import {
  Cep,
  Cnpj,
  Cpf,
  Phone,
} from 'br-helpers';

function formatBenefitNumber(benefitNumber) {
  let formattedBenefitNumber = benefitNumber;

  if (formattedBenefitNumber.length <= 7) {
    formattedBenefitNumber = formattedBenefitNumber
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})?/, '$1.$2');
  } else if (formattedBenefitNumber.length <= 11) {
    formattedBenefitNumber = formattedBenefitNumber
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})?(\d{3})?/, '$1.$2.$3');
  } else {
    formattedBenefitNumber = formattedBenefitNumber
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})?(\d{3})?(\d{1})?/, '$1.$2.$3-$4');
  }

  return formattedBenefitNumber;
}

function formatCaseNumber(caseNumber) {
  let formattedCaseNumber = caseNumber;

  if (formattedCaseNumber.length <= 10) {
    formattedCaseNumber = caseNumber
      .replace(/\D/g, '')
      .replace(/^(\d{7})(\d{2})?/, '$1-$2');
  } else if (formattedCaseNumber.length <= 15) {
    formattedCaseNumber = caseNumber
      .replace(/\D/g, '')
      .replace(/^(\d{7})(\d{2})?(\d{4})?/, '$1-$2.$3');
  } else if (formattedCaseNumber.length <= 17) {
    formattedCaseNumber = caseNumber
      .replace(/\D/g, '')
      .replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?/, '$1-$2.$3.$4');
  } else if (formattedCaseNumber.length <= 20) {
    formattedCaseNumber = caseNumber
      .replace(/\D/g, '')
      .replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?/, '$1-$2.$3.$4.$5');
  } else {
    formattedCaseNumber = caseNumber
      .replace(/\D/g, '')
      .replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6');
  }

  return formattedCaseNumber;
}

function formatDecimal(value) {
  let newValue;

  if (typeof value === 'number') {
    newValue = Number(value).toFixed(2);
  } else {
    newValue = value.replace(/\D+/g, '');
    newValue = (parseInt(newValue, 10) / 100).toFixed(2);
  }

  return newValue;
}

function formatDecimalBR(value) {
  let newValue;

  if (typeof value === 'number') {
    newValue = Number(value).toFixed(2);
  } else {
    newValue = value.replace(/\D+/g, '');
    newValue = (parseInt(newValue, 10) / 100).toFixed(2);
  }

  newValue = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(newValue));
  return newValue;
}

function formatPhone(phone) {
  return Phone.format(phone);
}

function formatTaxId(taxId, type) {
  if (type === 'cpf') {
    return Cpf.format(taxId);
  }
  if (type === 'cnpj') {
    return Cnpj.format(taxId);
  }
}

function formatZipCode(zipCode) {
  return Cep.format(zipCode);
}

export {
  formatBenefitNumber,
  formatCaseNumber,
  formatDecimal,
  formatDecimalBR,
  formatPhone,
  formatTaxId,
  formatZipCode,
};
