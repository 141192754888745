import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { formatTaxId } from '../../../services/format';

import './style.css';

function Report7({ reportData, setSelectedEvaluation }) {
  const columnsList = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '10%',
    },
    {
      id: 'taxId', label: 'CPF', minWidth: 135, width: '13%', align: 'center',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 100, width: '10%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIROS', minWidth: 50, width: '5%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '11%', align: 'center',
    },
    {
      id: 'caseNumber', label: 'Nº PROCESSO', minWidth: 50, width: '5%', align: 'center',
    },
    {
      id: 'phones', label: 'TELEFONE', minWidth: 140, width: '13%', align: 'center',
    },
    {
      id: 'address', label: 'ENDEREÇO', minWidth: 200, width: '19%',
    },
  ];

  return (
    <>
      <Paper sx={{
        width: '100%', height: '60vh', overflowY: 'scroll', paddingTop: '4px',
      }}
      >
        <header>
          <h3 className='report7-title'>
            {reportData.reportTitle}
          </h3>
        </header>
        <div className='report7-summary'>
          <div className='report7-summary-column'>
            <div>
              <span>
                <strong>Período: </strong>
                Geral
              </span>
            </div>
            <div>
              <span>
                <strong>Produto: </strong>
                {reportData.product ? reportData.product : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report7-summary-column'>
            <div>
              <span>
                <strong>Origem: </strong>
                {reportData.origin ? reportData.origin : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Parceiro: </strong>
                {reportData.partner ? reportData.partner : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report7-summary-column'>
            <div>
              <span>
                <strong>Clientes: </strong>
                {Number(reportData.clientsCount) > 0 ? Number(reportData.clientsCount).toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Casos/avaliações: </strong>
                {Number(reportData.evaluationsCount) > 0 ? Number(reportData.evaluationsCount).toLocaleString() : '-'}
              </span>
            </div>
          </div>
        </div>
        {reportData.clients.length > 0
          && (
            <>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table className='table-body-fixed'>
                  <TableHead>
                    <TableRow>
                      {columnsList.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.clients
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'taxId'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value ? formatTaxId(value, 'cpf') : '-'}
                                    </TableCell>
                                  )
                                  : (column.id === 'phones'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        {value.map((phone) => (<div>{phone}</div>))}
                                      </TableCell>
                                    )
                                    : (column.id === 'partners'
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value.map((partner) => (<div>{partner}</div>))}
                                        </TableCell>
                                      )
                                      : (column.id === 'caseNumber'
                                        ? (
                                          <TableCell key={column.id} align={column.align}>
                                            {value ? value.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'}
                                          </TableCell>
                                        )
                                        : (column.id === 'client'
                                          ? (
                                            <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.id, workflowId: record.workflowId })}>
                                              {value}
                                            </TableCell>
                                          )
                                          : (
                                            <TableCell key={column.id} align={column.align}>
                                              {value}
                                            </TableCell>
                                          )
                                        ))))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </Paper>
    </>
  );
}

export default Report7;
