import './style.css';

function WhiteButton({ buttonText, buttonWidth, buttonBorder }) {
  return (
    <>
      <input type='button' className='white-button' value={buttonText} style={{ width: buttonWidth, border: buttonBorder }} />
    </>
  );
}

export default WhiteButton;
