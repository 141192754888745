import { useContext } from 'react';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import Clients from './pages/Clients';
import Evaluations from './pages/Evaluations';
import EvaluationWorkflow1 from './pages/EvaluationWorkflow1';
import EvaluationWorkflow2 from './pages/EvaluationWorkflow2';
import EvaluationWorkflow3 from './pages/EvaluationWorkflow3';
import EvaluationWorkflow4 from './pages/EvaluationWorkflow4';
import EvaluationWorkflow5 from './pages/EvaluationWorkflow5';
import EvaluationWorkflow6 from './pages/EvaluationWorkflow6';
import FinancialTransactions from './pages/FinancialTransactions';
import Home from './pages/Home';
import Login from './pages/Login';
import Origins from './pages/Origins';
import Partners from './pages/Partners';
import Products from './pages/Products';
import Reports from './pages/Reports';
import Users from './pages/Users';

import {
  AuthContext,
  AuthProvider,
} from './contexts/auth';

import {
  PaginationProvider,
} from './contexts/pagination';

import './style.css';

function AppRoutes() {
  function Private({ children }) {
    const {
      authenticated,
      loading,
    } = useContext(AuthContext);

    if (loading) {
      return <div className='loading'>Carregando...</div>;
    }

    if (!authenticated) {
      return <Navigate to='/' />;
    }

    return children;
  }

  return (
    <Router>
      <AuthProvider>
        <PaginationProvider>
          <Routes>
            <Route exact path='/' element={<Login />} />
            <Route exact path='/home' element={<Private><Home /></Private>} />
            <Route exact path='/users' element={<Private><Users /></Private>} />
            <Route exact path='/origins' element={<Private><Origins /></Private>} />
            <Route exact path='/products' element={<Private><Products /></Private>} />
            <Route exact path='/clients' element={<Private><Clients /></Private>} />
            <Route exact path='/partners' element={<Private><Partners /></Private>} />
            <Route exact path='/evaluations' element={<Private><Evaluations /></Private>} />
            <Route exact path='/evaluation-workflow/1/:id' element={<Private><EvaluationWorkflow1 /></Private>} />
            <Route exact path='/evaluation-workflow/2/:id' element={<Private><EvaluationWorkflow2 /></Private>} />
            <Route exact path='/evaluation-workflow/3/:id' element={<Private><EvaluationWorkflow3 /></Private>} />
            <Route exact path='/evaluation-workflow/4/:id' element={<Private><EvaluationWorkflow4 /></Private>} />
            <Route exact path='/evaluation-workflow/5/:id' element={<Private><EvaluationWorkflow5 /></Private>} />
            <Route exact path='/evaluation-workflow/6/:id' element={<Private><EvaluationWorkflow6 /></Private>} />
            <Route exact path='/financial-transactions' element={<Private><FinancialTransactions /></Private>} />
            <Route exact path='/reports' element={<Private><Reports /></Private>} />
          </Routes>
        </PaginationProvider>
      </AuthProvider>
    </Router>
  );
}

export default AppRoutes;
