import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import DrawerMenu from '../../components/DrawerMenu';
import FinancialTransaction from '../../components/Modals/FinancialTransaction';
import FinancialTransactionsList from '../../components/Lists/FinancialTransactions';
import Header from '../../components/Header';
import SearchField from '../../components/SearchField';
import WhiteButton from '../../components/Buttons/WhiteButton';

import './style.css';

function FinancialTransactions() {
  const navigate = useNavigate();

  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [currentList, setCurrentList] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [originsList, setOriginsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('Geral');
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [transactionsCurrentList, setTransactionsCurrentList] = useState([]);
  const [transactionsOriginalList, setTransactionsOriginalList] = useState([]);
  const [updateList, setUpdateList] = useState(false);

  useEffect(() => {
    if (activeUser.category === 'Colaborador') {
      navigate('/home');
    }
  }, [activeUser.category, navigate]);

  const retrieveTransactions = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'financialTransactions', reqData);

      setTransactionsCurrentList(data);
      setTransactionsOriginalList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/FinancialTransactions/index.js - retrieveTransactions',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveCategories = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'financialTransactionCategories', reqData);

      setCategoriesList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/FinancialTransactions/index.js - retrieveCategories',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveOrigins = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'origins', reqData);

      setOriginsList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/FinancialTransactions/index.js - retrieveOrigins',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrievePartners = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'partners', reqData);

      setPartnersList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/FinancialTransactions/index.js - retrievePartners',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  useEffect(() => {
    if (selectedTransaction) {
      setOpenTransactionModal(true);
    }
  }, [selectedTransaction]);

  useEffect(() => {
    if (openTransactionModal) {
      retrieveCategories();
      retrieveOrigins();
      retrievePartners();
    }
  }, [openTransactionModal, retrieveCategories, retrieveOrigins, retrievePartners]);

  useEffect(() => {
    retrieveTransactions();
  }, [retrieveTransactions]);

  useEffect(() => {
    if (updateList) {
      retrieveTransactions();
      setUpdateList(false);
    }
  }, [updateList, retrieveTransactions]);

  useEffect(() => {
    if (currentList !== undefined) {
      setTransactionsCurrentList(currentList);
    } else {
      setTransactionsCurrentList(transactionsOriginalList);
    }
  }, [currentList, transactionsOriginalList]);

  useEffect(() => {
    if (confirmation) {
      setConfirmation(false);
    }
  }, [confirmation]);

  useEffect(() => {
    let tempList = transactionsOriginalList;

    switch (selectedFilter) {
      case 'Pagamento':
        tempList = tempList.filter((transaction) => (transaction.transactionType === 'Pagamento'));
        break;

      case 'Valor Devido':
        tempList = tempList.filter((transaction) => (transaction.transactionType === 'Valor devido'));
        break;

      default:
        break;
    }

    setTransactionsCurrentList(tempList);
    setCurrentList(tempList);
  }, [selectedFilter, transactionsOriginalList]);

  const handleNewFinancialTransaction = () => {
    setSelectedTransaction('');
    setOpenTransactionModal(true);
  };

  return (
    <>
      <div className='display-financial-transactions'>
        <Header secondaryTitle='FINANCEIRO' />
      </div>
      <div className='menu-financial-transactions'>
        <DrawerMenu />
        <div onClick={handleNewFinancialTransaction}>
          <BlueButton buttonText='Novo   +' />
        </div>
      </div>
      <div className='filter'>
        <div className='menu-financial-transactions-btn-container'>
          <div onClick={() => { setSelectedFilter('Geral'); }}>
            {selectedFilter === 'Geral' ? <WhiteButton buttonText='Geral' buttonWidth='60px' /> : <BlueButton buttonText='Geral' buttonWidth='60px' />}
          </div>
          <div onClick={() => { setSelectedFilter('Pagamento'); }}>
            {selectedFilter === 'Pagamento' ? <WhiteButton buttonText='Pagamento' buttonWidth='110px' /> : <BlueButton buttonText='Pagamento' buttonWidth='110px' />}
          </div>
          <div onClick={() => { setSelectedFilter('Valor Devido'); }}>
            {selectedFilter === 'Valor Devido' ? <WhiteButton buttonText='Valor Devido' buttonWidth='120px' /> : <BlueButton buttonText='Valor Devido' buttonWidth='120px' />}
          </div>
        </div>
        <div className='search'>
          <SearchField
            target='Financeiro'
            originalList={transactionsOriginalList}
            setCurrentList={setCurrentList}
            selectedFilter={selectedFilter}
          />
        </div>
      </div>
      <div className='interface-financial-transactions'>
        <FinancialTransactionsList
          transactionsCurrentList={transactionsCurrentList}
          setSelectedTransaction={setSelectedTransaction}
          setUpdateList={setUpdateList}
        />
      </div>
      {openTransactionModal
        && (
          <FinancialTransaction
            openTransactionModal={openTransactionModal}
            setOpenTransactionModal={setOpenTransactionModal}
            selectedTransaction={selectedTransaction}
            setSelectedTransaction={setSelectedTransaction}
            setUpdateList={setUpdateList}
            categoriesList={categoriesList}
            originsList={originsList}
            partnersList={partnersList}
          />
        )}
      {openAlert
        && <Alert openAlert={openAlert} setOpenAlert={setOpenAlert} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
    </>
  );
}

export default FinancialTransactions;
