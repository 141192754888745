import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AuthContext } from '../../../contexts/auth';
import { PaginationContext } from '../../../contexts/pagination';

import { API } from '../../../services/api';
import Filters from '../../../services/filters';
import { formatTaxId } from '../../../services/format';

import Alert from '../../Modals/Alert';
import CheckboxFilter from '../../Filters/CheckboxFilter';
import Confirmation from '../../Modals/Confirmation';
import ListRows from './ListRows';
import RadioFilter from '../../Filters/RadioFilter';
import TablePagination from '../../TablePagination';
import TextFilter from '../../Filters/TextFilter';

import '../../../style.css';

function Origins({ origins, setSelectedOrigin, setUpdateList }) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const { sliceCurrentList } = useContext(PaginationContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState({});

  const filter = useRef({});
  const operation = useRef();
  const originId = useRef();

  function handleSort(column) {
    const currentOrder = sortOrder[column] || 'asc';

    const sortedData = [...origins];

    sortedData.sort((a, b) => {
      const aValue = a[column] || '';
      const bValue = b[column] || '';

      if (column === 'active') {
        return currentOrder === 'asc'
          ? (aValue ? 'Ativo' : 'Inativo').localeCompare((bValue ? 'Ativo' : 'Inativo'))
          : (bValue ? 'Ativo' : 'Inativo').localeCompare((aValue ? 'Ativo' : 'Inativo'));
      }

      const numericColumns = ['clients', 'onGoing', 'distributed', 'unsuccessful', 'coldCases'];

      if (numericColumns.includes(column)) {
        return currentOrder === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }

      return currentOrder === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setCurrentList(sortedData);
    setSortOrder({ ...sortOrder, [column]: currentOrder === 'asc' ? 'desc' : 'asc' });
  }

  function handleFilterChange() {
    if (Object.keys(filter.current).length === 0) {
      setNoResultsFound(false);
      setCurrentList(origins);
      return;
    }

    let tempList = origins;

    tempList = tempList.filter((item) => {
      const originMatch = Filters.filterData(filter.current.origin, item.origin);
      const originDescriptionMatch = Filters.filterText(filter.current.description, item.description);
      const activeMatch = Filters.filterData(filter.current.active, (item.active ? 'Ativo' : 'Inativo'));

      return originMatch && originDescriptionMatch && activeMatch;
    });

    setNoResultsFound(tempList.length === 0);

    setCurrentList(tempList);
  }

  function handleConfirmRemoveOrigin(origin) {
    setConfirmationMessage(`Tem certeza de que deseja remover/inativar a origem ${origin.origin}?`);
    operation.current = 'DELETE';
    originId.current = origin.id;
    setOpenConfirmation(true);
  }

  function handleConfirmActivateOrigin(origin) {
    setConfirmationMessage(`Tem certeza de que deseja ativar a origem ${origin.origin}?`);
    operation.current = 'PUT';
    originId.current = origin.id;
    setOpenConfirmation(true);
  }

  const manageOriginStatus = useCallback(async () => {
    try {
      const reqData = {
        id: originId.current,
        token,
      };

      if (operation.current === 'PUT') {
        reqData.body = { active: true };
      }

      const data = await API.fetch(operation.current, 'origins', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Lists/Origins/index.js - manageOriginStatus',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, setUpdateList]);

  useEffect(() => {
    if (confirmation) {
      manageOriginStatus();
      setConfirmation(false);
      operation.current = '';
      originId.current = '';
    }
  }, [confirmation, manageOriginStatus]);

  useEffect(() => {
    setCurrentList(origins);
  }, [origins]);

  return (
    <div className='table-component-container'>
      <div className='table-component-table-area'>
        <table className='table-component-table-body'>
          <thead className='table-component-table-thead'>
            <th
              className='table-component-table-th table-component-table-th-first-column'
              style={{
                width: 250,
                minWidth: 250,
                maxWidth: 250,
              }}
            >
              <span className='table-component-table-header'>
                ORIGEM
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('origin')}
                    >
                      {sortOrder.origin === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(origins?.map((item) => item.origin).sort())]}
                      column='origin'
                      filter={filter}
                      active={filter.current?.origin?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                minWidth: 300,
                maxWidth: 'auto',
              }}
            >
              <span className='table-component-table-header'>
                DESCRIÇÃO
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('description')}
                    >
                      {sortOrder.description === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='description'
                      filter={filter}
                      active={filter.current?.description?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 100,
                minWidth: 100,
                maxWidth: 100,
              }}
            >
              <span className='table-component-table-header'>
                CLIENTES
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('clients')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 145,
                minWidth: 145,
                maxWidth: 145,
              }}
            >
              <span className='table-component-table-header'>
                EM ANDAMENTO
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('onGoing')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                DISTRIBUÍDOS
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('distributed')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 105,
                minWidth: 105,
                maxWidth: 105,
              }}
            >
              <span className='table-component-table-header'>
                BAIXADOS
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('unsuccessful')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 125,
                minWidth: 125,
                maxWidth: 125,
              }}
            >
              <span className='table-component-table-header'>
                CASOS FRIOS
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('coldCases')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 110,
                minWidth: 110,
                maxWidth: 110,
              }}
            >
              <span className='table-component-table-header'>
                STATUS
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('active')}
                    >
                      {sortOrder.active === 'asc' ? '▲' : '▼'}
                    </span>
                    <RadioFilter
                      applyFilters={handleFilterChange}
                      filterOptions={['Ativo', 'Inativo']}
                      column='active'
                      filter={filter}
                      active={filter.current?.active?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th table-component-table-th-last-column'
              style={{
                width: 60,
                minWidth: 60,
                maxWidth: 60,
              }}
            />
          </thead>
          <tbody
            className='table-component-rows-area'
            style={{ height: '100px' }}
          >
            {(origins.length > 0) && (
              sliceCurrentList(currentList, currentPage).map((origin) => (
                <ListRows
                  key={origin.id}
                  origin={origin}
                  setSelectedOrigin={setSelectedOrigin}
                  handleConfirmRemoveOrigin={handleConfirmRemoveOrigin}
                  handleConfirmActivateOrigin={handleConfirmActivateOrigin}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      {noResultsFound && (
        <div className='table-component-no-results'>
          FILTRO APLICADO NÃO APRESENTOU RESULTADOS
        </div>
      )}
      <TablePagination
        array={currentList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Origins;
