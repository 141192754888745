import { Paper } from '@mui/material';

import {
  format,
  parse,
} from 'date-fns';

import './style.css';

function Report1({ reportData }) {
  return (
    <>
      <Paper sx={{
        width: '100%', height: '60vh', overflowY: 'scroll', paddingTop: '4px',
      }}
      >
        <header>
          <h3 className='report1-title'>
            {reportData.reportTitle}
          </h3>
        </header>
        <div className='report1-summary'>
          <div className='report1-summary-column'>
            <div>
              <span>
                <strong>Período: </strong>
                {(reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Produto: </strong>
                {reportData.product ? reportData.product : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report1-summary-column'>
            <div>
              <span>
                <strong>Origem: </strong>
                {reportData.origin ? reportData.origin : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Parceiro: </strong>
                {reportData.partner ? reportData.partner : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report1-summary-column'>
            <div>
              <span>
                <strong>Honorários previstos: </strong>
                R$
                {' '}
                {Number(reportData.amountToReceive) > 0
                  ? (`${Number(reportData.amountToReceive).toLocaleString()
                    + (Number(reportData.amountToReceive).toLocaleString().indexOf(',') === -1 ? ',00'
                      : Number(reportData.amountToReceive).toLocaleString().length - (Number(reportData.amountToReceive).toLocaleString().indexOf(',') + 1) === 1
                        ? '0'
                        : '')} (${reportData.totalCasesToReceive} casos)`)
                  : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Honorários recebidos: </strong>
                R$
                {' '}
                {Number(reportData.amountReceived) > 0
                  ? (`${Number(reportData.amountReceived).toLocaleString()
                    + (Number(reportData.amountReceived).toLocaleString().indexOf(',') === -1
                      ? ',00'
                      : Number(reportData.amountReceived).toLocaleString().length - (Number(reportData.amountReceived).toLocaleString().indexOf(',') + 1) === 1
                        ? '0'
                        : '')} (${reportData.totalCasesReceived} casos)`)
                  : '-'}
              </span>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default Report1;
