import {
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function RecoverPassword({ openRecoverPasswordModal, setOpenRecoverPasswordModal }) {
  const {
    activeUser,
    emailDomain,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [emailRecoverPassword, setEmailRecoverPassword] = useState();
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setEmailRecoverPassword('');
  }, [openRecoverPasswordModal]);

  async function handleRecoverPassword() {
    try {
      if (!emailRecoverPassword) {
        setOpenAlert(true);

        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o e-mail.',
        });
        return;
      }

      const reqData = {
        body: {
          email: emailRecoverPassword + emailDomain,
        },
      };

      const data = await API.fetch('PUT', 'resetPassword', reqData);

      setEmailRecoverPassword('');

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });
    } catch (error) {
      setOpenRecoverPasswordModal(false);

      setEmailRecoverPassword('');

      setAlertMessage({
        title: 'Alerta!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'src/contexts/auth.js - handleRecoverPassword',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  function handleCloseRecoverPassword() {
    setOpenRecoverPasswordModal(false);
    setEmailRecoverPassword('');
  }

  return (
    <div>
      <div>
        <Dialog
          open={openRecoverPasswordModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseRecoverPassword}
        >
          <div className='dialog-title'>Recuperação de Senha</div>
          <DialogContent sx={{ width: '450px', height: 130 }}>
            <div className='dialog-subtitle'>Digite seu email de recuperação de senha.</div>
            <div className='login-input'>
              <input
                autoFocus
                className='input'
                placeholder='E-mail'
                variant='outlined'
                size='small'
                type='email'
                value={emailRecoverPassword}
                onChange={(e) => setEmailRecoverPassword(e.target.value)}
              />
              <span className='login-email'>
                {emailDomain}
              </span>
            </div>
          </DialogContent>
          <div className='dialog-actions' style={{ zIndex: 9999, display: 'flex', justifyContent: 'flex-end' }}>
            <DialogActions sx={{ padding: '20px' }}>
              <div onClick={() => setOpenRecoverPasswordModal(false)}>
                <GrayButton buttonText='Cancelar' buttonWidth='90px' />
              </div>
              <div onClick={handleRecoverPassword}>
                <BlueButton className='recover-button' buttonText='Recuperar' />
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
      {openAlert
        && <Alert openAlert={openAlert} setOpenAlert={setOpenAlert} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
    </div>
  );
}

export default RecoverPassword;
