import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';
import { formatPhone } from '../../services/format';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import Client from '../../components/Modals/Client';
import ClientsList from '../../components/Lists/Clients';
import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';

import '../../style.css';

function Clients() {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [clientLogs, setClientLogs] = useState([]);
  const [clients, setClients] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [originsList, setOriginsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [updateList, setUpdateList] = useState(false);

  const retrieveClients = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'clients', reqData);

      data.forEach((item) => {
        const phones = [];
        if (item.phone1) {
          phones.push(formatPhone(item.phone1));
        }
        if (item.phone2) {
          phones.push(formatPhone(item.phone2));
        }
        if (item.phone3) {
          phones.push(formatPhone(item.phone3));
        }
        if (item.phone4) {
          phones.push(formatPhone(item.phone4));
        }
        if (item.phone5) {
          phones.push(formatPhone(item.phone5));
        }
        item.phones = phones;

        const partners = [];
        if (item.partnerCode1) {
          partners.push(item.partnerCode1);
        }
        if (item.partnerCode2) {
          partners.push(item.partnerCode2);
        }
        if (item.partnerCode3) {
          partners.push(item.partnerCode3);
        }
        item.partners = partners;

        let location = '';
        if (item.city && item.state) {
          location = `${item.city}/${item.state}`;
        } else if (item.city && !item.state) {
          location = item.city;
        } else if (!item.city && item.state) {
          location = item.state;
        }
        item.location = location;
      });

      setClients(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Clients/index.js - retrieveClients',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveClientLogs = useCallback(async (clientId) => {
    try {
      const reqData = {
        id: clientId,
        token,
      };

      const data = await API.fetch('GET', 'clientLogs', reqData);

      setClientLogs(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Clients/index.js - retrieveClientLogs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveOrigins = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'origins', reqData);

      setOriginsList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Clients/index.js - retrieveOrigins',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrievePartners = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'partners', reqData);

      setPartnersList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Clients/index.js - retrievePartners',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  function handleNewClient() {
    setSelectedClient('');
    setOpenModal(true);
  }

  useEffect(() => {
    retrieveClients();
  }, [retrieveClients]);

  useEffect(() => {
    if (updateList) {
      retrieveClients();
      setUpdateList(false);
    }
  }, [updateList, retrieveClients]);

  useEffect(() => {
    if (selectedClient) {
      retrieveClientLogs(selectedClient.id);
      setOpenModal(true);
    } else {
      setClientLogs([]);
    }
  }, [selectedClient, retrieveClientLogs]);

  useEffect(() => {
    if (openModal) {
      const fetchData = async () => {
        const promise1 = retrieveOrigins();
        const promise2 = retrievePartners();

        await Promise.all([promise1, promise2]);
      };

      fetchData();
    }
  }, [openModal, retrieveOrigins, retrievePartners]);

  return (
    <div className='page-container'>
      <Header secondaryTitle='CLIENTES' />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
          <div onClick={handleNewClient}>
            <BlueButton buttonText='Novo   +' />
          </div>
        </div>
        <div className='page-table-area'>
          {clients.length > 0 && (
            <ClientsList
              clients={clients}
              setSelectedClient={setSelectedClient}
              setUpdateList={setUpdateList}
            />
          )}
        </div>
      </div>
      {openModal && (
        <Client
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          clientLogs={clientLogs}
          setClientLogs={setClientLogs}
          setUpdateList={setUpdateList}
          auxiliaryLists={{
            originsList,
            partnersList,
          }}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Clients;
