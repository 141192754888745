import {
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
} from '@mui/material/';

import CloseIcon from '@mui/icons-material/Close';

import {
  format,
  parse,
} from 'date-fns';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function FinancialTransaction({
  openTransactionModal, setOpenTransactionModal, selectedTransaction, setSelectedTransaction, setUpdateList, categoriesList, originsList, partnersList,
}) {
  const { activeUser, token } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [completedAt, setCompletedAt] = useState(selectedTransaction !== undefined ? (selectedTransaction.completedAt ? format(parse(selectedTransaction.completedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '') : '');
  const [details, setDetails] = useState(selectedTransaction !== undefined ? (selectedTransaction.details ? selectedTransaction.details : '') : '');
  const [openAlert, setOpenAlert] = useState(false);
  const [origin, setOrigin] = useState(selectedTransaction !== undefined ? (selectedTransaction.origin ? selectedTransaction.origin : '') : '');
  const [partnerCode, setPartnerCode] = useState(selectedTransaction !== undefined ? (selectedTransaction.partnerCode ? selectedTransaction.partnerCode : '') : '');
  const [transactionCategory, setTransactionCategory] = useState(selectedTransaction !== undefined ? (selectedTransaction.transactionCategory ? selectedTransaction.transactionCategory : '') : '');
  const [transactionDate, setTransactionDate] = useState(selectedTransaction !== undefined ? (selectedTransaction.transactionDate ? format(parse(selectedTransaction.transactionDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '') : '');
  const [transactionValue, setTransactionValue] = useState(selectedTransaction !== undefined ? (selectedTransaction.transactionValue ? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(selectedTransaction.transactionValue)) : '') : '');

  const transactionType = selectedTransaction ? selectedTransaction.transactionType : 'Pagamento';

  const title = selectedTransaction ? 'Editar Transação Financeira' : 'Cadastrar Transação Financeira';

  useEffect(() => {
    switch (transactionCategory) {
      case 'Marketing':
        setPartnerCode('');
        break;

      case 'Parceiro':
        setOrigin('');
        break;

      default:
        setOrigin('');
        setPartnerCode('');
        break;
    }
  }, [transactionCategory]);

  const handleCloseTransactionModal = () => {
    setSelectedTransaction('');
    setOpenTransactionModal(false);
  };

  async function handleSaveTransaction() {
    try {
      if (!transactionDate) {
        setAlertMessage({ title: 'Alerta!', message: 'É necessário informar a data da transação.' });
        setOpenAlert(true);
        return;
      }

      if (!transactionValue) {
        setAlertMessage({ title: 'Alerta!', message: 'É necessário informar o valor da transação.' });
        setOpenAlert(true);
        return;
      }

      if (!transactionCategory) {
        setAlertMessage({ title: 'Alerta!', message: 'É necessário informar a categoria da transação.' });
        setOpenAlert(true);
        return;
      }

      const transactionData = {
        transactionDate: format(parse(transactionDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd'),
        transactionValue: (parseInt(transactionValue.replace(/\D+/g, ''), 10) / 100).toFixed(2),
        transactionCategory,
      };

      if (origin) {
        transactionData.origin = origin;
      } else if (transactionCategory === 'Marketing') {
        setAlertMessage({ title: 'Alerta!', message: 'Em transações do tipo "Marketing" é necessário informar a origem.' });
        setOpenAlert(true);
        return;
      }

      if (partnerCode) {
        transactionData.partnerCode = partnerCode;
      } else if (transactionCategory === 'Parceiro') {
        setAlertMessage({ title: 'Alerta!', message: 'Em transações do tipo "Parceiro" é necessário informar o código do parceiro.' });
        setOpenAlert(true);
        return;
      }

      if (details) {
        transactionData.details = details;
      }

      const reqData = {
        body: transactionData,
        token,
      };

      let data;

      if (!selectedTransaction) {
        data = await API.fetch('POST', 'financialTransactions', reqData);
      } else {
        reqData.id = selectedTransaction.id;
        data = await API.fetch('PUT', 'financialTransactions', reqData);
      }

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      handleCloseTransactionModal();

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/FinancialTransaction/index.js - handleSaveTransaction',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRegisterPayment() {
    try {
      if (!completedAt) {
        setAlertMessage({ title: 'Alerta!', message: 'É necessário informar a data da efetivação do pagamento.' });
        setOpenAlert(true);
        return;
      }

      const transactionData = { completedAt: format(parse(completedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') };

      const reqData = {
        id: selectedTransaction.id,
        body: transactionData,
        token,
      };

      const data = await API.fetch('PUT', 'financialTransactions', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      handleCloseTransactionModal();

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/FinancialTransaction/index.js - handleRegisterPayment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  function formatTransactionValue(value) {
    let newValue = value.replace(/\D+/g, '');
    newValue = (parseInt(newValue, 10) / 100).toFixed(2);
    newValue = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(newValue));
    setTransactionValue(newValue);
  }

  return (
    <div className='transaction-form-modal'>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openTransactionModal}
        TransitionComponent={Transition}
        keepMounted
        style={{ zIndex: 9 }}
      >
        <div className='title-modal'>
          <div className='dialog-title'>
            {title}
            <div className='close-button' onClick={handleCloseTransactionModal}><CloseIcon /></div>
          </div>
        </div>
        <DialogContent>
          <div className='row1'>
            <div className='transaction-row1'>
              <div className='transaction-row1-col1'>
                <div style={{
                  display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start',
                }}
                >
                  <div className='transaction-date'>
                    <label className='form-label'>Data</label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='date'
                      value={transactionDate}
                      onChange={(e) => setTransactionDate(e.target.value)}
                      disabled={selectedTransaction.transactionType === 'Valor devido'}
                    />
                  </div>
                  <div className='transaction-value'>
                    <label className='form-label'>Valor</label>
                    <div style={{ display: 'flex' }}>
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='13'
                        style={{ padding: '0 10%', textAlign: 'right' }}
                        value={transactionValue}
                        onChange={(e) => formatTransactionValue(e.target.value)}
                      />
                      <span style={{ marginLeft: '-95%', alignSelf: 'center', fontSize: '13px' }}>R$</span>
                    </div>
                  </div>
                  <div className='transaction-category'>
                    <label className='form-label'>Categoria</label>
                    <select
                      style={{ marginRight: '5px' }}
                      className='input'
                      placeholder='Parceiro'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={transactionCategory}
                      onChange={(e) => setTransactionCategory(e.target.value)}
                      disabled={selectedTransaction.transactionType === 'Valor devido'}
                    >
                      <option />
                      {categoriesList.map((category) => (
                        <option>{category.category}</option>
                      ))}
                    </select>
                  </div>
                  <div className='transaction-type'>
                    <label className='form-label'>Tipo</label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={transactionType}
                      disabled
                    />
                  </div>
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', marginTop: '10px',
                }}
                >
                  {transactionCategory === 'Marketing'
                    && (
                      <div className='transaction-origin'>
                        <label className='form-label'>Origem (Marketing)</label>
                        <select
                          className='input'
                          variant='outlined'
                          size='small'
                          type='text'
                          value={origin}
                          onChange={(e) => setOrigin(e.target.value)}
                          disabled={selectedTransaction.transactionType === 'Valor devido'}
                        >
                          <option />
                          {originsList.map((origin) => (
                            <option>{origin.origin}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  {transactionCategory === 'Parceiro'
                    && (
                      <div className='transaction-partner'>
                        <label className='form-label'>Parceiro</label>
                        <select
                          style={{ marginRight: '5px' }}
                          className='input'
                          placeholder='Parceiro'
                          variant='outlined'
                          size='small'
                          type='text'
                          value={partnerCode}
                          onChange={(e) => setPartnerCode(e.target.value)}
                          disabled={selectedTransaction.transactionType === 'Valor devido'}
                        >
                          <option />
                          {partnersList.map((partner) => (
                            <option>{partner.partnerCode}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  <div className='transaction-datails' style={{ marginRight: '5px' }}>
                    <label className='form-label'>Detalhes</label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='150'
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                  </div>
                  {selectedTransaction
                    && (
                      <div className='transaction-date-final'>
                        <label className='form-label'>Data de Conclusão</label>
                        <input
                          className='input'
                          variant='outlined'
                          size='small'
                          type='date'
                          value={completedAt}
                          onChange={(e) => setCompletedAt(e.target.value)}
                          disabled={selectedTransaction.transactionType === 'Pagamento'}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <div className='dialog-actions'>
          <DialogActions
            sx={{ padding: '20px 25px' }}
            style={{
              zIndex: 9999, display: 'flex', justifyContent: 'space-between', width: '100%',
            }}
          >
            {selectedTransaction.transactionType === 'Valor devido'
              ? (
                <div onClick={handleRegisterPayment}>
                  <BlueButton className='save-button' buttonText='Pagamento Realizado' buttonWidth='180px' />
                </div>
              )
              : <div />}
            <div style={{
              zIndex: 9999, display: 'flex', justifyContent: 'flex-end', width: '310px', gap: '10px',
            }}
            >
              <div onClick={handleCloseTransactionModal}>
                <GrayButton buttonText='Cancelar' buttonWidth='90px' />
              </div>
              <div onClick={handleSaveTransaction}>
                <BlueButton className='save-button' buttonText={selectedTransaction ? 'Salvar' : 'Criar'} buttonWidth={selectedTransaction ? '70px' : '60px'} />
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      {openAlert
        && <Alert openAlert={openAlert} setOpenAlert={setOpenAlert} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
    </div>
  );
}

export default FinancialTransaction;
