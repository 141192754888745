import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@mui/material';

import {
  format,
  parse,
} from 'date-fns';

import { formatTaxId } from '../../../../services/format';

import '../../../../style.css';

function ListRows({
  client, setSelectedClient, handleConfirmRemoveClient, handleConfirmActivateClient,
}) {
  return (
    <>
      <tr
        key={client.id}
        className='table-rows-component-tr'
      >
        <td
          className='table-rows-component-td table-rows-component-first-column'
          onClick={() => setSelectedClient(client)}
        >
          {client.name}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedClient(client)}
        >
          {client.taxId ? formatTaxId(client.taxId, 'cpf') : '-'}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedClient(client)}
        >
          {client.phones.map((phone) => (
            <div>{phone}</div>
          ))}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedClient(client)}
        >
          {client.email}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedClient(client)}
        >
          {client.location}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedClient(client)}
        >
          {client.origin}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedClient(client)}
        >
          {client.partners.map((partner) => (
            <div>{partner}</div>
          ))}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedClient(client)}
        >
          {client.retirementBenefitDate
            ? format(parse(client.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
            : '-'}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedClient(client)}
        >
          {client.products.map((product) => (
            <div>{product}</div>
          ))}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedClient(client)}
        >
          {client.active ? 'Ativo' : 'Inativo'}
        </td>
        <td
          className='table-rows-component-buttons-cell table-rows-component-last-column'
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          {client.active ? (
            <span
              className='table-buttons'
              onClick={() => handleConfirmRemoveClient(client)}
            >
              <Tooltip title='Excluir/Inativar'>
                <DeleteForeverIcon style={{ color: 'red' }} />
              </Tooltip>
            </span>
          ) : (
            <span
              className='table-buttons activate-button'
              onClick={() => handleConfirmActivateClient(client)}
            >
              <Tooltip title='Reativar'>
                <ReplayIcon style={{ color: 'green' }} />
              </Tooltip>
            </span>
          )}
        </td>
      </tr>
    </>
  );
}

export default ListRows;
