import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import { format } from 'date-fns';

import { formatTaxId } from '../../../services/format';

import logoReports from '../../../images/base64Images';

function Report7PDF(reportData) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', 150],
        body: [
          [
            {
              text: reportData.reportTitle,
              fontSize: 15,
              bold: true,
              alignment: 'center',
              margin: [140, 15, 0, 15],
              colSpan: 6,
              border: [true, true, false, true],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              width: 90,
              image: logoReports,
              border: [false, true, true, true],
              margin: [50, 10, 10, 10],
            },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
  ];

  const dataPart = reportData.clients.map((client) => [
    {
      text: client.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: client.taxId ? formatTaxId(client.taxId, 'cpf') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: client.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: client.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: client.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: (client.caseNumber ? client.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: client.phones.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: client.address,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [false, true, true, true],
    },
  ]);

  const header = [
    {
      table: {
        widths: [70, 120, 70, 120, '*', 85, 150],
        body: [
          [
            {
              text: 'Período:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Origem:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: reportData.origin ? reportData.origin : 'Geral',
              style: 'tableHeader',
              colSpan: 2,
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {},
            {
              text: 'Clientes:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: Number(reportData.clientsCount) > 0 ? Number(reportData.clientsCount).toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
          ],
          [
            {
              text: 'Produto:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.product ? reportData.product : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: 'Parceiro:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.partner ? reportData.partner : 'Geral',
              style: 'tableHeader',
              colSpan: 2,
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {},
            {
              text: 'Casos/avaliações:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: Number(reportData.evaluationsCount) > 0 ? Number(reportData.evaluationsCount).toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
          ],
        ],
      },
    },
  ];

  const details = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 55, 65, 45, 90, 90, 60, '*'],
        body: [
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'CPF',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'Nº PROCESSO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'TELEFONE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'ENDEREÇO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  function footer(currentPage, pageCount) {
    return {
      columns: [
        {
          text: `Gerado em: ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`,
          fontSize: 7,
          margin: [20, 10, 0, 0], // left, top, right, bottom
        },
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0], // left, top, right, bottom
        },
      ],
    };
  }

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [15, 40, 15, 40],
    content: [reportTitle, header, reportData.clients.length > 0 ? details : ''],
    footer,
  };
  return docDefinitions;
}

export default Report7PDF;
