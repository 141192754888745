import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import {
  format,
  parse,
} from 'date-fns';

import logoReports from '../../../images/base64Images';

function Report5PDF(reportData) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', 150],
        body: [
          [
            {
              text: `${reportData.reportTitle} - PARCEIRO: ${reportData.partner}`,
              fontSize: 15,
              bold: true,
              alignment: 'center',
              margin: [140, 15, 0, 15],
              colSpan: 9,
              border: [true, true, false, true],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              width: 90,
              image: logoReports,
              border: [false, true, true, true],
              margin: [50, 10, 10, 10],
            },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
  ];

  const dataPart1 = reportData.cases.map((partnerCase) => [
    {
      text: partnerCase.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: partnerCase.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.kitReceivedAt ? format(parse(partnerCase.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.partnerCommissionStartToPay ? 'Sim' : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.partnerCommissionStartPaid ? 'Sim' : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.distributedAt ? format(parse(partnerCase.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: (partnerCase.caseNumber ? partnerCase.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.paymentReceivedAt ? format(parse(partnerCase.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.partnerCommissionEndToPay ? 'Sim' : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.partnerCommissionEndPaid ? 'Sim' : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const header = [
    {
      table: {
        widths: [70, 120, 70, 120, '*', '*', '*', '*', 70, 150],
        body: [
          [
            {
              text: 'Período:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: (reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              colSpan: 7,
              border: [false, true, false, false],
              fontSize: 8,
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: 'Casos previstos:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: Number(reportData.amountToPay) > 0 ? Number(reportData.amountToPay).toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
          ],
          [
            {
              text: 'Produto:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.product ? reportData.product : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              colSpan: 7,
              border: [false, false, false, true],
              fontSize: 8,
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: 'Honorários recebidos:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: Number(reportData.amountPaid) > 0 ? Number(reportData.amountPaid).toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
          ],
        ],
      },
    },
  ];

  const details1 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', '*', 45, 60, 50, 70, 90, 70, 50, 40],
        body: [
          [
            {
              text: 'CASOS DE CLIENTES INDICADOS PELO PARCEIRO',
              style: 'tableHeader',
              colSpan: 10,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA KIT',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PREVISTO INÍCIO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PAGO INÍCIO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA DISTRIBUIÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'Nº PROCESSO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA RECEBIMENTO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PREVISTO FIM',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PAGO FIM',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart1,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  function footer(currentPage, pageCount) {
    return {
      columns: [
        {
          text: `Gerado em: ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`,
          fontSize: 7,
          margin: [20, 10, 0, 0], // left, top, right, bottom
        },
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0], // left, top, right, bottom
        },
      ],
    };
  }

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [15, 40, 15, 40],
    content: [reportTitle, header, reportData.cases.length > 0 ? details1 : ''],
    footer,
  };

  return docDefinitions;
}

export default Report5PDF;
