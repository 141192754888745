import { useNavigate } from 'react-router-dom';

import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';

import Alert from '../../components/Modals/Alert';
import BarChart from '../../components/Dashboard/BarChart';
import CasesCount from '../../components/Dashboard/CasesCount';
import CasesNearDeadline from '../../components/Dashboard/CasesNearDeadline';
import DoughnutChart from '../../components/Dashboard/DoughnutChart';
import DrawerMenu from '../../components/DrawerMenu';
import ExpectedBenefitDateNearDeadline from '../../components/Dashboard/ExpectedBenefitDateNearDeadline';
import Header from '../../components/Header';

// Seção de valores de cálculos e metas retirada em 13/05/2024
// import EvaluationValues from '../../components/Dashboard/EvaluationValues';
// import Goals from '../../components/Dashboard/Goals';

import './style.css';
import '../../style.css';

function Home() {
  const navigate = useNavigate();

  const { activeUser, token } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState();

  const retrieveDashboardData = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'dashboard', reqData);

      setDashboardData(data);
      console.log({ data })
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Home/index.js - retrieveDashboardData',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  useEffect(() => {
    retrieveDashboardData();
  }, [retrieveDashboardData]);

  useEffect(() => {
    if (selectedEvaluation) {
      navigate(`/evaluation-workflow/${selectedEvaluation.workflowId}/${selectedEvaluation.id}`);
    }
  }, [selectedEvaluation]);

  return (
    <div className='page-container'>
      <Header />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
        </div>
        {dashboardData && (
          <div className='page-dashboard-area'>
            {/* Retirada da seção de valores de cálculos e metas em 13/05/2024 */}
            {/* <div className='dashboard-section-1'> */}
            {/* <CasesCount dashboardData={dashboardData} /> */}
            {/* <DoughnutChart chartData={[dashboardData.distributedCases, dashboardData.negativeResults, dashboardData.onGoingCases, dashboardData.coldCases]} /> */}
            {/* </div> */}
            {/* <div className='dashboard-section-2'> */}
            {/* <EvaluationValues dashboardData={dashboardData} /> */}
            {/* <Goals dashboardData={dashboardData} /> */}
            {/* </div> */}
            {/* <div className='dashboard-section-3'> */}
            {/* <BarChart chartData={dashboardData.evaluationsValues} /> */}
            {/* </div> */}
            {/* <div className='dashboard-section-3'> */}
            {/* <CasesNearDeadline */}
            {/* dashboardData={dashboardData} */}
            {/* setSelectedEvaluation={setSelectedEvaluation} */}
            {/* setAlertMessage={setAlertMessage} */}
            {/* setOpenAlert={setOpenAlert} */}
            {/* /> */}
            {/* </div> */}

            {/* Versãso sem seção de valores de cálculos e metas retirada em 13/05/2024 */}
            <div className='dashboard-object-1'>
              <CasesCount dashboardData={dashboardData} />
            </div>
            <div className='dashboard-object-2'>
              <DoughnutChart chartData={[dashboardData.signedCases, dashboardData.negativeResults, dashboardData.onGoingCases, dashboardData.coldCases]} />
            </div>
            <div className='dashboard-object-3'>
              <ExpectedBenefitDateNearDeadline
                dashboardData={dashboardData}
                setSelectedEvaluation={setSelectedEvaluation}
                setAlertMessage={setAlertMessage}
                setOpenAlert={setOpenAlert}
              />
            </div>
            <div className='dashboard-object-4'>
              <BarChart chartData={dashboardData.evaluationsValues} />
            </div>
            <div className='dashboard-object-5'>
              <CasesNearDeadline
                dashboardData={dashboardData}
                setSelectedEvaluation={setSelectedEvaluation}
                setAlertMessage={setAlertMessage}
                setOpenAlert={setOpenAlert}
              />
            </div>
          </div>
        )}
      </div>

      {openAlert && (
        <Alert openAlert={openAlert} setOpenAlert={setOpenAlert} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      )}
    </div>
  );
}

export default Home;
