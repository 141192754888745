import './style.css';

function BlueButton({ buttonText, buttonWidth }) {
  return (
    <>
      <input
        type='button'
        className='blue-button'
        value={buttonText}
        style={{ width: buttonWidth }}
      />
    </>
  );
}

export default BlueButton;
