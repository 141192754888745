import APIError from '../errors/APIError';

// export const baseURL = 'http://localhost:3003'; // Localhost
// export const baseURL = 'https://maadv-management.herokuapp.com'; //Heroku
export const baseURL = 'https://gestao-api.marcosandreadvogados.com.br'; // Producao

const targets = [
  ['activeClientsReport', 'reports/active-clients'],
  ['benefitPayingEntities', 'benefit-paying-entities'],
  ['clientLogs', 'logs/Cliente'],
  ['clients', 'clients'],
  ['clientsReport', 'reports/clients'],
  ['commissionBasis', 'commission-basis'],
  ['dashboard', 'reports/dashboard'],
  ['evaluationDocs', 'evaluation-docs'],
  ['evaluationLogs', 'logs/Avaliação'],
  ['evaluationReports', 'evaluation-reports'],
  ['evaluations', 'evaluations'],
  ['evaluationsReport', 'reports/evaluations'],
  ['financialTransactionCategories', 'financial-transaction-categories'],
  ['financialTransactions', 'financial-transactions'],
  ['login', 'login'],
  ['logs', 'logs'],
  ['origins', 'origins'],
  ['partnerReport', 'reports/partner'],
  ['partnerLogs', 'logs/Parceiro'],
  ['partners', 'partners'],
  ['patologies', 'patologies'],
  ['products', 'products'],
  ['productsGroups', 'products-groups'],
  ['productsWorkflows', 'products-workflows'],
  ['resetPassword', 'users/reset-password'],
  ['resultsReport', 'reports/results'],
  ['unclosedReport', 'reports/unclosed'],
  ['userCategories', 'user-categories'],
  ['users', 'users'],
];

class APIFetch {
  async fetch(method, target, data) {
    const targetValidation = targets.find((item) => item[0].toUpperCase() === target.toUpperCase());

    let targetUrl = !targetValidation ? target : targetValidation[1];

    let response = null;
    let body = null;

    switch (method) {
      case 'GET':
        if (target !== 'dashboard' && targetUrl.includes('reports/') && data.query) {
          targetUrl += `?type=${data.query.type}${data.query.period !== undefined ? `&period=${data.query.period}` : ''}${data.query.product !== undefined ? `&product=${data.query.product}` : ''}${data.query.origin !== undefined ? `&origin=${data.query.origin}` : ''}${data.query.partner !== undefined ? `&partner=${data.query.partner}` : ''}`;
        } else {
          targetUrl += `${data?.id ? `/${data?.id}` : ''}`;
        }
        response = await fetch(`${baseURL}/${targetUrl}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${!data.token ? JSON.parse(localStorage.getItem('MAADV')).token : data.token}`,
          },
        });
        break;

      case 'POST':
        if (target === 'login') {
          response = await fetch(`${baseURL}/${targetUrl}`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data.body),
            headers: {
              'Content-type': 'application/json',
            },
          });
        } else {
          response = await fetch(`${baseURL}/${targetUrl}${data?.id ? `/${data?.id}` : ''}`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data.body),
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${!data.token ? JSON.parse(localStorage.getItem('MAADV')).token : data.token}`,
            },
          });
        }
        break;

        case 'PUT':
          if (target==='resetPassword')  {
            response = await fetch(`${baseURL}/${targetUrl}${data?.id ? `/${data?.id}` : ''}`, {
              method: 'PUT',
              mode: 'cors',
              body: JSON.stringify(data.body),
              headers: {
                'Content-type': 'application/json',
              },
            });
          }else{
            response = await fetch(`${baseURL}/${targetUrl}${data?.id ? `/${data?.id}` : ''}`, {
              method: 'PUT',
              mode: 'cors',
              body: JSON.stringify(data.body),
              headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${!data.token ? JSON.parse(localStorage.getItem('MAADV')).token : data.token}`,
              },
            });
          }
          break;

      case 'DELETE':
        response = await fetch(`${baseURL}/${targetUrl}/${data.id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${!data.token ? JSON.parse(localStorage.getItem('MAADV')).token : data.token}`,
          },
        });
        break;

      default:
        break;
    }

    if (!response) {
      throw new APIError(response, body);
    }

    const contentType = response.headers.get('Content-Type');

    if (contentType.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      return body;
    }

    throw new APIError(response, body);
  }

  async retrieveAddress(processedZipCode) {
    const response = await fetch(`https://viacep.com.br/ws/${processedZipCode}/json/`);
    const body = await response.json();
    if (response.ok) {
      return body;
    }

    throw new APIError(response, body);
  }
}

const API = new APIFetch();

export { API };
