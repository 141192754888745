import {
  useEffect,
  useState,
} from 'react';

import {
  Input,
  Popover,
} from 'antd';

import {
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';

import BlueButton from '../../Buttons/BlueButton';

import './style.css';

function TextFilter({
  applyFilters, column, filter, active,
}) {
  const [searchText, setSearchText] = useState('');

  function handleUndoFilter() {
    const updatedFilter = { ...filter.current };
    delete updatedFilter[column];
    filter.current = { ...updatedFilter };
    setSearchText('');
    applyFilters();
  }

  useEffect(() => {
    const recoveredFilters = JSON.parse(sessionStorage.getItem('MAADVEvaluationFilters'));
    if (recoveredFilters) {
      if (recoveredFilters[column]?.length > 0) {
        setSearchText(recoveredFilters[column]);
      }
    }
  }, []);

  useEffect(() => {
    const isEmpty = searchText.length === 0;

    const updatedFilter = { ...filter.current };

    if (isEmpty && updatedFilter[column]) {
      delete updatedFilter[column];
      filter.current = { ...updatedFilter };
      applyFilters();
    }

    if (!isEmpty && updatedFilter[column] !== searchText) {
      updatedFilter[column] = searchText;
      filter.current = { ...updatedFilter };
      applyFilters();
    }
  }, [searchText, filter, column, applyFilters]);

  const content = (
    <>
      <div className='content-text-filter'>
        <Input
          className='input-text-filter'
          type='text'
          placeholder='Digite sua busca...'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {active && (
          <div
            className='undo-filter-text-filter'
            onClick={handleUndoFilter}
          >
            <BlueButton buttonText='Limpar filtro' />
          </div>
        )}
      </div>
    </>
  );

  return (
    <div>
      <div>
        <Popover
          placement='bottom'
          content={content}
          trigger='click'
        >
          {active ? (
            <FilterFilled style={{ cursor: 'pointer' }} />
          ) : (
            <FilterOutlined style={{ cursor: 'pointer' }} />
          )}
        </Popover>
      </div>
    </div>
  );
}

export default TextFilter;
