import { createContext } from 'react';

export const PaginationContext = createContext();

export function PaginationProvider({ children }) {
  const recordsPerPage = 10;

  function totalPages(array) {
    return Math.ceil(array?.length / recordsPerPage);
  }

  function sliceCurrentList(array, currentPage) {
    const start = (currentPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    return array.slice(start, end);
  }

  return (
    <PaginationContext.Provider
      value={{
        totalPages,
        sliceCurrentList,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
}
