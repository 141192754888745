import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AuthContext } from '../../../contexts/auth';
import { PaginationContext } from '../../../contexts/pagination';

import { API } from '../../../services/api';
import Filters from '../../../services/filters';

import Alert from '../../Modals/Alert';
import CheckboxFilter from '../../Filters/CheckboxFilter';
import Confirmation from '../../Modals/Confirmation';
import ListRows from './ListRows';
import RadioFilter from '../../Filters/RadioFilter';
import TablePagination from '../../TablePagination';

import '../../../style.css';

function Products({ products, setSelectedProduct, setUpdateList }) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const { sliceCurrentList } = useContext(PaginationContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState({});

  const filter = useRef({});
  const operation = useRef();
  const productId = useRef();

  function handleSort(column) {
    const currentOrder = sortOrder[column] || 'asc';

    const sortedData = [...products];

    sortedData.sort((a, b) => {
      const aValue = a[column] || '';
      const bValue = b[column] || '';

      if (column === 'active') {
        return currentOrder === 'asc'
          ? (aValue ? 'Ativo' : 'Inativo').localeCompare((bValue ? 'Ativo' : 'Inativo'))
          : (bValue ? 'Ativo' : 'Inativo').localeCompare((aValue ? 'Ativo' : 'Inativo'));
      }

      const numericColumns = ['evaluations', 'onGoing', 'distributed', 'unsuccessful', 'coldCases'];

      if (numericColumns.includes(column)) {
        return currentOrder === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }

      return currentOrder === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setCurrentList(sortedData);
    setSortOrder({ ...sortOrder, [column]: currentOrder === 'asc' ? 'desc' : 'asc' });
  }

  function handleFilterChange() {
    if (Object.keys(filter.current).length === 0) {
      setNoResultsFound(false);
      setCurrentList(products);
      return;
    }

    let tempList = products;

    tempList = tempList.filter((item) => {
      const productMatch = Filters.filterData(filter.current.product, item.product);
      const typeMatch = Filters.filterData(filter.current.type, item.type);
      const activeMatch = Filters.filterData(filter.current.active, (item.active ? 'Ativo' : 'Inativo'));

      return productMatch && typeMatch && activeMatch;
    });

    setNoResultsFound(tempList.length === 0);

    setCurrentList(tempList);
  }

  function handleConfirmRemoveProduct(product) {
    setConfirmationMessage(`Tem certeza de que deseja remover/inativar o produto ${product.product}?`);
    operation.current = 'DELETE';
    productId.current = product.id;
    setOpenConfirmation(true);
  }

  function handleConfirmActivateProduct(product) {
    setConfirmationMessage(`Tem certeza de que deseja ativar o produto ${product.product}?`);
    operation.current = 'PUT';
    productId.current = product.id;
    setOpenConfirmation(true);
  }

  const manageProductStatus = useCallback(async () => {
    try {
      const reqData = {
        id: productId.current,
        token,
      };

      if (operation.current === 'PUT') {
        reqData.body = { active: true };
      }

      const data = await API.fetch(operation.current, 'products', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Lists/Products/index.js - manageProductStatus',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, setUpdateList]);

  useEffect(() => {
    if (confirmation) {
      manageProductStatus();
      setConfirmation(false);
      operation.current = '';
      productId.current = '';
    }
  }, [confirmation, manageProductStatus]);

  useEffect(() => {
    setCurrentList(products);
  }, [products]);

  return (
    <div className='table-component-container'>
      <div className='table-component-table-area'>
        <table className='table-component-table-body'>
          <thead className='table-component-table-thead'>
            <th
              className='table-component-table-th table-component-table-th-first-column'
              style={{
                minWidth: 360,
                maxWidth: 'auto',
              }}
            >
              <span className='table-component-table-header'>
                PRODUTO
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('product')}
                    >
                      {sortOrder.product === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(products?.map((item) => item.product).sort())]}
                      column='product'
                      filter={filter}
                      active={filter.current?.product?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 120,
                minWidth: 120,
                maxWidth: 120,
              }}
            >
              <span className='table-component-table-header'>
                TIPO
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('type')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.type === 'asc' ? '▲' : '▼'}
                </span>
                <CheckboxFilter
                  applyFilters={handleFilterChange}
                  filterOptions={[...new Set(products?.map((item) => item.type).sort())]}
                  column='type'
                  filter={filter}
                  active={filter.current?.type?.length > 0}
                />
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 120,
                minWidth: 120,
                maxWidth: 120,
              }}
            >
              <span className='table-component-table-header'>
                AVALIAÇÕES
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('evaluations')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.evaluations === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 145,
                minWidth: 145,
                maxWidth: 145,
              }}
            >
              <span className='table-component-table-header'>
                EM ANDAMENTO
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('onGoing')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                DISTRIBUÍDOS
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('distributed')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 105,
                minWidth: 105,
                maxWidth: 105,
              }}
            >
              <span className='table-component-table-header'>
                BAIXADOS
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('unsuccessful')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 125,
                minWidth: 125,
                maxWidth: 125,
              }}
            >
              <span className='table-component-table-header'>
                CASOS FRIOS
                <span
                  className='table-component-table-filter-area'
                  onClick={() => handleSort('coldCases')}
                  style={{
                    margin: '0 -20px 0 auto',
                    cursor: 'pointer',
                  }}
                >
                  {sortOrder.clients === 'asc' ? '▲' : '▼'}
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 110,
                minWidth: 110,
                maxWidth: 110,
              }}
            >
              <span className='table-component-table-header'>
                STATUS
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('active')}
                    >
                      {sortOrder.active === 'asc' ? '▲' : '▼'}
                    </span>
                    <RadioFilter
                      applyFilters={handleFilterChange}
                      filterOptions={['Ativo', 'Inativo']}
                      column='active'
                      filter={filter}
                      active={filter.current?.active?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th table-component-table-th-last-column'
              style={{
                width: 60,
                minWidth: 60,
                maxWidth: 60,
              }}
            />
          </thead>
          <tbody
            className='table-component-rows-area'
            style={{ height: '100px' }}
          >
            {(products.length > 0) && (
              sliceCurrentList(currentList, currentPage).map((product) => (
                <ListRows
                  key={product.id}
                  product={product}
                  setSelectedProduct={setSelectedProduct}
                  handleConfirmRemoveProduct={handleConfirmRemoveProduct}
                  handleConfirmActivateProduct={handleConfirmActivateProduct}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      {noResultsFound && (
        <div className='table-component-no-results'>
          FILTRO APLICADO NÃO APRESENTOU RESULTADOS
        </div>
      )}
      <TablePagination
        array={currentList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Products;
