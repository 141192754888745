import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AuthContext } from '../../../contexts/auth';
import { PaginationContext } from '../../../contexts/pagination';

import { API } from '../../../services/api';
import Filters from '../../../services/filters';
import { formatTaxId } from '../../../services/format';

import Alert from '../../Modals/Alert';
import CheckboxFilter from '../../Filters/CheckboxFilter';
import Confirmation from '../../Modals/Confirmation';
import DateRangeFilter from '../../Filters/DateRangeFilter';
import ListRows from './ListRows';
import TablePagination from '../../TablePagination';
import TextFilter from '../../Filters/TextFilter';

import '../../../style.css';

function EvaluationsList({ evaluations, setUpdateList }) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const { sliceCurrentList } = useContext(PaginationContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState({});

  const filter = useRef({});
  const operation = useRef();
  const evaluationId = useRef();

  function handleSort(column) {
    const currentOrder = sortOrder[column] || 'asc';

    const sortedData = [...currentList];

    sortedData.sort((a, b) => {
      const aValue = a[column] || '';
      const bValue = b[column] || '';

      if (column === 'phones' || column === 'partners') {
        return currentOrder === 'asc'
          ? aValue.join('').localeCompare(bValue.join(''))
          : bValue.join('').localeCompare(aValue.join(''));
      }

      if (column === 'active') {
        return currentOrder === 'asc'
          ? (aValue ? 'Ativo' : 'Inativo').localeCompare((bValue ? 'Ativo' : 'Inativo'))
          : (bValue ? 'Ativo' : 'Inativo').localeCompare((aValue ? 'Ativo' : 'Inativo'));
      }

      return currentOrder === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setCurrentList(sortedData);
    setSortOrder({ ...sortOrder, [column]: currentOrder === 'asc' ? 'desc' : 'asc' });
  }

  function handleFilterChange() {
    sessionStorage.setItem('MAADVEvaluationFilters', JSON.stringify(filter.current));

    if (Object.keys(filter.current).length === 0) {
      setNoResultsFound(false);
      setCurrentList(evaluations);
      return;
    }

    let tempList = evaluations;

    tempList = tempList.filter((item) => {
      const createdAtMatch = Filters.filterDate(filter.current.periodCreatedAt, item.createdAt, item.createdAt);
      const clientMatch = Filters.filterText(filter.current.client, item.client);
      const taxIdMatch = Filters.filterText(filter.current.taxId, (item.taxId ? formatTaxId(item.taxId, 'cpf') : '-'));
      const phoneMatch = Filters.filterText(filter.current.phones, item.phones.join(','));
      const originMatch = Filters.filterData(filter.current.origin, item.origin);
      const partnerMatch = Filters.filterArrays(filter.current.partners, item.partners);
      const productMatch = Filters.filterData(filter.current.product, item.product);
      const retirementBenefitDateMatch = Filters.filterDate(filter.current.periodRetirementBenefitDate, item.retirementBenefitDate, item.retirementBenefitDate);
      const deadlineMatch = Filters.filterText(filter.current.deadline, item.deadline);
      const distributedAtMatch = Filters.filterDate(filter.current.periodDistributedAt, item.distributedAt, item.distributedAt);
      const typeMatch = Filters.filterData(filter.current.type, item.type);
      const statusMatch = Filters.filterData(filter.current.completeStatus, item.completeStatus);

      return createdAtMatch && clientMatch && taxIdMatch && phoneMatch && originMatch && partnerMatch && productMatch && retirementBenefitDateMatch && deadlineMatch && distributedAtMatch && typeMatch && statusMatch;
    });

    setNoResultsFound(tempList.length === 0);

    setCurrentList(tempList);
  }

  function handleConfirmDeactivateEvaluation(evaluation) {
    setConfirmationMessage(`Tem certeza de que deseja mover a avaliação de ${evaluation.product} do cliente ${evaluation.client} para casos frios?`);
    operation.current = 'deactivate';
    evaluationId.current = evaluation.id;
    setOpenConfirmation(true);
  }

  function handleConfirmActivateEvaluation(evaluation) {
    setConfirmationMessage(`Tem certeza de que deseja reativar a avaliação de ${evaluation.product} do cliente ${evaluation.client}?`);
    operation.current = 'activate';
    evaluationId.current = evaluation.id;
    setOpenConfirmation(true);
  }

  const manageEvaluationStatus = useCallback(async () => {
    try {
      const reqData = {
        id: evaluationId.current,
        body: {
          updateType: 'Ativar/Inativar',
          coldCase: (operation.current === 'deactivate'),
        },
        token,
      };

      const data = await API.fetch('PUT', 'evaluations', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Lists/Evaluations/index.js - manageEvaluationStatus',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, setUpdateList]);

  useEffect(() => {
    if (confirmation) {
      manageEvaluationStatus();
      setConfirmation(false);
      operation.current = '';
      evaluationId.current = '';
    }
  }, [confirmation, manageEvaluationStatus]);

  useEffect(() => {
    setCurrentList(evaluations);
  }, [evaluations]);

  useEffect(() => {
    const recoveredFilters = JSON.parse(sessionStorage.getItem('MAADVEvaluationFilters'));
    if (recoveredFilters) {
      filter.current = { ...recoveredFilters };
      handleFilterChange();
    }
  }, []);

  return (
    <div className='table-component-container'>
      <div className='table-component-table-area'>
        <table className='table-component-table-body'>
          <thead className='table-component-table-thead'>
            <th
              className='table-component-table-th table-component-table-th-first-column'
              style={{
                width: 120,
                minWidth: 120,
                maxWidth: 120,
              }}
            >
              <span className='table-component-table-header'>
                CRIAÇÃO
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('createdAt')}
                    >
                      {sortOrder.createdAt === 'asc' ? '▲' : '▼'}
                    </span>
                    <DateRangeFilter
                      applyFilters={handleFilterChange}
                      column='periodCreatedAt'
                      filter={filter}
                      active={Object.keys(filter.current?.periodCreatedAt || {}).length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                minWidth: 400,
                maxWidth: 'auto',
              }}
            >
              <span className='table-component-table-header'>
                CLIENTE
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('client')}
                    >
                      {sortOrder.client === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='client'
                      filter={filter}
                      active={filter.current?.client?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                CPF
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('taxId')}
                    >
                      {sortOrder.taxId === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='taxId'
                      filter={filter}
                      active={filter.current?.taxId?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                TELEFONE
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('phones')}
                    >
                      {sortOrder.phones === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='phones'
                      filter={filter}
                      active={filter.current?.phones?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 250,
                minWidth: 250,
                maxWidth: 250,
              }}
            >
              <span className='table-component-table-header'>
                ORIGEM
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('origin')}
                    >
                      {sortOrder.origin === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(evaluations?.map((item) => item.origin).sort())]}
                      column='origin'
                      filter={filter}
                      active={filter.current?.origin?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                PARCEIROS
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('partners')}
                    >
                      {sortOrder.partners === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(evaluations?.flatMap((item) => item.partners.map((partner) => partner)).sort())]}
                      column='partners'
                      filter={filter}
                      active={filter.current?.partners?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                minWidth: 250,
                maxWidth: 'auto',
              }}
            >
              <span className='table-component-table-header'>
                PRODUTO
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('product')}
                    >
                      {sortOrder.product === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(evaluations?.map((item) => item.product).sort())]}
                      column='product'
                      filter={filter}
                      active={filter.current?.product?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 100,
                minWidth: 100,
                maxWidth: 100,
              }}
            >
              <span className='table-component-table-header'>
                DIB
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('retirementBenefitDate')}
                    >
                      {sortOrder.retirementBenefitDate === 'asc' ? '▲' : '▼'}
                    </span>
                    <DateRangeFilter
                      applyFilters={handleFilterChange}
                      column='periodRetirementBenefitDate'
                      filter={filter}
                      active={Object.keys(filter.current?.periodRetirementBenefitDate || {}).length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 250,
                minWidth: 250,
                maxWidth: 250,
              }}
            >
              <span className='table-component-table-header'>
                PRAZO DECADÊNCIA
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('deadline')}
                    >
                      {sortOrder.deadline === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='deadline'
                      filter={filter}
                      active={filter.current?.deadline?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 160,
                minWidth: 160,
                maxWidth: 160,
              }}
            >
              <span className='table-component-table-header'>
                DISTRIBUIÇÃO
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('distributedAt')}
                    >
                      {sortOrder.distributedAt === 'asc' ? '▲' : '▼'}
                    </span>
                    <DateRangeFilter
                      applyFilters={handleFilterChange}
                      column='periodDistributedAt'
                      filter={filter}
                      active={Object.keys(filter.current?.periodDistributedAt || {}).length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 120,
                minWidth: 120,
                maxWidth: 120,
              }}
            >
              <span className='table-component-table-header'>
                TIPO
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('type')}
                    >
                      {sortOrder.type === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(evaluations?.map((item) => item.type).sort())]}
                      column='type'
                      filter={filter}
                      active={filter.current?.type?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 290,
                minWidth: 290,
                maxWidth: 290,
                position: 'sticky',
                right: 60,
                zIndex: 3,
              }}
            >
              <span className='table-component-table-header'>
                STATUS
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('completeStatus')}
                    >
                      {sortOrder.status === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(evaluations?.map((item) => item.completeStatus).sort())]}
                      column='completeStatus'
                      filter={filter}
                      active={filter.current?.completeStatus?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th table-component-table-th-last-column'
              style={{
                width: 60,
                minWidth: 60,
                maxWidth: 60,
              }}
            />
          </thead>
          <tbody
            className='table-component-rows-area'
            style={{ height: '100px' }}
          >
            {(evaluations.length > 0) && (
              sliceCurrentList(currentList, currentPage).map((evaluation) => (
                <ListRows
                  key={evaluation.id}
                  evaluation={evaluation}
                  handleConfirmDeactivateEvaluation={handleConfirmDeactivateEvaluation}
                  handleConfirmActivateEvaluation={handleConfirmActivateEvaluation}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      {noResultsFound && (
        <div className='table-component-no-results'>
          FILTRO APLICADO NÃO APRESENTOU RESULTADOS
        </div>
      )}
      <TablePagination
        array={currentList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default EvaluationsList;
