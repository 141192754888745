import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  format,
  parse,
} from 'date-fns';

import './style.css';

function Report5({ reportData, setSelectedEvaluation }) {
  const columnsList01 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'kitReceivedAt', label: 'DATA KIT', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'partnerCommissionStartToPay', label: 'PREVISTO INÍCIO', minWidth: 50, width: '8%', align: 'center',
    },
    {
      id: 'partnerCommissionStartPaid', label: 'PAGO INÍCIO', minWidth: 50, width: '8%', align: 'center',
    },
    {
      id: 'distributedAt', label: 'DATA DISTRIBUIÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'caseNumber', label: 'Nº PROCESSO', minWidth: 50, width: '8%', align: 'center',
    },
    {
      id: 'paymentReceivedAt', label: 'DATA RECEBIMENTO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'partnerCommissionEndToPay', label: 'PREVISTO FIM', minWidth: 50, width: '8%', align: 'center',
    },
    {
      id: 'partnerCommissionEndPaid', label: 'PAGO FIM', minWidth: 50, width: '8%', align: 'center',
    },
  ];

  return (
    <>
      <Paper sx={{
        width: '100%', height: '60vh', overflowY: 'scroll', paddingTop: '4px',
      }}
      >
        <header>
          <h3 className='report5-title'>
            {reportData.reportTitle}
            {' '}
            - PARCEIRO:
            {' '}
            {reportData.partner}
          </h3>
        </header>
        <div className='report5-summary'>
          <div className='report5-summary-column'>
            <div>
              <span>
                <strong>Período: </strong>
                {(reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Produto: </strong>
                {reportData.product ? reportData.product : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report5-summary-column'>
            <div>
              <span>
                <strong>Casos previstos: </strong>
                {Number(reportData.amountToPay) > 0 ? Number(reportData.amountToPay).toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Casos pagos: </strong>
                {Number(reportData.amountPaid) > 0 ? Number(reportData.amountPaid).toLocaleString() : '-'}
              </span>
            </div>
          </div>
        </div>
        {reportData.cases.length > 0
          && (
            <>
              <h4 className='report5-subtitle'>
                <strong>CASOS DE CLIENTES INDICADOS PELO PARCEIRO</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table className='table-body-fixed'>
                  <TableHead>
                    <TableRow>
                      {columnsList01.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.cases
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList01.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {(column.id === 'partnerCommissionStartToPay' || column.id === 'partnerCommissionStartPaid' || column.id === 'partnerCommissionEndToPay' || column.id === 'partnerCommissionEndPaid')
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value ? 'Sim' : '-'}
                                    </TableCell>
                                  )
                                  : ((column.id === 'kitReceivedAt' || column.id === 'distributedAt' || column.id === 'paymentReceivedAt')
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'caseNumber'
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? value.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </Paper>
    </>
  );
}

export default Report5;
