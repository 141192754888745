import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AuthContext } from '../../../contexts/auth';
import { PaginationContext } from '../../../contexts/pagination';

import { API } from '../../../services/api';
import Filters from '../../../services/filters';
import { formatTaxId } from '../../../services/format';

import Alert from '../../Modals/Alert';
import CheckboxFilter from '../../Filters/CheckboxFilter';
import Confirmation from '../../Modals/Confirmation';
import ListRows from './ListRows';
import RadioFilter from '../../Filters/RadioFilter';
import TablePagination from '../../TablePagination';
import TextFilter from '../../Filters/TextFilter';

import '../../../style.css';

function PartnersList({ partners, setSelectedPartner, setUpdateList }) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const { sliceCurrentList } = useContext(PaginationContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [currentList, setCurrentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState({});

  const filter = useRef({});
  const operation = useRef();
  const partnerId = useRef();

  function handleSort(column) {
    const currentOrder = sortOrder[column] || 'asc';

    const sortedData = [...partners];

    sortedData.sort((a, b) => {
      const aValue = a[column] || '';
      const bValue = b[column] || '';

      if (column === 'phones') {
        return currentOrder === 'asc'
          ? aValue.join('').localeCompare(bValue.join(''))
          : bValue.join('').localeCompare(aValue.join(''));
      }

      if (column === 'active') {
        return currentOrder === 'asc'
          ? (aValue ? 'Ativo' : 'Inativo').localeCompare((bValue ? 'Ativo' : 'Inativo'))
          : (bValue ? 'Ativo' : 'Inativo').localeCompare((aValue ? 'Ativo' : 'Inativo'));
      }

      return currentOrder === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setCurrentList(sortedData);
    setSortOrder({ ...sortOrder, [column]: currentOrder === 'asc' ? 'desc' : 'asc' });
  }

  function handleFilterChange() {
    if (Object.keys(filter.current).length === 0) {
      setNoResultsFound(false);
      setCurrentList(partners);
      return;
    }

    let tempList = partners;

    tempList = tempList.filter((item) => {
      const nameMatch = Filters.filterText(filter.current.name, item.name);
      const partnerCodeMatch = Filters.filterText(filter.current.partnerCode, item.partnerCode);
      const taxIdMatch = Filters.filterText(filter.current.taxId, (item.taxId ? formatTaxId(item.taxId, 'cpf') : '-'));
      const phoneMatch = Filters.filterText(filter.current.phones, item.phones.join(','));
      const emailMatch = Filters.filterText(filter.current.email, item.email);
      const locationMatch = Filters.filterData(filter.current.location, item.location);
      const activeMatch = Filters.filterData(filter.current.active, (item.active ? 'Ativo' : 'Inativo'));

      return nameMatch && partnerCodeMatch && taxIdMatch && phoneMatch && emailMatch && locationMatch && activeMatch;
    });

    setNoResultsFound(tempList.length === 0);

    setCurrentList(tempList);
  }

  function handleConfirmRemovePartner(partner) {
    setConfirmationMessage(`Tem certeza de que deseja remover/inativar o parceiro ${partner.name} (${partner.partnerCode})?`);
    operation.current = 'DELETE';
    partnerId.current = partner.id;
    setOpenConfirmation(true);
  }

  function handleConfirmActivatePartner(partner) {
    setConfirmationMessage(`Tem certeza de que deseja ativar o parceiro ${partner.name} (${partner.partnerCode})?`);
    operation.current = 'PUT';
    partnerId.current = partner.id;
    setOpenConfirmation(true);
  }

  const managePartnerStatus = useCallback(async () => {
    try {
      const reqData = {
        id: partnerId.current,
        token,
      };

      if (operation.current === 'PUT') {
        reqData.body = { active: true };
      }

      const data = await API.fetch(operation.current, 'partners', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Lists/Partners/index.js - managePartnerStatus',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, setUpdateList]);

  useEffect(() => {
    if (confirmation) {
      managePartnerStatus();
      setConfirmation(false);
      operation.current = '';
      partnerId.current = '';
    }
  }, [confirmation, managePartnerStatus]);

  useEffect(() => {
    setCurrentList(partners);
  }, [partners]);

  return (
    <div className='table-component-container'>
      <div className='table-component-table-area'>
        <table className='table-component-table-body'>
          <thead className='table-component-table-thead'>
            <th
              className='table-component-table-th table-component-table-th-first-column'
              style={{
                width: 400,
                minWidth: 400,
                maxWidth: 400,
              }}
            >
              <span className='table-component-table-header'>
                NOME
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('name')}
                    >
                      {sortOrder.name === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='name'
                      filter={filter}
                      active={filter.current?.name?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                CÓD.
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('partnerCode')}
                    >
                      {sortOrder.email === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='partnerCode'
                      filter={filter}
                      active={filter.current?.partnerCode?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 150,
                minWidth: 150,
                maxWidth: 150,
              }}
            >
              <span className='table-component-table-header'>
                CPF/CNPJ
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('taxId')}
                    >
                      {sortOrder.taxId === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='taxId'
                      filter={filter}
                      active={filter.current?.taxId?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 130,
                minWidth: 130,
                maxWidth: 130,
              }}
            >
              <span className='table-component-table-header'>
                TELEFONE
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('phones')}
                    >
                      {sortOrder.phones === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='phones'
                      filter={filter}
                      active={filter.current?.phones?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                minWidth: 400,
                maxWidth: 'auto',
              }}
            >
              <span className='table-component-table-header'>
                E-MAIL
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('email')}
                    >
                      {sortOrder.email === 'asc' ? '▲' : '▼'}
                    </span>
                    <TextFilter
                      applyFilters={handleFilterChange}
                      column='email'
                      filter={filter}
                      active={filter.current?.email?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                minWidth: 200,
                maxWidth: 'auto',
              }}
            >
              <span className='table-component-table-header'>
                CIDADE/UF
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('location')}
                    >
                      {sortOrder.location === 'asc' ? '▲' : '▼'}
                    </span>
                    <CheckboxFilter
                      applyFilters={handleFilterChange}
                      filterOptions={[...new Set(partners?.map((item) => item.location).sort())]}
                      column='location'
                      filter={filter}
                      active={filter.current?.location?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th'
              style={{
                width: 110,
                minWidth: 110,
                maxWidth: 110,
              }}
            >
              <span className='table-component-table-header'>
                STATUS
                <span className='table-component-table-filter-area'>
                  <>
                    <span
                      className='table-component-sort-button'
                      onClick={() => handleSort('active')}
                    >
                      {sortOrder.active === 'asc' ? '▲' : '▼'}
                    </span>
                    <RadioFilter
                      applyFilters={handleFilterChange}
                      filterOptions={['Ativo', 'Inativo']}
                      column='active'
                      filter={filter}
                      active={filter.current?.active?.length > 0}
                    />
                  </>
                </span>
              </span>
            </th>
            <th
              className='table-component-table-th table-component-table-th-last-column'
              style={{
                width: 60,
                minWidth: 60,
                maxWidth: 60,
              }}
            />
          </thead>
          <tbody
            className='table-component-rows-area'
            style={{ height: '100px' }}
          >
            {(partners.length > 0) && (
              sliceCurrentList(currentList, currentPage).map((partner) => (
                <ListRows
                  key={partner.id}
                  partner={partner}
                  setSelectedPartner={setSelectedPartner}
                  handleConfirmRemovePartner={handleConfirmRemovePartner}
                  handleConfirmActivatePartner={handleConfirmActivatePartner}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      {noResultsFound && (
        <div className='table-component-no-results'>
          FILTRO APLICADO NÃO APRESENTOU RESULTADOS
        </div>
      )}
      <TablePagination
        array={currentList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default PartnersList;
