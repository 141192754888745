import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@mui/material';

import '../../../../style.css';

function ListRows({
  product, setSelectedProduct, handleConfirmRemoveProduct, handleConfirmActivateProduct,
}) {
  return (
    <>
      <tr
        key={product.id}
        className='table-rows-component-tr'
      >
        <td
          className='table-rows-component-td table-rows-component-first-column'
          onClick={() => setSelectedProduct(product)}
        >
          {product.product}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.type}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.evaluations}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.onGoing}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.distributed}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.unsuccessful}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.coldCases}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedProduct(product)}
        >
          {product.active ? 'Ativo' : 'Inativo'}
        </td>
        <td
          className='table-rows-component-buttons-cell table-rows-component-last-column'
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          {product.active ? (
            <span
              className='table-buttons'
              onClick={() => handleConfirmRemoveProduct(product)}
            >
              <Tooltip title='Excluir/Inativar'>
                <DeleteForeverIcon style={{ color: 'red' }} />
              </Tooltip>
            </span>
          ) : (
            <span
              className='table-buttons activate-button'
              onClick={() => handleConfirmActivateProduct(product)}
            >
              <Tooltip title='Reativar'>
                <ReplayIcon style={{ color: 'green' }} />
              </Tooltip>
            </span>
          )}
        </td>
      </tr>
    </>
  );
}

export default ListRows;
