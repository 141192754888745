import {
  forwardRef,
  useEffect,
  useContext,
  useState,
} from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';
import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

import hide from '../../../images/hide.png';
import show from '../../../images/show.png';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function ChangePassword({ openChangePasswordModal, setOpenChangePasswordModal }) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [newPasswordStatus, setNewPasswordStatus] = useState('');
  const [newPasswordConfirmationStatus, setNewPasswordConfirmationStatus] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [visible, setVisible] = useState(false);

  function handleCloseChangePasswordModal() {
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirmation('');
    setOpenChangePasswordModal(false);
  }

  async function handleChangePassword() {
    try {
      if (!currentPassword) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar a senha atual.',
        });
        setOpenAlert(true);
        return;
      }

      if (!newPassword) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar a nova senha.',
        });
        setOpenAlert(true);
        return;
      }

      if (!newPasswordConfirmation) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário confirmar a nova senha.',
        });
        setOpenAlert(true);
        return;
      }

      if (newPassword !== newPasswordConfirmation) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'A nova senha e confirmação de nova senha não conferem.',
        });
        setOpenAlert(true);
        return;
      }

      if (
        newPassword.trim().length < 8
        || newPassword.trim().replace(/[a-zA-Z]/g, '').length === newPassword.trim().length
        || newPassword.trim().replace(/[0-9]/g, '').length === newPassword.trim().length
      ) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'A senha deve ter no mínimo 8 caracteres com letras e números.',
        });
        setOpenAlert(true);
        return;
      }

      const reqData = {
        id: activeUser.id,
        body: {
          currentPassword: currentPassword.trim(),
          newPassword: newPassword.trim(),
        },
        token,
      };

      const data = await API.fetch('PUT', 'users', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      handleCloseChangePasswordModal();
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'src/components/Modals/ChangePassword/index.js - handleChangePassword',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  useEffect(() => {
    if (newPassword.trim().length > 0 && (newPassword.trim().length < 8 || newPassword.trim().replace(/[a-zA-Z]/g, '').length === newPassword.trim().length || newPassword.trim().replace(/[0-9]/g, '').length === newPassword.trim().length)) {
      setNewPasswordStatus('A senha deve ter no mínimo 8 caracteres com letras e números.');
    } else {
      setNewPasswordStatus('');
    }

    if (newPassword.trim().length > 0 && newPasswordConfirmation.trim().length > 0 && newPassword.trim() !== newPasswordConfirmation.trim()) {
      setNewPasswordConfirmationStatus('A nova senha e confirmação de nova senha não conferem.');
    } else {
      setNewPasswordConfirmationStatus('');
    }
  }, [newPassword, newPasswordConfirmation]);

  return (
    <div>
      <div>
        <Dialog
          open={openChangePasswordModal}
          TransitionComponent={Transition}
          keepMounted
        >
          <div className='dialog-title'>
            Alteração de Senha
          </div>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '450px',
              height: ((newPasswordStatus && newPasswordConfirmationStatus) ? '245px' : ((newPasswordStatus || newPasswordConfirmationStatus) ? '225px' : '205px')),
            }}
          >
            <div className='change-pw-input'>
              <input
                autoFocus
                className='input'
                placeholder='Digite sua senha atual'
                variant='outlined'
                size='small'
                type={visible ? 'text' : 'password'}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <span className='login-password-visibility'>
                <img
                  src={visible ? hide : show}
                  alt={visible ? 'Ocultar' : 'Mostrar'}
                  onClick={() => setVisible(!visible)}
                  style={{ width: '20px' }}
                />
              </span>
            </div>
            <div className='change-pw-input'>
              <input
                className='input'
                placeholder='Digite sua nova senha'
                variant='outlined'
                size='small'
                type={visible ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            {newPasswordStatus
              && (
                <label className='password-alert'>
                  {newPasswordStatus}
                </label>
              )}
            <div className='change-pw-input'>
              <input
                className='input'
                placeholder='Confirme sua nova senha'
                variant='outlined'
                size='small'
                type={visible ? 'text' : 'password'}
                value={newPasswordConfirmation}
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              />
            </div>
            {newPasswordConfirmationStatus
              && (
                <label className='password-alert'>
                  {newPasswordConfirmationStatus}
                </label>
              )}
          </DialogContent>
          <div
            className='dialog-actions'
            style={{ zIndex: 9999, display: 'flex', justifyContent: 'flex-end' }}
          >
            <DialogActions sx={{ padding: '20px 25px' }}>
              <div onClick={handleCloseChangePasswordModal}>
                <GrayButton
                  buttonText='Cancelar'
                  buttonWidth='90px'
                />
              </div>
              <div onClick={handleChangePassword}>
                <BlueButton
                  className='recover-button'
                  buttonText='Alterar'
                />
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
      {
        openAlert
        && (
          <Alert
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
          />
        )
      }
    </div>
  );
}

export default ChangePassword;
