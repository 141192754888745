import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import {
  format,
  parse,
} from 'date-fns';

import logoReports from '../../../images/base64Images';

function Report4PDF(reportData) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', 150],
        body: [
          [
            {
              text: `${reportData.reportTitle} - PARCEIRO: ${reportData.partner}`,
              fontSize: 15,
              bold: true,
              alignment: 'center',
              margin: [140, 15, 0, 15],
              colSpan: 10,
              border: [true, true, false, true],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              width: 90,
              image: logoReports,
              border: [false, true, true, true],
              margin: [50, 10, 10, 10],
            },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
  ];

  const dataPart1 = reportData.cases.map((partnerCase) => [
    {
      text: partnerCase.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: partnerCase.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(partnerCase.evaluationValue) > 0
        ? (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString()
          + (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : ''))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.kitReceivedAt ? format(parse(partnerCase.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(partnerCase.partnerCommissionStartToPay) > 0
        ? (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString()
          + (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : ''))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(partnerCase.partnerCommissionStartPaid) > 0
        ? (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString()
          + (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : ''))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.distributedAt ? format(parse(partnerCase.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: (partnerCase.caseNumber ? partnerCase.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: partnerCase.paymentReceivedAt ? format(parse(partnerCase.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(partnerCase.partnerCommissionEndToPay) > 0
        ? (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString()
          + (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : ''))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(partnerCase.partnerCommissionEndPaid) > 0
        ? (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString()
          + (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : ''))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, true, true],
    },
  ]);

  const header = [
    {
      table: {
        widths: [70, 120, 70, 120, '*', '*', '*', '*', '*', 90, 140],
        body: [
          [
            {
              text: 'Período:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: (reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              colSpan: 8,
              border: [false, true, false, false],
              fontSize: 8,
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: 'Valor devido ao parceiro:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: `R$ ${Number(reportData.amountToPay) > 0
                ? (`${Number(Number(reportData.amountToPay).toFixed(2).toString()).toLocaleString()
                  + (Number(Number(reportData.amountToPay).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
                    ? ',00'
                    : (Number(Number(reportData.amountToPay).toFixed(2).toString()).toLocaleString().length - (Number(Number(reportData.amountToPay).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
                      ? '0'
                      : '')} (${reportData.cases.filter((currentCase) => !currentCase.paymentReceivedAt).length} casos)`)
                : '-'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
          ],
          [
            {
              text: 'Produto:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.product ? reportData.product : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              colSpan: 8,
              border: [false, false, false, true],
              fontSize: 8,
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: 'Valor pago ao parceiro:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: `R$ ${Number(reportData.amountPaid) > 0
                ? (`${Number(Number(reportData.amountPaid).toFixed(2).toString()).toLocaleString()
                  + (Number(Number(reportData.amountPaid).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
                    ? ',00'
                    : (Number(Number(reportData.amountPaid).toFixed(2).toString()).toLocaleString().length - (Number(Number(reportData.amountPaid).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
                      ? '0'
                      : '')} (${reportData.cases.filter((currentCase) => currentCase.paymentReceivedAt).length} casos)`)
                : '-'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
          ],
        ],
      },
    },
  ];

  const details1 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', '*', 60, 45, 55, 45, 50, 90, 50, 50, 45],
        body: [
          [
            {
              text: 'CASOS DE CLIENTES INDICADOS PELO PARCEIRO',
              style: 'tableHeader',
              colSpan: 11,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'VALOR DO CÁLCULO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA KIT',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PREVISTO INÍCIO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PAGO INÍCIO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA DISTRIBUIÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'Nº PROCESSO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA RECEBIMENTO',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PREVISTO FIM',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PAGO FIM',
              margin: [0, 8, 0, 8],
              fontSize: 7,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart1,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  function footer(currentPage, pageCount) {
    return {
      columns: [
        {
          text: `Gerado em: ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`,
          fontSize: 7,
          margin: [20, 10, 0, 0], // left, top, right, bottom
        },
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0], // left, top, right, bottom
        },
      ],
    };
  }

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [15, 40, 15, 40],
    content: [reportTitle, header, reportData.cases.length > 0 ? details1 : ''],
    footer,
  };
  return docDefinitions;
}

export default Report4PDF;
