import { forwardRef } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';
import BlueButton from '../../Buttons/BlueButton';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Alert({
  openAlert, setOpenAlert, alertMessage, setAlertMessage,
}) {
  function handleCloseAlert() {
    setOpenAlert(false);
    setAlertMessage('');
  }

  return (
    <div>
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAlert}
      >
        <div className='dialog-title'>{alertMessage.title}</div>
        <DialogContent sx={{ width: '350px' }}>
          <div>{alertMessage.message}</div>
        </DialogContent>
        <div className='dialog-actions'>
          <DialogActions onClick={handleCloseAlert}>
            <BlueButton buttonText='OK' />
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default Alert;
