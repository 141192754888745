import {
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import CloseIcon from '@mui/icons-material/Close';

import { AuthContext } from '../../../contexts/auth';

import { doubleSpaceRegex } from '../../../services/regex';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Product({
  openModal, setOpenModal, selectedProduct, setSelectedProduct, setUpdateList, auxiliaryLists,
}) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [docs, setDocs] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [product, setProduct] = useState();
  const [productGroup, setProductGroup] = useState();
  const [productType, setProductType] = useState();
  const [productWorkflow, setProductWorkflow] = useState();

  const title = selectedProduct ? 'Editar Produto' : 'Cadastrar Produto';

  useEffect(() => {
    if (selectedProduct) {
      setProduct(selectedProduct.product || '');
      setProductGroup(selectedProduct.productGroup || '');
      setProductType(selectedProduct.type || '');
      setProductWorkflow(selectedProduct.workflow || '');
      setDocs(selectedProduct.docs);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (productGroup) {
      const selectedGroup = auxiliaryLists.productsGroups.find((group) => group.productGroup === productGroup);

      if (selectedGroup) {
        setDocs(selectedGroup.docs.map((doc) => doc.doc));
      }
    }
  }, [productGroup]);

  function handleCloseProductModal() {
    setOpenModal(false);
    setProduct('');
    setSelectedProduct('');
  }

  async function handleSaveProduct() {
    try {
      if (!product) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o nome do produto.',
        });
        setOpenAlert(true);
        return;
      }

      if (!productGroup) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o grupo do produto.',
        });
        setOpenAlert(true);
        return;
      }

      const selectedGroup = auxiliaryLists.productsGroups.find((group) => group.productGroup === productGroup);

      if (!selectedGroup) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'Grupo selecionado não é um grupo válido.',
        });
        setOpenAlert(true);
        return;
      }

      if (!productType) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o tipo do produto.',
        });
        setOpenAlert(true);
        return;
      }

      if (!productWorkflow) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o fluxo de avaliação do produto.',
        });
        setOpenAlert(true);
        return;
      }

      const selectedWorkflow = auxiliaryLists.productsWorkflows.find((workflow) => workflow.workflow === productWorkflow);

      if (!selectedWorkflow) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'Fluxo de avaliação selecionado não é um fluxo válido.',
        });
        setOpenAlert(true);
        return;
      }

      const reqData = {
        body: {
          product,
          productGroupId: selectedGroup.id,
          type: productType,
          workflowId: selectedWorkflow.id,
        },
        token,
      };

      let data;

      if (!selectedProduct) {
        data = await API.fetch('POST', 'products', reqData);
      } else {
        reqData.id = selectedProduct.id;
        data = await API.fetch('PUT', 'products', reqData);
      }

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      handleCloseProductModal();

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Product/index.js - handleSaveProduct',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  return (
    <div>
      <div className='product-form-modal'>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
          style={{ zIndex: 9 }}
        >
          <div className='title-modal'>
            <div className='dialog-title'>
              {title}
              <div
                className='close-button'
                onClick={handleCloseProductModal}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          <DialogContent>
            <div className='product-modal-row'>
              <div className='product-input'>
                <label
                  className='product-label'
                  style={{ marginBottom: '5px' }}
                >
                  Produto
                </label>
                <input
                  style={{ marginBottom: '15px' }}
                  autoFocus
                  className='input'
                  placeholder='Produto'
                  variant='outlined'
                  size='small'
                  type='text'
                  disabled={activeUser.category !== 'Sócio'}
                  value={product}
                  onChange={(e) => setProduct(e.target.value.replace(doubleSpaceRegex, ' '))}
                />
              </div>
              <div className='product-input'>
                <label
                  className='product-label'
                  style={{ marginBottom: '5px' }}
                >
                  Grupo
                </label>
                <select
                  style={{ marginBottom: '15px' }}
                  className='input'
                  variant='outlined'
                  size='small'
                  type='text'
                  value={productGroup}
                  onChange={(e) => setProductGroup(e.target.value)}
                  disabled={selectedProduct.evaluations > 0}
                >
                  <option />
                  {auxiliaryLists.productsGroups.map((group) => (
                    <option>{group.productGroup}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='product-modal-row'>
              <div className='product-input-group'>
                <div>
                  <label
                    className='product-label'
                    style={{ marginBottom: '5px' }}
                  >
                    Tipo
                  </label>
                  <select
                    style={{ marginBottom: '15px' }}
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <option />
                    {auxiliaryLists.productsTypes.map((type) => (
                      <option>{type}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    className='product-label'
                    style={{ marginBottom: '5px' }}
                  >
                    Fluxo de avaliação
                  </label>
                  <select
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    value={productWorkflow}
                    onChange={(e) => setProductWorkflow(e.target.value)}
                    disabled={selectedProduct.evaluations > 0}
                  >
                    <option />
                    {auxiliaryLists.productsWorkflows.map((workflow) => (
                      <option>{workflow.workflow}</option>
                    ))}
                  </select>
                </div>
              </div>
              {docs.length > 0 && (
                <div
                  className='product-input-group'
                  style={{ paddingLeft: '6px' }}
                >
                  <label
                    className='product-label'
                    style={{ marginBottom: '5px' }}
                  >
                    Documentos para avaliação
                  </label>
                  {docs.map((doc) => (
                    <li className='product-docs'>{doc}</li>
                  ))}
                </div>
              )}
            </div>
          </DialogContent>
          {activeUser.category === 'Sócio' && (
            <div
              className='dialog-actions'
              style={{
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <DialogActions sx={{ padding: '20px 25px' }}>
                <div onClick={handleCloseProductModal}>
                  <GrayButton
                    buttonText='Cancelar'
                    buttonWidth='90px'
                  />
                </div>
                <div onClick={handleSaveProduct}>
                  <BlueButton
                    className='recover-button'
                    buttonText={selectedProduct ? 'Salvar' : 'Criar'}
                    buttonWidth={selectedProduct ? '70px' : '60px'}
                  />
                </div>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Product;
