import { useNavigate } from 'react-router-dom';

import {
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogContent,
  Slide,
} from '@mui/material/';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';
import {
  formatTaxId,
} from '../../../services/format';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Evaluation({
  openModal, setOpenModal, clients, products, evaluations, setUpdateList,
}) {
  const navigate = useNavigate();

  const { activeUser, token } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState('');
  const [client, setClient] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [openAlert, setOpenAlert] = useState(false);
  const [origin, setOrigin] = useState('');
  const [partner1, setPartner1] = useState('');
  const [partner2, setPartner2] = useState('');
  const [partner3, setPartner3] = useState('');
  const [product, setProduct] = useState('');
  const [taxId, setTaxId] = useState('');

  async function handleCreateEvaluation() {
    try {
      setAlertMessage('');

      if (!client) {
        setAlertMessage({ title: 'Alerta!', message: 'É necessário informar o cliente.' });
        setOpenAlert(true);
        return;
      }

      if (!product) {
        setAlertMessage({ title: 'Alerta!', message: 'É necessário informar o produto.' });
        setOpenAlert(true);
        return;
      }

      const reqData = {
        body: {
          clientId: clients.find((item) => item.name === client).id,
          productId: products.find((item) => item.product === product).id,
        },
        token,
      };

      const data = await API.fetch('POST', 'evaluations', reqData);

      setUpdateList(true);

      setOpenModal(false);

      navigate(`/evaluation-workflow/${products.find((item) => item.product === product).workflowId}/${data.evaluationId}`);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Evaluation/index.js - handleCreateEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  useEffect(() => {
    setFilteredProducts([]);
    setTaxId('');
    setPartner1('');
    setPartner2('');
    setPartner3('');
    setOrigin('');
    if (client) {
      const tempEvaluationsList = evaluations.filter((evaluation) => evaluation.client === client);
      const tempProductsList = products.filter((item) => !tempEvaluationsList.find((evaluation) => evaluation.product === item.product));
      setFilteredProducts(tempProductsList);
      const selectedClient = clients.find((item) => item.name === client);
      setTaxId(selectedClient.taxId ? formatTaxId(selectedClient.taxId, 'cpf') : '');
      setPartner1(selectedClient.partnerCode1 || '');
      setPartner2(selectedClient.partnerCode2 || '');
      setPartner3(selectedClient.partnerCode3 || '');
      setOrigin(selectedClient.origin || '');
    }
  }, [client]);

  return (
    <div className='evaluation-form-modal'>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        style={{ zIndex: 9 }}
      >
        <div className='title-modal'>
          <div className='dialog-title'>
            Cadastrar Avaliação
            <div
              className='close-button'
              onClick={() => setOpenModal(false)}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <DialogContent sx={{ width: '899px' }}>
          <div className='form-header-row'>
            <div style={{
              width: '392px',
              minWidth: '392px',
              maxWidth: '392px',
            }}
            >
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <label className='form-label'>
                  Cliente
                </label>
              </div>
              <select
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={client}
                onChange={(e) => setClient(e.target.value)}
              >
                <option />
                {clients.map((item) => (
                  <option>{item.name}</option>
                ))}
              </select>
            </div>
            <div style={{
              width: '110px',
              minWidth: '110px',
            }}
            >
              <label className='form-label'>
                CPF
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                style={{ textAlign: 'center' }}
                value={taxId}
                disabled
              />
            </div>
            <div style={{
              width: '105px',
              minWidth: '105px',
            }}
            >
              <label className='form-label'>
                Parceiros
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={partner1}
                disabled
              />
            </div>
            <div style={{
              width: '105px',
              minWidth: '105px',
            }}
            >
              <label
                className='form-label'
                style={{ height: '18px' }}
              />
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={partner2}
                disabled
              />
            </div>
            <div style={{
              width: '105px',
              minWidth: '105px',
            }}
            >
              <label
                className='form-label'
                style={{ height: '18px' }}
              />
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={partner3}
                disabled
              />
            </div>
          </div>
          <div className='form-header-row'>
            <div style={{
              width: '392px',
              minWidth: '392px',
              maxWidth: '392px',
            }}
            >
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <label className='form-label'>
                  Produto
                </label>
              </div>
              <select
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              >
                <option />
                {filteredProducts.map((item) => (
                  <option>{item.product}</option>
                ))}
              </select>
            </div>
            <div style={{
              width: '449px',
              minWidth: '449px',
              maxWidth: '449px',
            }}
            >
              <label className='form-label'>
                Origem
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={origin}
                disabled
              />
            </div>
          </div>
          <div
            className='form-header-row'
            style={{
              marginTop: 8,
              justifyContent: 'flex-end',
            }}
          >
            <div onClick={() => setOpenModal(false)}>
              <GrayButton
                buttonText='Cancelar'
                buttonWidth='100px'
              />
            </div>
            <div onClick={handleCreateEvaluation}>
              <BlueButton
                buttonText='Criar'
                buttonWidth='60px'
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Evaluation;
