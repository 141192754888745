import {
  forwardRef,
  useContext,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';
import PartnerFormRow1 from './PartnerFormRow1';
import PartnerFormRow2 from './PartnerFormRow2';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Partner({
  openModal, setOpenModal, selectedPartner, setSelectedPartner, partnerLogs, setPartnerLogs, setUpdateList,
}) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [partnerData, setPartnerData] = useState();

  const title = selectedPartner ? 'Editar Parceiro' : 'Cadastrar Parceiro';

  function handleClosePartnerModal() {
    setOpenModal(false);
    setPartnerData('');
    setSelectedPartner('');
  }

  async function handleSavePartner() {
    try {
      setAlertMessage('');
      if (partnerData) {
        if (partnerData !== undefined) {
          if (!selectedPartner && !partnerData.name) {
            setAlertMessage({ title: 'Alerta!', message: 'É necessário informar o nome do parceiro.' });
            setOpenAlert(true);
            return;
          }

          if (!selectedPartner && !partnerData.partnerCode) {
            setAlertMessage({ title: 'Alerta!', message: 'É necessário informar o código do parceiro.' });
            setOpenAlert(true);
            return;
          }

          const reqData = {
            body: partnerData,
            token,
          };

          let data;

          if (!selectedPartner) {
            data = await API.fetch('POST', 'partners', reqData);
          } else {
            reqData.id = selectedPartner.id;
            data = await API.fetch('PUT', 'partners', reqData);
          }

          setAlertMessage({
            title: 'Sucesso!',
            message: data,
          });

          setOpenAlert(true);

          handleClosePartnerModal();

          setUpdateList(true);
        }
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Partner/index.js - handleSavePartner',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  return (
    <div className='partner-form-modal'>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        style={{ zIndex: 9 }}
      >
        <div className='title-modal'>
          <div className='dialog-title'>
            {title}
            <div
              className='close-button'
              onClick={handleClosePartnerModal}
            >
              <CloseIcon />

            </div>
          </div>
        </div>
        <DialogContent>
          <PartnerFormRow1
            selectedPartner={selectedPartner}
            partnerData={partnerData}
            setPartnerData={setPartnerData}
          />
          <PartnerFormRow2
            selectedPartner={selectedPartner}
            partnerData={partnerData}
            setPartnerData={setPartnerData}
            partnerLogs={partnerLogs}
            setPartnerLogs={setPartnerLogs}
          />
        </DialogContent>
        {activeUser.category === 'Sócio' && (
          <div className='dialog-actions'>
            <DialogActions
              sx={{ padding: '20px 0px' }}
              style={{
                zIndex: 9999,
                display: 'flex',
                width: '100%',
              }}
            >
              <div className='action-buttons'>
                <div onClick={handleClosePartnerModal}>
                  <GrayButton
                    buttonText='Cancelar'
                    buttonWidth='90px'
                  />
                </div>
                <div onClick={handleSavePartner}>
                  <BlueButton
                    className='save-button'
                    buttonText={selectedPartner ? 'Salvar' : 'Criar'}
                    buttonWidth={selectedPartner ? '70px' : '60px'}
                  />
                </div>
              </div>
            </DialogActions>
          </div>
        )}
      </Dialog>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Partner;
