import {
  useEffect,
  useState,
} from 'react';

import {
  format,
  parse,
} from 'date-fns';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { doubleSpaceRegex } from '../../../../services/regex';

import {
  validateEmail,
  validateTaxId,
} from '../../../../services/validations';

import { formatTaxId } from '../../../../services/format';

import './style.css';

function ClientFormRow1({ selectedClient, clientData, setClientData }) {
  const [birthDate, setBirthDate] = useState();
  const [email, setEmail] = useState();
  const [invalidTaxId, setInvalidTaxId] = useState(false);
  const [name, setName] = useState();
  const [taxId, setTaxId] = useState();

  useEffect(() => {
    if (selectedClient !== undefined) {
      setBirthDate(selectedClient.birthDate ? format(parse(selectedClient.birthDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '');
      setEmail(selectedClient.email ? selectedClient.email : '');
      setName(selectedClient.name ? selectedClient.name : '');
      setTaxId(selectedClient.taxId ? formatTaxId(selectedClient.taxId, 'cpf') : '');
    }
  }, [selectedClient]);

  useEffect(() => {
    let currentData = {};

    if (clientData !== undefined && clientData !== '') {
      currentData = clientData;
    }

    if (birthDate && birthDate !== undefined) {
      if (format(parse(birthDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') !== selectedClient.birthDate) {
        currentData.birthDate = format(parse(birthDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');
      }
    } else {
      currentData.birthDate = null;
    }

    if (name) {
      if (name !== selectedClient.name) {
        currentData.name = name.trim().toUpperCase();
      }
    }

    if (email) {
      if (validateEmail(email)) {
        if (email.toLowerCase() !== selectedClient.email) {
          currentData.email = email.toLowerCase();
        }
      }
    } else {
      currentData.email = '';
    }

    if (taxId) {
      if (validateTaxId(taxId, 'cpf')) {
        if (taxId.replace(/\D/g, '') !== selectedClient.taxId) {
          currentData.taxId = taxId.replace(/\D/g, '');
        }
        setInvalidTaxId(false);
      } else {
        setInvalidTaxId(true);
      }
    } else {
      currentData.taxId = null;
    }

    setClientData(currentData);
  }, [birthDate, name, email, taxId, clientData, setClientData, selectedClient.birthDate, selectedClient.email, selectedClient.name, selectedClient.taxId]);

  function formatTaxIdInput(taxId) {
    const formattedTaxId = taxId
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
    setTaxId(formattedTaxId);
  }

  return (
    <div className='row1'>
      <div className='client-row1'>
        <div className='client-row1-col1'>
          <div className='client-name'>
            <label className='form-label'>
              Nome
            </label>
            <input
              autoFocus
              className='input'
              variant='outlined'
              size='small'
              type='text'
              value={name}
              // onChange={(e) => setName(e.target.value)}
              onChange={(e) => setName(e.target.value.replace(doubleSpaceRegex, ' '))}
            />
          </div>
          <div className='client-email'>
            <label className='form-label'>
              E-mail
            </label>
            <input
              className='input'
              variant='outlined'
              size='small'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className='client-row1-col2'>
          <div className='client-tax-id'>
            <label className='form-label'>
              CPF
            </label>
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={taxId}
                onChange={(e) => formatTaxIdInput(e.target.value)}
              />
              {invalidTaxId && (
                <span style={{ marginLeft: -35 }}>
                  <ErrorOutlineIcon style={{ color: 'red' }} />

                </span>
              )}
            </div>
          </div>
          <div className='client-date'>
            <label className='form-label'>
              Data de Nascimento
            </label>
            <input
              className='input'
              variant='outlined'
              size='small'
              type='date'
              style={{ textAlign: 'center' }}
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientFormRow1;
