import { utils, writeFile } from 'xlsx';

import {
  format,
  parse,
} from 'date-fns';

import { formatTaxId } from './format';

function report2Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Honorários previstos', `R$ ${Number(originalData.amountToReceive) > 0
      ? (Number(Number(originalData.amountToReceive).toFixed(2).toString()).toLocaleString()
        + (Number(Number(originalData.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(originalData.amountToReceive).toFixed(2).toString()).toLocaleString().length - (Number(Number(originalData.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`],
    ['Casos previstos', `${originalData.casesToReceive.length} casos`],
    ['Honorários recebidos', `R$ ${Number(originalData.amountReceived) > 0
      ? (Number(Number(originalData.amountReceived).toFixed(2).toString()).toLocaleString()
        + (Number(Number(originalData.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(originalData.amountReceived).toFixed(2).toString()).toLocaleString().length - (Number(Number(originalData.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`],
    ['Casos recebidos', `${originalData.casesReceived.length} casos`],
    ['Valor total de cálculos', `R$ ${Number(originalData.totalEvaluationValue) > 0
      ? (Number(Number(originalData.totalEvaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(originalData.totalEvaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(originalData.totalEvaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(originalData.totalEvaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`],
  ];

  const sortedDataSheet2 = [];

  originalData.casesToReceive.forEach((caseToReceive) => {
    caseToReceive.evaluationValue = `R$ ${Number(caseToReceive.evaluationValue) > 0
      ? (Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    caseToReceive.distributedAt = parse(caseToReceive.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date());
    caseToReceive.caseNumber = caseToReceive.caseNumber ? caseToReceive.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';
    caseToReceive.amountToReceive = `R$ ${Number(caseToReceive.amountToReceive) > 0
      ? (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString()
        + (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;

    sortedDataSheet2.push({
      CLIENTE: caseToReceive.client,
      ORIGEM: caseToReceive.origin,
      'PARCEIRO 1': caseToReceive.partner1,
      'PARCEIRO 2': caseToReceive.partner2,
      'PARCEIRO 3': caseToReceive.partner3,
      PRODUTO: caseToReceive.product,
      'VALOR DO CÁLCULO': caseToReceive.evaluationValue,
      'DATA DISTRIBUIÇÃO': caseToReceive.distributedAt,
      'Nº PROCESSO': caseToReceive.caseNumber,
      'HONORÁRIOS PREVISTOS': caseToReceive.amountToReceive,
    });
  });

  const sortedDataSheet3 = [];

  originalData.casesReceived.forEach((caseReceived) => {
    caseReceived.evaluationValue = `R$ ${Number(caseReceived.evaluationValue) > 0
      ? (Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    caseReceived.distributedAt = parse(caseReceived.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date());
    caseReceived.caseNumber = caseReceived.caseNumber ? caseReceived.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';
    caseReceived.paymentReceivedAt = parse(caseReceived.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date());
    caseReceived.amountReceived = `R$ ${Number(caseReceived.amountReceived) > 0
      ? (Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString()
        + (Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;

    sortedDataSheet3.push({
      CLIENTE: caseReceived.client,
      ORIGEM: caseReceived.origin,
      'PARCEIRO 1': caseReceived.partner1,
      'PARCEIRO 2': caseReceived.partner2,
      'PARCEIRO 3': caseReceived.partner3,
      PRODUTO: caseReceived.product,
      'VALOR DO CÁLCULO': caseReceived.evaluationValue,
      'DATA DISTRIBUIÇÃO': caseReceived.distributedAt,
      'Nº PROCESSO': caseReceived.caseNumber,
      'DATA RECEBIMENTO': caseReceived.paymentReceivedAt,
      'HONORÁRIOS RECEBIDOS': caseReceived.amountReceived,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);
  const worksheet3 = utils.json_to_sheet(sortedDataSheet3);

  worksheet2['!opts'] = { cellDates: true };
  worksheet3['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.casesToReceive.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'CASOS A RECEBER');
  }
  if (originalData.casesReceived.length > 0) {
    utils.book_append_sheet(workbook, worksheet3, 'CASOS RECEBIDOS');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 20 }, { wch: 20 }];

  if (originalData.casesToReceive.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 25 }];
  }

  if (originalData.casesReceived.length > 0) {
    worksheet3['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 20 }, { wch: 25 }];
  }

  const fileName = `${originalData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report3Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Casos previstos', `${originalData.casesToReceive.length} casos`],
    ['Casos recebidos', `${originalData.casesReceived.length} casos`],
  ];

  const sortedDataSheet2 = [];

  originalData.casesToReceive.forEach((caseToReceive) => {
    caseToReceive.distributedAt = parse(caseToReceive.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date());
    caseToReceive.caseNumber = caseToReceive.caseNumber ? caseToReceive.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';

    sortedDataSheet2.push({
      CLIENTE: caseToReceive.client,
      ORIGEM: caseToReceive.origin,
      'PARCEIRO 1': caseToReceive.partner1,
      'PARCEIRO 2': caseToReceive.partner2,
      'PARCEIRO 3': caseToReceive.partner3,
      PRODUTO: caseToReceive.product,
      'DATA DISTRIBUIÇÃO': caseToReceive.distributedAt,
      'Nº PROCESSO': caseToReceive.caseNumber,
    });
  });

  const sortedDataSheet3 = [];

  originalData.casesReceived.forEach((caseReceived) => {
    caseReceived.distributedAt = parse(caseReceived.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date());
    caseReceived.caseNumber = caseReceived.caseNumber ? caseReceived.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';
    caseReceived.paymentReceivedAt = parse(caseReceived.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date());

    sortedDataSheet3.push({
      CLIENTE: caseReceived.client,
      ORIGEM: caseReceived.origin,
      'PARCEIRO 1': caseReceived.partner1,
      'PARCEIRO 2': caseReceived.partner2,
      'PARCEIRO 3': caseReceived.partner3,
      PRODUTO: caseReceived.product,
      'DATA DISTRIBUIÇÃO': caseReceived.distributedAt,
      'Nº PROCESSO': caseReceived.caseNumber,
      'DATA RECEBIMENTO': caseReceived.paymentReceivedAt,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);
  const worksheet3 = utils.json_to_sheet(sortedDataSheet3);

  worksheet2['!opts'] = { cellDates: true };
  worksheet3['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.casesToReceive.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'CASOS A RECEBER');
  }
  if (originalData.casesReceived.length > 0) {
    utils.book_append_sheet(workbook, worksheet3, 'CASOS RECEBIDOS');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 20 }, { wch: 20 }];

  if (originalData.casesToReceive.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 25 }];
  }

  if (originalData.casesReceived.length > 0) {
    worksheet3['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 25 }, { wch: 20 }];
  }

  const fileName = `${reportData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report4Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Valor devido ao parceiro', `R$ ${Number(originalData.amountToPay) > 0
      ? (Number(Number(originalData.amountToPay).toFixed(2).toString()).toLocaleString()
        + (Number(Number(originalData.amountToPay).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(originalData.amountToPay).toFixed(2).toString()).toLocaleString().length - (Number(Number(originalData.amountToPay).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`],
    ['Casos previstos', `${originalData.cases.filter((partnerCase) => !partnerCase.paymentReceivedAt).length} casos`],
    ['Valor pago ao parceiro', `R$ ${Number(originalData.amountPaid) > 0
      ? (Number(Number(originalData.amountPaid).toFixed(2).toString()).toLocaleString()
        + (Number(Number(originalData.amountPaid).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(originalData.amountPaid).toFixed(2).toString()).toLocaleString().length - (Number(Number(originalData.amountPaid).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`],
    ['Casos recebidos', `${originalData.cases.filter((partnerCase) => partnerCase.paymentReceivedAt).length} casos`],
  ];

  const sortedData = [];

  originalData.cases.forEach((partnerCase) => {
    partnerCase.evaluationValue = `R$ ${Number(partnerCase.evaluationValue) > 0
      ? (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    partnerCase.kitReceivedAt = partnerCase.kitReceivedAt ? parse(partnerCase.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()) : '-';
    partnerCase.partnerCommissionStartToPay = `R$ ${Number(partnerCase.partnerCommissionStartToPay) > 0
      ? (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString()
        + (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionStartToPay).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    partnerCase.partnerCommissionStartPaid = `R$ ${Number(partnerCase.partnerCommissionStartPaid) > 0
      ? (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString()
        + (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionStartPaid).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    partnerCase.distributedAt = partnerCase.distributedAt ? parse(partnerCase.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()) : '-';
    partnerCase.caseNumber = partnerCase.caseNumber ? partnerCase.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';
    partnerCase.paymentReceivedAt = partnerCase.paymentReceivedAt ? parse(partnerCase.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()) : '-';
    partnerCase.partnerCommissionEndToPay = `R$ ${Number(partnerCase.partnerCommissionEndToPay) > 0
      ? (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString()
        + (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionEndToPay).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    partnerCase.partnerCommissionEndPaid = `R$ ${Number(partnerCase.partnerCommissionEndPaid) > 0
      ? (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString()
        + (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString().length - (Number(Number(partnerCase.partnerCommissionEndPaid).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;

    sortedData.push({
      CLIENTE: partnerCase.client,
      PRODUTO: partnerCase.product,
      'VALOR DO CÁLCULO': partnerCase.evaluationValue,
      'DATA KIT': partnerCase.kitReceivedAt,
      'PREVISTO INÍCIO': partnerCase.partnerCommissionStartToPay,
      'PAGO INÍCIO': partnerCase.partnerCommissionStartPaid,
      'DATA DISTRIBUIÇÃO': partnerCase.distributedAt,
      'Nº PROCESSO': partnerCase.caseNumber,
      'PREVISTO FIM': partnerCase.partnerCommissionEndToPay,
      'PAGO FIM': partnerCase.partnerCommissionEndPaid,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedData);

  worksheet2['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.cases.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'CASOS A RECEBER');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 20 }, { wch: 20 }];

  if (originalData.cases.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 20 }, { wch: 20 }];
  }

  const fileName = `${originalData.reportTitle} ${` - PARCEIRO: ${originalData.partner}`} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report5Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Casos previstos', `${Number(originalData.amountToPay).toLocaleString()} casos`],
    ['Casos recebidos', `${Number(originalData.amountPaid).toLocaleString()} casos`],
  ];

  const sortedData = [];

  originalData.cases.forEach((partnerCase) => {
    partnerCase.kitReceivedAt = partnerCase.kitReceivedAt ? parse(partnerCase.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()) : '-';
    partnerCase.partnerCommissionStartToPay = partnerCase.partnerCommissionStartToPay ? 'Sim' : '-';
    partnerCase.partnerCommissionStartPaid = partnerCase.partnerCommissionStartPaid ? 'Sim' : '-';
    partnerCase.distributedAt = partnerCase.distributedAt ? parse(partnerCase.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()) : '-';
    partnerCase.caseNumber = partnerCase.caseNumber ? partnerCase.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';
    partnerCase.paymentReceivedAt = partnerCase.paymentReceivedAt ? parse(partnerCase.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()) : '-';
    partnerCase.partnerCommissionEndToPay = partnerCase.partnerCommissionEndToPay ? 'Sim' : '-';
    partnerCase.partnerCommissionEndPaid = partnerCase.partnerCommissionEndPaid ? 'Sim' : '-';

    sortedData.push({
      CLIENTE: partnerCase.client,
      PRODUTO: partnerCase.product,
      'DATA KIT': partnerCase.kitReceivedAt,
      'PREVISTO INÍCIO': partnerCase.partnerCommissionStartToPay,
      'PAGO INÍCIO': partnerCase.partnerCommissionStartPaid,
      'DATA DISTRIBUIÇÃO': partnerCase.distributedAt,
      'Nº PROCESSO': partnerCase.caseNumber,
      'PREVISTO FIM': partnerCase.partnerCommissionEndToPay,
      'PAGO FIM': partnerCase.partnerCommissionEndPaid,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedData);

  worksheet2['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.cases.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'CASOS A RECEBER');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 20 }, { wch: 20 }];

  if (originalData.cases.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 50 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 25 }, { wch: 15 }, { wch: 15 }];
  }

  const fileName = `${originalData.reportTitle} ${` - PARCEIRO: ${originalData.partner}`} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report6Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Leads', reportData.leadsList.length > 0 ? (`${reportData.leadsList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando documentação', reportData.awaitingDocumentsList.length > 0 ? (`${reportData.awaitingDocumentsList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando cálculo', reportData.awaitingCalculationList.length > 0 ? (`${reportData.awaitingCalculationList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando kit', reportData.awaitingKitList.length > 0 ? (`${reportData.awaitingKitList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando distribuição', reportData.awaitingDistributionList.length > 0 ? (`${reportData.awaitingDistributionList.length.toLocaleString()} casos`) : '-'],
    ['Sem direito', reportData.negativeResultsList.length > 0 ? (`${reportData.negativeResultsList.length.toLocaleString()} casos`) : '-'],
    ['Casos frios', reportData.coldCasesList.length > 0 ? (`${reportData.coldCasesList.length.toLocaleString()} casos`) : '-'],
  ];

  const sortedDataSheet2 = [];

  originalData.leadsList.forEach((lead) => {
    sortedDataSheet2.push({
      CLIENTE: lead.client,
      ORIGEM: lead.origin,
      'PARCEIRO 1': lead.partner1,
      'PARCEIRO 2': lead.partner2,
      'PARCEIRO 3': lead.partner3,
      PRODUTO: lead.product,
      STATUS: lead.status,
    });
  });

  const sortedDataSheet3 = [];

  originalData.awaitingDocumentsList.forEach((awaitingDocuments) => {
    awaitingDocuments.docsRequestedAt = awaitingDocuments.docsRequestedAt ? parse(awaitingDocuments.docsRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet3.push({
      CLIENTE: awaitingDocuments.client,
      ORIGEM: awaitingDocuments.origin,
      'PARCEIRO 1': awaitingDocuments.partner1,
      'PARCEIRO 2': awaitingDocuments.partner2,
      'PARCEIRO 3': awaitingDocuments.partner3,
      PRODUTO: awaitingDocuments.product,
      'SOLICITAÇÃO DOCUMENTAÇÃO': awaitingDocuments.docsRequestedAt,
      STATUS: awaitingDocuments.status,
    });
  });

  const sortedDataSheet4 = [];

  originalData.awaitingCalculationList.forEach((awaitingCalculation) => {
    awaitingCalculation.docsRequestedAt = awaitingCalculation.docsRequestedAt ? parse(awaitingCalculation.docsRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet4.push({
      CLIENTE: awaitingCalculation.client,
      ORIGEM: awaitingCalculation.origin,
      'PARCEIRO 1': awaitingCalculation.partner1,
      'PARCEIRO 2': awaitingCalculation.partner2,
      'PARCEIRO 3': awaitingCalculation.partner3,
      PRODUTO: awaitingCalculation.product,
      'SOLICITAÇÃO DOCUMENTAÇÃO': awaitingCalculation.docsRequestedAt,
      STATUS: awaitingCalculation.status,
    });
  });

  const sortedDataSheet5 = [];

  originalData.awaitingKitList.forEach((awaitingKit) => {
    awaitingKit.evaluatedAt = awaitingKit.evaluatedAt ? parse(awaitingKit.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    awaitingKit.evaluationValue = `R$ ${Number(awaitingKit.evaluationValue) > 0
      ? (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    awaitingKit.kitRequestedAt = awaitingKit.kitRequestedAt ? parse(awaitingKit.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet5.push({
      CLIENTE: awaitingKit.client,
      ORIGEM: awaitingKit.origin,
      'PARCEIRO 1': awaitingKit.partner1,
      'PARCEIRO 2': awaitingKit.partner2,
      'PARCEIRO 3': awaitingKit.partner3,
      PRODUTO: awaitingKit.product,
      'DATA AVALIAÇÃO': awaitingKit.evaluatedAt,
      'VALOR DO CÁLCULO': awaitingKit.evaluationValue,
      'SOLICITAÇÃO KIT': awaitingKit.kitRequestedAt,
      STATUS: awaitingKit.status,
    });
  });

  const sortedDataSheet6 = [];

  originalData.awaitingDistributionList.forEach((awaitingDistribution) => {
    awaitingDistribution.evaluationValue = `R$ ${Number(awaitingDistribution.evaluationValue) > 0
      ? (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    awaitingDistribution.kitReceivedAt = awaitingDistribution.kitReceivedAt ? parse(awaitingDistribution.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet6.push({
      CLIENTE: awaitingDistribution.client,
      ORIGEM: awaitingDistribution.origin,
      'PARCEIRO 1': awaitingDistribution.partner1,
      'PARCEIRO 2': awaitingDistribution.partner2,
      'PARCEIRO 3': awaitingDistribution.partner3,
      PRODUTO: awaitingDistribution.product,
      'VALOR DO CÁLCULO': awaitingDistribution.evaluationValue,
      'RECEBIMENTO KIT': awaitingDistribution.kitReceivedAt,
      STATUS: awaitingDistribution.status,
    });
  });

  const sortedDataSheet7 = [];

  originalData.negativeResultsList.forEach((negativeResult) => {
    negativeResult.evaluatedAt = negativeResult.evaluatedAt ? parse(negativeResult.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet7.push({
      CLIENTE: negativeResult.client,
      ORIGEM: negativeResult.origin,
      'PARCEIRO 1': negativeResult.partner1,
      'PARCEIRO 2': negativeResult.partner2,
      'PARCEIRO 3': negativeResult.partner3,
      PRODUTO: negativeResult.product,
      'DATA AVALIAÇÃO': negativeResult.evaluatedAt,
      STATUS: negativeResult.status,
    });
  });

  const sortedDataSheet8 = [];

  originalData.coldCasesList.forEach((coldCase) => {
    coldCase.evaluatedAt = coldCase.evaluatedAt ? parse(coldCase.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.evaluationValue = `R$ ${Number(coldCase.evaluationValue) > 0
      ? (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;

    sortedDataSheet8.push({
      CLIENTE: coldCase.client,
      ORIGEM: coldCase.origin,
      'PARCEIRO 1': coldCase.partner1,
      'PARCEIRO 2': coldCase.partner2,
      'PARCEIRO 3': coldCase.partner3,
      PRODUTO: coldCase.product,
      'DATA AVALIAÇÃO': coldCase.evaluatedAt,
      'VALOR DO CÁLCULO': coldCase.evaluationValue,
      STATUS: coldCase.status,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);
  const worksheet3 = utils.json_to_sheet(sortedDataSheet3);
  const worksheet4 = utils.json_to_sheet(sortedDataSheet4);
  const worksheet5 = utils.json_to_sheet(sortedDataSheet5);
  const worksheet6 = utils.json_to_sheet(sortedDataSheet6);
  const worksheet7 = utils.json_to_sheet(sortedDataSheet7);
  const worksheet8 = utils.json_to_sheet(sortedDataSheet8);

  worksheet3['!opts'] = { cellDates: true };
  worksheet4['!opts'] = { cellDates: true };
  worksheet5['!opts'] = { cellDates: true };
  worksheet6['!opts'] = { cellDates: true };
  worksheet7['!opts'] = { cellDates: true };
  worksheet8['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.leadsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'LEADS');
  }
  if (originalData.awaitingDocumentsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet3, 'AGUARDANDO DOCUMENTAÇÃO');
  }
  if (originalData.awaitingCalculationList.length > 0) {
    utils.book_append_sheet(workbook, worksheet4, 'AGUARDANDO CÁLCULO');
  }
  if (originalData.awaitingKitList.length > 0) {
    utils.book_append_sheet(workbook, worksheet5, 'AGUARDANDO KIT');
  }
  if (originalData.awaitingDistributionList.length > 0) {
    utils.book_append_sheet(workbook, worksheet6, 'AGUARDANDO DISTRIBUIÇÃO');
  }
  if (originalData.negativeResultsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet7, 'SEM DIREITO - COMUNICAR CLIENTE');
  }
  if (originalData.coldCasesList.length > 0) {
    utils.book_append_sheet(workbook, worksheet8, 'CASOS FRIOS');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 25 }, { wch: 20 }];

  if (originalData.leadsList.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 35 }];
  }

  if (originalData.awaitingDocumentsList.length > 0) {
    worksheet3['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 30 }, { wch: 35 }];
  }

  if (originalData.awaitingCalculationList.length > 0) {
    worksheet4['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 30 }, { wch: 35 }];
  }

  if (originalData.awaitingKitList.length > 0) {
    worksheet5['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.awaitingDistributionList.length > 0) {
    worksheet6['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.negativeResultsList.length > 0) {
    worksheet7['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.coldCasesList.length > 0) {
    worksheet8['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 20 }, { wch: 35 }];
  }

  const fileName = `${originalData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report7Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Clientes', Number(originalData.clientsCount) > 0 ? Number(originalData.clientsCount).toLocaleString() : '-'],
    ['Casos/avaliações', Number(originalData.evaluationsCount) > 0 ? Number(originalData.evaluationsCount).toLocaleString() : '-'],
  ];

  const sortedDataSheet2 = [];

  originalData.clients.forEach((client) => {
    client.caseNumber = client.caseNumber ? client.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';

    sortedDataSheet2.push({
      CLIENTE: client.client,
      CPF: client.taxId ? formatTaxId(client.taxId, 'cpf') : '-',
      ORIGEM: client.origin,
      'PARCEIRO 1': client.partners[0] ? client.partners[0] : '-',
      'PARCEIRO 2': client.partners[1] ? client.partners[1] : '-',
      'PARCEIRO 3': client.partners[2] ? client.partners[2] : '-',
      PRODUTO: client.product,
      'Nº PROCESSO': client.caseNumber,
      'TELEFONE 1': client.phones[0] ? client.phones[0] : '-',
      'TELEFONE 2': client.phones[1] ? client.phones[1] : '-',
      'TELEFONE 3': client.phones[2] ? client.phones[2] : '-',
      'TELEFONE 4': client.phones[3] ? client.phones[3] : '-',
      'TELEFONE 5': client.phones[4] ? client.phones[4] : '-',
      ENDEREÇO: client.address,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.clients.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'CLIENTES ATIVOS');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 20 }, { wch: 20 }];

  if (originalData.clients.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 100 }];
  }

  const fileName = `${originalData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report8Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Clientes', Number(originalData.clientsCount) > 0 ? Number(originalData.clientsCount).toLocaleString() : '-'],
    ['Casos/avaliações', Number(originalData.evaluationsCount) > 0 ? Number(originalData.evaluationsCount).toLocaleString() : '-'],
  ];

  const sortedDataSheet2 = [];

  originalData.clients.forEach((client) => {
    client.caseNumber = client.caseNumber ? client.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-';

    sortedDataSheet2.push({
      CLIENTE: client.name,
      CPF: client.taxId ? formatTaxId(client.taxId, 'cpf') : '-',
      ORIGEM: client.origin,
      'PARCEIRO 1': client.partners[0] ? client.partners[0] : '-',
      'PARCEIRO 2': client.partners[1] ? client.partners[1] : '-',
      'PARCEIRO 3': client.partners[2] ? client.partners[2] : '-',
      PRODUTO: client.product,
      'Nº PROCESSO': client.caseNumber,
      STATUS: client.status,
      'CASO FRIO': client.coldCase ? 'Sim' : '-',
      'TELEFONE 1': client.phones[0] ? client.phones[0] : '-',
      'TELEFONE 2': client.phones[1] ? client.phones[1] : '-',
      'TELEFONE 3': client.phones[2] ? client.phones[2] : '-',
      'TELEFONE 4': client.phones[3] ? client.phones[3] : '-',
      'TELEFONE 5': client.phones[4] ? client.phones[4] : '-',
      ENDEREÇO: client.address,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.clients.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'CLIENTES (GERAL)');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 20 }, { wch: 20 }];

  if (originalData.clients.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 20 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 100 }];
  }

  const fileName = `${originalData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report9Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Leads', reportData.leadsList.length > 0 ? (`${reportData.leadsList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando documentação', reportData.awaitingDocumentsList.length > 0 ? (`${reportData.awaitingDocumentsList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando cálculo', reportData.awaitingCalculationList.length > 0 ? (`${reportData.awaitingCalculationList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando kit', reportData.awaitingKitList.length > 0 ? (`${reportData.awaitingKitList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando distribuição', reportData.awaitingDistributionList.length > 0 ? (`${reportData.awaitingDistributionList.length.toLocaleString()} casos`) : '-'],
    ['Distribuídos', reportData.distributedList.length > 0 ? (`${reportData.distributedList.length.toLocaleString()} casos`) : '-'],
    ['Sem direito', reportData.negativeResultsList.length > 0 ? (`${reportData.negativeResultsList.length.toLocaleString()} casos`) : '-'],
    ['Baixados', reportData.closedList.length > 0 ? (`${reportData.closedList.length.toLocaleString()} casos`) : '-'], // 9
    ['Casos frios', reportData.coldCasesList.length > 0 ? (`${reportData.coldCasesList.length.toLocaleString()} casos`) : '-'],
  ];

  const sortedDataSheet2 = [];

  originalData.leadsList.forEach((lead) => {
    sortedDataSheet2.push({
      CLIENTE: lead.client,
      ORIGEM: lead.origin,
      'PARCEIRO 1': lead.partner1,
      'PARCEIRO 2': lead.partner2,
      'PARCEIRO 3': lead.partner3,
      PRODUTO: lead.product,
      STATUS: lead.status,
    });
  });

  const sortedDataSheet3 = [];

  originalData.awaitingDocumentsList.forEach((awaitingDocuments) => {
    awaitingDocuments.docsRequestedAt = awaitingDocuments.docsRequestedAt ? parse(awaitingDocuments.docsRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet3.push({
      CLIENTE: awaitingDocuments.client,
      ORIGEM: awaitingDocuments.origin,
      'PARCEIRO 1': awaitingDocuments.partner1,
      'PARCEIRO 2': awaitingDocuments.partner2,
      'PARCEIRO 3': awaitingDocuments.partner3,
      PRODUTO: awaitingDocuments.product,
      'SOLICITAÇÃO DOCUMENTAÇÃO': awaitingDocuments.docsRequestedAt,
      STATUS: awaitingDocuments.status,
    });
  });

  const sortedDataSheet4 = [];

  originalData.awaitingCalculationList.forEach((awaitingCalculation) => {
    awaitingCalculation.docsRequestedAt = awaitingCalculation.docsRequestedAt ? parse(awaitingCalculation.docsRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet4.push({
      CLIENTE: awaitingCalculation.client,
      ORIGEM: awaitingCalculation.origin,
      'PARCEIRO 1': awaitingCalculation.partner1,
      'PARCEIRO 2': awaitingCalculation.partner2,
      'PARCEIRO 3': awaitingCalculation.partner3,
      PRODUTO: awaitingCalculation.product,
      'SOLICITAÇÃO DOCUMENTAÇÃO': awaitingCalculation.docsRequestedAt,
      STATUS: awaitingCalculation.status,
    });
  });

  const sortedDataSheet5 = [];

  originalData.awaitingKitList.forEach((awaitingKit) => {
    awaitingKit.evaluatedAt = awaitingKit.evaluatedAt ? parse(awaitingKit.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    awaitingKit.evaluationValue = `R$ ${Number(awaitingKit.evaluationValue) > 0
      ? (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    awaitingKit.kitRequestedAt = awaitingKit.kitRequestedAt ? parse(awaitingKit.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet5.push({
      CLIENTE: awaitingKit.client,
      ORIGEM: awaitingKit.origin,
      'PARCEIRO 1': awaitingKit.partner1,
      'PARCEIRO 2': awaitingKit.partner2,
      'PARCEIRO 3': awaitingKit.partner3,
      PRODUTO: awaitingKit.product,
      'DATA AVALIAÇÃO': awaitingKit.evaluatedAt,
      'VALOR DO CÁLCULO': awaitingKit.evaluationValue,
      'SOLICITAÇÃO KIT': awaitingKit.kitRequestedAt,
      STATUS: awaitingKit.status,
    });
  });

  const sortedDataSheet6 = [];

  originalData.awaitingDistributionList.forEach((awaitingDistribution) => {
    awaitingDistribution.evaluationValue = `R$ ${Number(awaitingDistribution.evaluationValue) > 0
      ? (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    awaitingDistribution.kitReceivedAt = awaitingDistribution.kitReceivedAt ? parse(awaitingDistribution.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet6.push({
      CLIENTE: awaitingDistribution.client,
      ORIGEM: awaitingDistribution.origin,
      'PARCEIRO 1': awaitingDistribution.partner1,
      'PARCEIRO 2': awaitingDistribution.partner2,
      'PARCEIRO 3': awaitingDistribution.partner3,
      PRODUTO: awaitingDistribution.product,
      'VALOR DO CÁLCULO': awaitingDistribution.evaluationValue,
      'RECEBIMENTO KIT': awaitingDistribution.kitReceivedAt,
      STATUS: awaitingDistribution.status,
    });
  });

  const sortedDataSheet7 = [];

  originalData.distributedList.forEach((distributed) => {
    distributed.evaluationValue = `R$ ${Number(distributed.evaluationValue) > 0
      ? (Number(Number(distributed.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(distributed.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(distributed.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(distributed.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    distributed.distributedAt = distributed.distributedAt ? parse(distributed.distributedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet7.push({
      CLIENTE: distributed.client,
      ORIGEM: distributed.origin,
      'PARCEIRO 1': distributed.partner1,
      'PARCEIRO 2': distributed.partner2,
      'PARCEIRO 3': distributed.partner3,
      PRODUTO: distributed.product,
      'VALOR DO CÁLCULO': distributed.evaluationValue,
      DISTRIBUIÇÃO: distributed.distributedAt,
      STATUS: distributed.status,
    });
  });

  const sortedDataSheet8 = [];

  originalData.negativeResultsList.forEach((negativeResult) => {
    negativeResult.evaluatedAt = negativeResult.evaluatedAt ? parse(negativeResult.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet8.push({
      CLIENTE: negativeResult.client,
      ORIGEM: negativeResult.origin,
      'PARCEIRO 1': negativeResult.partner1,
      'PARCEIRO 2': negativeResult.partner2,
      'PARCEIRO 3': negativeResult.partner3,
      PRODUTO: negativeResult.product,
      'DATA AVALIAÇÃO': negativeResult.evaluatedAt,
      STATUS: negativeResult.status,
    });
  });

  const sortedDataSheet9 = [];

  originalData.closedList.forEach((closed) => {
    closed.evaluatedAt = closed.evaluatedAt ? parse(closed.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.evaluationValue = `R$ ${Number(closed.evaluationValue) > 0
      ? (Number(Number(closed.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(closed.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(closed.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(closed.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    closed.kitRequestedAt = closed.kitRequestedAt ? parse(closed.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.kitReceivedAt = closed.kitReceivedAt ? parse(closed.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.distributedAt = closed.distributedAt ? parse(closed.distributedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.clientInformedAt = closed.clientInformedAt ? parse(closed.clientInformedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet9.push({
      CLIENTE: closed.client,
      ORIGEM: closed.origin,
      'PARCEIRO 1': closed.partner1,
      'PARCEIRO 2': closed.partner2,
      'PARCEIRO 3': closed.partner3,
      PRODUTO: closed.product,
      'DATA AVALIAÇÃO': closed.evaluatedAt,
      'VALOR DO CÁLCULO': closed.evaluationValue,
      'SOLICITAÇÃO KIT': closed.kitRequestedAt,
      'RECEBIMENTO KIT': closed.kitReceivedAt,
      DISTRIBUIÇÃO: closed.distributedAt,
      'INFORMAÇÃO CLIENTE': closed.clientInformedAt,
      STATUS: closed.status,
    });
  });

  const sortedDataSheet10 = [];

  originalData.coldCasesList.forEach((coldCase) => {
    coldCase.evaluatedAt = coldCase.evaluatedAt ? parse(coldCase.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.evaluationValue = `R$ ${Number(coldCase.evaluationValue) > 0
      ? (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString()
        + (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
          ? ',00'
          : ((Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
            ? '0'
            : '')))
      : '-'}`;
    coldCase.kitRequestedAt = coldCase.kitRequestedAt ? parse(coldCase.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.kitReceivedAt = coldCase.kitReceivedAt ? parse(coldCase.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.distributedAt = coldCase.distributedAt ? parse(coldCase.distributedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.clientInformedAt = coldCase.clientInformedAt ? parse(coldCase.clientInformedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet10.push({
      CLIENTE: coldCase.client,
      ORIGEM: coldCase.origin,
      'PARCEIRO 1': coldCase.partner1,
      'PARCEIRO 2': coldCase.partner2,
      'PARCEIRO 3': coldCase.partner3,
      PRODUTO: coldCase.product,
      'DATA AVALIAÇÃO': coldCase.evaluatedAt,
      'VALOR DO CÁLCULO': coldCase.evaluationValue,
      'SOLICITAÇÃO KIT': coldCase.kitRequestedAt,
      'RECEBIMENTO KIT': coldCase.kitReceivedAt,
      DISTRIBUIÇÃO: coldCase.distributedAt,
      'INFORMAÇÃO CLIENTE': coldCase.clientInformedAt,
      STATUS: coldCase.status,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);
  const worksheet3 = utils.json_to_sheet(sortedDataSheet3);
  const worksheet4 = utils.json_to_sheet(sortedDataSheet4);
  const worksheet5 = utils.json_to_sheet(sortedDataSheet5);
  const worksheet6 = utils.json_to_sheet(sortedDataSheet6);
  const worksheet7 = utils.json_to_sheet(sortedDataSheet7);
  const worksheet8 = utils.json_to_sheet(sortedDataSheet8);
  const worksheet9 = utils.json_to_sheet(sortedDataSheet9);
  const worksheet10 = utils.json_to_sheet(sortedDataSheet10);

  worksheet3['!opts'] = { cellDates: true };
  worksheet4['!opts'] = { cellDates: true };
  worksheet5['!opts'] = { cellDates: true };
  worksheet6['!opts'] = { cellDates: true };
  worksheet7['!opts'] = { cellDates: true };
  worksheet8['!opts'] = { cellDates: true };
  worksheet9['!opts'] = { cellDates: true };
  worksheet10['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.leadsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'LEADS');
  }
  if (originalData.awaitingDocumentsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet3, 'AGUARDANDO DOCUMENTAÇÃO');
  }
  if (originalData.awaitingCalculationList.length > 0) {
    utils.book_append_sheet(workbook, worksheet4, 'AGUARDANDO CÁLCULO');
  }
  if (originalData.awaitingKitList.length > 0) {
    utils.book_append_sheet(workbook, worksheet5, 'AGUARDANDO KIT');
  }
  if (originalData.awaitingDistributionList.length > 0) {
    utils.book_append_sheet(workbook, worksheet6, 'AGUARDANDO DISTRIBUIÇÃO');
  }
  if (originalData.distributedList.length > 0) {
    utils.book_append_sheet(workbook, worksheet7, 'DISTRIBUÍDOS');
  }
  if (originalData.negativeResultsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet8, 'SEM DIREITO - COMUNICAR CLIENTE');
  }
  if (originalData.closedList.length > 0) {
    utils.book_append_sheet(workbook, worksheet9, 'BAIXADOS');
  }
  if (originalData.coldCasesList.length > 0) {
    utils.book_append_sheet(workbook, worksheet10, 'CASOS FRIOS');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 25 }, { wch: 20 }];

  if (originalData.leadsList.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 35 }];
  }

  if (originalData.awaitingDocumentsList.length > 0) {
    worksheet3['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 30 }, { wch: 35 }];
  }

  if (originalData.awaitingCalculationList.length > 0) {
    worksheet4['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 30 }, { wch: 35 }];
  }

  if (originalData.awaitingKitList.length > 0) {
    worksheet5['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.awaitingDistributionList.length > 0) {
    worksheet6['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.distributedList.length > 0) {
    worksheet7['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 20 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.negativeResultsList.length > 0) {
    worksheet8['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.closedList.length > 0) {
    worksheet9['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 35 }];
  }

  if (originalData.coldCasesList.length > 0) {
    worksheet10['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 35 }];
  }

  const fileName = `${originalData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

function report10Excel(reportData) {
  const originalData = JSON.parse(JSON.stringify(reportData));

  const reportAbstract = [
    ['Período', (originalData.startDate && originalData.endDate) ? `${format(parse(originalData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(originalData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'],
    ['Produto', originalData.product ? originalData.product : 'Geral'],
    ['Origem', originalData.origin ? originalData.origin : 'Geral'],
    ['Parceiro', originalData.partner ? originalData.partner : 'Geral'],
    ['Leads', reportData.leadsList.length > 0 ? (`${reportData.leadsList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando documentação', reportData.awaitingDocumentsList.length > 0 ? (`${reportData.awaitingDocumentsList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando cálculo', reportData.awaitingCalculationList.length > 0 ? (`${reportData.awaitingCalculationList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando kit', reportData.awaitingKitList.length > 0 ? (`${reportData.awaitingKitList.length.toLocaleString()} casos`) : '-'],
    ['Aguardando distribuição', reportData.awaitingDistributionList.length > 0 ? (`${reportData.awaitingDistributionList.length.toLocaleString()} casos`) : '-'],
    ['Distribuídos', reportData.distributedList.length > 0 ? (`${reportData.distributedList.length.toLocaleString()} casos`) : '-'],
    ['Sem direito', reportData.negativeResultsList.length > 0 ? (`${reportData.negativeResultsList.length.toLocaleString()} casos`) : '-'],
    ['Baixados', reportData.closedList.length > 0 ? (`${reportData.closedList.length.toLocaleString()} casos`) : '-'], // 9
    ['Casos frios', reportData.coldCasesList.length > 0 ? (`${reportData.coldCasesList.length.toLocaleString()} casos`) : '-'],
  ];

  const sortedDataSheet2 = [];

  originalData.leadsList.forEach((lead) => {
    sortedDataSheet2.push({
      CLIENTE: lead.client,
      ORIGEM: lead.origin,
      'PARCEIRO 1': lead.partner1,
      'PARCEIRO 2': lead.partner2,
      'PARCEIRO 3': lead.partner3,
      PRODUTO: lead.product,
      STATUS: lead.status,
    });
  });

  const sortedDataSheet3 = [];

  originalData.awaitingDocumentsList.forEach((awaitingDocuments) => {
    awaitingDocuments.docsRequestedAt = awaitingDocuments.docsRequestedAt ? parse(awaitingDocuments.docsRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet3.push({
      CLIENTE: awaitingDocuments.client,
      ORIGEM: awaitingDocuments.origin,
      'PARCEIRO 1': awaitingDocuments.partner1,
      'PARCEIRO 2': awaitingDocuments.partner2,
      'PARCEIRO 3': awaitingDocuments.partner3,
      PRODUTO: awaitingDocuments.product,
      'SOLICITAÇÃO DOCUMENTAÇÃO': awaitingDocuments.docsRequestedAt,
      STATUS: awaitingDocuments.status,
    });
  });

  const sortedDataSheet4 = [];

  originalData.awaitingCalculationList.forEach((awaitingCalculation) => {
    awaitingCalculation.docsRequestedAt = awaitingCalculation.docsRequestedAt ? parse(awaitingCalculation.docsRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet4.push({
      CLIENTE: awaitingCalculation.client,
      ORIGEM: awaitingCalculation.origin,
      'PARCEIRO 1': awaitingCalculation.partner1,
      'PARCEIRO 2': awaitingCalculation.partner2,
      'PARCEIRO 3': awaitingCalculation.partner3,
      PRODUTO: awaitingCalculation.product,
      'SOLICITAÇÃO DOCUMENTAÇÃO': awaitingCalculation.docsRequestedAt,
      STATUS: awaitingCalculation.status,
    });
  });

  const sortedDataSheet5 = [];

  originalData.awaitingKitList.forEach((awaitingKit) => {
    awaitingKit.evaluatedAt = awaitingKit.evaluatedAt ? parse(awaitingKit.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    awaitingKit.kitRequestedAt = awaitingKit.kitRequestedAt ? parse(awaitingKit.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet5.push({
      CLIENTE: awaitingKit.client,
      ORIGEM: awaitingKit.origin,
      'PARCEIRO 1': awaitingKit.partner1,
      'PARCEIRO 2': awaitingKit.partner2,
      'PARCEIRO 3': awaitingKit.partner3,
      PRODUTO: awaitingKit.product,
      'DATA AVALIAÇÃO': awaitingKit.evaluatedAt,
      'SOLICITAÇÃO KIT': awaitingKit.kitRequestedAt,
      STATUS: awaitingKit.status,
    });
  });

  const sortedDataSheet6 = [];

  originalData.awaitingDistributionList.forEach((awaitingDistribution) => {
    awaitingDistribution.kitReceivedAt = awaitingDistribution.kitReceivedAt ? parse(awaitingDistribution.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet6.push({
      CLIENTE: awaitingDistribution.client,
      ORIGEM: awaitingDistribution.origin,
      'PARCEIRO 1': awaitingDistribution.partner1,
      'PARCEIRO 2': awaitingDistribution.partner2,
      'PARCEIRO 3': awaitingDistribution.partner3,
      PRODUTO: awaitingDistribution.product,
      'RECEBIMENTO KIT': awaitingDistribution.kitReceivedAt,
      STATUS: awaitingDistribution.status,
    });
  });

  const sortedDataSheet7 = [];

  originalData.distributedList.forEach((distributed) => {
    distributed.distributedAt = distributed.distributedAt ? parse(distributed.distributedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet7.push({
      CLIENTE: distributed.client,
      ORIGEM: distributed.origin,
      'PARCEIRO 1': distributed.partner1,
      'PARCEIRO 2': distributed.partner2,
      'PARCEIRO 3': distributed.partner3,
      PRODUTO: distributed.product,
      DISTRIBUIÇÃO: distributed.distributedAt,
      STATUS: distributed.status,
    });
  });

  const sortedDataSheet8 = [];

  originalData.negativeResultsList.forEach((negativeResult) => {
    negativeResult.evaluatedAt = negativeResult.evaluatedAt ? parse(negativeResult.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet8.push({
      CLIENTE: negativeResult.client,
      ORIGEM: negativeResult.origin,
      'PARCEIRO 1': negativeResult.partner1,
      'PARCEIRO 2': negativeResult.partner2,
      'PARCEIRO 3': negativeResult.partner3,
      PRODUTO: negativeResult.product,
      'DATA AVALIAÇÃO': negativeResult.evaluatedAt,
      STATUS: negativeResult.status,
    });
  });

  const sortedDataSheet9 = [];

  originalData.closedList.forEach((closed) => {
    closed.evaluatedAt = closed.evaluatedAt ? parse(closed.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.kitRequestedAt = closed.kitRequestedAt ? parse(closed.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.kitReceivedAt = closed.kitReceivedAt ? parse(closed.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.distributedAt = closed.distributedAt ? parse(closed.distributedAt, 'yyyy-MM-dd', new Date()) : '-';
    closed.clientInformedAt = closed.clientInformedAt ? parse(closed.clientInformedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet9.push({
      CLIENTE: closed.client,
      ORIGEM: closed.origin,
      'PARCEIRO 1': closed.partner1,
      'PARCEIRO 2': closed.partner2,
      'PARCEIRO 3': closed.partner3,
      PRODUTO: closed.product,
      'DATA AVALIAÇÃO': closed.evaluatedAt,
      'SOLICITAÇÃO KIT': closed.kitRequestedAt,
      'RECEBIMENTO KIT': closed.kitReceivedAt,
      DISTRIBUIÇÃO: closed.distributedAt,
      'INFORMAÇÃO CLIENTE': closed.clientInformedAt,
      STATUS: closed.status,
    });
  });

  const sortedDataSheet10 = [];

  originalData.coldCasesList.forEach((coldCase) => {
    coldCase.evaluatedAt = coldCase.evaluatedAt ? parse(coldCase.evaluatedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.kitRequestedAt = coldCase.kitRequestedAt ? parse(coldCase.kitRequestedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.kitReceivedAt = coldCase.kitReceivedAt ? parse(coldCase.kitReceivedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.distributedAt = coldCase.distributedAt ? parse(coldCase.distributedAt, 'yyyy-MM-dd', new Date()) : '-';
    coldCase.clientInformedAt = coldCase.clientInformedAt ? parse(coldCase.clientInformedAt, 'yyyy-MM-dd', new Date()) : '-';

    sortedDataSheet10.push({
      CLIENTE: coldCase.client,
      ORIGEM: coldCase.origin,
      'PARCEIRO 1': coldCase.partner1,
      'PARCEIRO 2': coldCase.partner2,
      'PARCEIRO 3': coldCase.partner3,
      PRODUTO: coldCase.product,
      'DATA AVALIAÇÃO': coldCase.evaluatedAt,
      'SOLICITAÇÃO KIT': coldCase.kitRequestedAt,
      'RECEBIMENTO KIT': coldCase.kitReceivedAt,
      DISTRIBUIÇÃO: coldCase.distributedAt,
      'INFORMAÇÃO CLIENTE': coldCase.clientInformedAt,
      STATUS: coldCase.status,
    });
  });

  const worksheet1 = utils.json_to_sheet(reportAbstract);
  const worksheet2 = utils.json_to_sheet(sortedDataSheet2);
  const worksheet3 = utils.json_to_sheet(sortedDataSheet3);
  const worksheet4 = utils.json_to_sheet(sortedDataSheet4);
  const worksheet5 = utils.json_to_sheet(sortedDataSheet5);
  const worksheet6 = utils.json_to_sheet(sortedDataSheet6);
  const worksheet7 = utils.json_to_sheet(sortedDataSheet7);
  const worksheet8 = utils.json_to_sheet(sortedDataSheet8);
  const worksheet9 = utils.json_to_sheet(sortedDataSheet9);
  const worksheet10 = utils.json_to_sheet(sortedDataSheet10);

  worksheet3['!opts'] = { cellDates: true };
  worksheet4['!opts'] = { cellDates: true };
  worksheet5['!opts'] = { cellDates: true };
  worksheet6['!opts'] = { cellDates: true };
  worksheet7['!opts'] = { cellDates: true };
  worksheet8['!opts'] = { cellDates: true };
  worksheet9['!opts'] = { cellDates: true };
  worksheet10['!opts'] = { cellDates: true };

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet1, 'RESUMO');
  if (originalData.leadsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet2, 'LEADS');
  }
  if (originalData.awaitingDocumentsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet3, 'AGUARDANDO DOCUMENTAÇÃO');
  }
  if (originalData.awaitingCalculationList.length > 0) {
    utils.book_append_sheet(workbook, worksheet4, 'AGUARDANDO CÁLCULO');
  }
  if (originalData.awaitingKitList.length > 0) {
    utils.book_append_sheet(workbook, worksheet5, 'AGUARDANDO KIT');
  }
  if (originalData.awaitingDistributionList.length > 0) {
    utils.book_append_sheet(workbook, worksheet6, 'AGUARDANDO DISTRIBUIÇÃO');
  }
  if (originalData.distributedList.length > 0) {
    utils.book_append_sheet(workbook, worksheet7, 'DISTRIBUÍDOS');
  }
  if (originalData.negativeResultsList.length > 0) {
    utils.book_append_sheet(workbook, worksheet8, 'SEM DIREITO - COMUNICAR CLIENTE');
  }
  if (originalData.closedList.length > 0) {
    utils.book_append_sheet(workbook, worksheet9, 'BAIXADOS');
  }
  if (originalData.coldCasesList.length > 0) {
    utils.book_append_sheet(workbook, worksheet10, 'CASOS FRIOS');
  }

  utils.sheet_add_aoa(worksheet1, [['RESUMO', '']], { origin: 'A1' });
  worksheet1['!cols'] = [{ wch: 25 }, { wch: 20 }];

  if (originalData.leadsList.length > 0) {
    worksheet2['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 35 }];
  }

  if (originalData.awaitingDocumentsList.length > 0) {
    worksheet3['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 30 }, { wch: 35 }];
  }

  if (originalData.awaitingCalculationList.length > 0) {
    worksheet4['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 30 }, { wch: 35 }];
  }

  if (originalData.awaitingKitList.length > 0) {
    worksheet5['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.awaitingDistributionList.length > 0) {
    worksheet6['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.distributedList.length > 0) {
    worksheet7['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.negativeResultsList.length > 0) {
    worksheet8['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 35 }];
  }

  if (originalData.closedList.length > 0) {
    worksheet9['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 35 }];
  }

  if (originalData.coldCasesList.length > 0) {
    worksheet10['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 50 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 35 }];
  }

  const fileName = `${originalData.reportTitle} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.xlsx`;

  writeFile(workbook, fileName, { compression: true });
}

export {
  report2Excel,
  report3Excel,
  report4Excel,
  report5Excel,
  report6Excel,
  report7Excel,
  report8Excel,
  report9Excel,
  report10Excel,
};
