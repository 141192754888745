import {
  useContext,
  useEffect,
  useState,
} from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { AuthContext } from '../../../../contexts/auth';

import { doubleSpaceRegex } from '../../../../services/regex';

import {
  validateEmail,
  validateTaxId,
  validatePhone,
} from '../../../../services/validations';

import {
  formatPhone,
  formatTaxId,
} from '../../../../services/format';

import whatsappIcon from '../../../../images/whatsapp-icon.png';

import './style.css';

function PartnerFormRow1({ selectedPartner, partnerData, setPartnerData }) {
  const { activeUser } = useContext(AuthContext);

  const [city, setCity] = useState();
  const [documentType, setDocumentType] = useState();
  const [email, setEmail] = useState();
  const [invalidTaxId, setInvalidTaxId] = useState(false);
  const [name, setName] = useState();
  const [partnerCode, setPartnerCode] = useState();
  const [phone1, setPhone1] = useState();
  const [phone1Details, setPhone1Details] = useState();
  const [phone2, setPhone2] = useState();
  const [phone2Details, setPhone2Details] = useState();
  const [state, setState] = useState();
  const [taxId, setTaxId] = useState();

  useEffect(() => {
    if (selectedPartner !== undefined) {
      setCity(selectedPartner.city ? selectedPartner.city : '');
      setDocumentType(selectedPartner.taxId ? (selectedPartner.taxId.length === 11 ? 'CPF' : 'CNPJ') : 'CNPJ');
      setEmail(selectedPartner.email ? selectedPartner.email : '');
      setName(selectedPartner.name ? selectedPartner.name : '');
      setPartnerCode(selectedPartner.partnerCode ? selectedPartner.partnerCode : '');
      setPhone1(selectedPartner.phone1 ? formatPhone(selectedPartner.phone1) : '');
      setPhone1Details(selectedPartner.phone1Details ? selectedPartner.phone1Details : '');
      setPhone2(selectedPartner.phone2 ? formatPhone(selectedPartner.phone2) : '');
      setPhone2Details(selectedPartner.phone2Details ? selectedPartner.phone2Details : '');
      setState(selectedPartner.state ? selectedPartner.state : '');
      setTaxId(selectedPartner.taxId ? (selectedPartner.taxId.length === 11 ? formatTaxId(selectedPartner.taxId, 'cpf') : formatTaxId(selectedPartner.taxId, 'cnpj')) : '');
    }
  }, [selectedPartner]);

  useEffect(() => {
    let currentData = {};
    if (partnerData !== undefined) {
      currentData = partnerData;
    }

    if (name) {
      if (name !== selectedPartner.name) {
        currentData.name = name.trim().toUpperCase();
      }
    }

    if (email) {
      if (validateEmail(email)) {
        if (email.toLowerCase() !== selectedPartner.email) {
          currentData.email = email.toLowerCase();
        }
      }
    } else {
      currentData.email = '';
    }

    if (city) {
      if (city !== selectedPartner.city) {
        currentData.city = city;
      }
    } else {
      currentData.city = null;
    }

    if (state) {
      if (state !== selectedPartner.state) {
        currentData.state = state;
      }
    } else {
      currentData.state = null;
    }

    if (partnerCode) {
      currentData.partnerCode = partnerCode.trim();
    } else {
      currentData.partnerCode = null;
    }

    if (taxId) {
      if (validateTaxId(taxId, 'cpf') || validateTaxId(taxId, 'cnpj')) {
        if (taxId.replace(/\D/g, '') !== selectedPartner.taxId) {
          currentData.taxId = taxId.replace(/\D/g, '');
        }
        setInvalidTaxId(false);
      } else {
        setInvalidTaxId(true);
      }
    } else {
      currentData.taxId = null;
    }

    if (phone1) {
      if (validatePhone(phone1)) {
        if (phone1.replace(/\D/g, '') !== selectedPartner.phone1) {
          currentData.phone1 = phone1.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone1 = null;
    }

    if (phone1Details) {
      if (phone1Details !== selectedPartner.phone1Details) {
        currentData.phone1Details = phone1Details;
      }
    } else {
      currentData.phone1Details = null;
    }

    if (phone2) {
      if (validatePhone(phone2)) {
        if (phone2.replace(/\D/g, '') !== selectedPartner.phone2) {
          currentData.phone2 = phone2.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone2 = null;
    }

    if (phone2Details) {
      if (phone2Details !== selectedPartner.phone2Details) {
        currentData.phone2Details = phone2Details;
      }
    } else {
      currentData.phone2Details = null;
    }

    setPartnerData(currentData);
  }, [name, email, city, state, partnerCode, taxId, phone1, phone1Details, phone2, phone2Details, partnerData, setPartnerData, selectedPartner.city,
    selectedPartner.state, selectedPartner.email, selectedPartner.name, selectedPartner.partnerCode, selectedPartner.taxId, selectedPartner.phone1,
    selectedPartner.phone1Details, selectedPartner.phone2, selectedPartner.phone2Details]);

  function formatTaxIdInput(taxId) {
    let formattedTaxId;

    if (documentType === 'CPF') {
      formattedTaxId = taxId
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    } else {
      formattedTaxId = taxId
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }

    setTaxId(formattedTaxId);
  }

  function formatPhoneNumberInput(phoneNumber, id) {
    const formattedPhoneNumber = phoneNumber
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1)$2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
    switch (id) {
      case '1':
        setPhone1(formattedPhoneNumber);
        break;
      case '2':
        setPhone2(formattedPhoneNumber);
        break;
      default:
        break;
    }
  }

  return (
    <div className='row1'>
      <div className='partner-row1'>
        <div className='partner-row1-col1'>
          <div className='partner-name'>
            <label className='partner-label'>
              Nome
            </label>
            <input
              autoFocus
              className='input'
              variant='outlined'
              size='small'
              type='text'
              disabled={activeUser.category !== 'Sócio'}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='partner-email'>
            <label className='partner-label'>
              E-mail
            </label>
            <input
              className='input'
              variant='outlined'
              size='small'
              type='email'
              disabled={activeUser.category !== 'Sócio'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='partner-location'>
            <div className='partner-city'>
              <label className='partner-label'>
                Cidade
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={city}
                onChange={(e) => setCity(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='partner-state'>
              <label className='partner-label'>
                Estado
              </label>
              <input
                className='input center-text'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={state}
                onChange={(e) => setState(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
          </div>
        </div>
        <div className='partner-row1-col2'>
          <div
            className='partner-row1-col2-block1'
            style={{ marginTop: '-1px' }}
          >
            <div className='partner-code'>
              <label className='partner-label'>
                Código
              </label>
              <input
                className='input center-text'
                variant='outlined'
                size='small'
                type='text'
                maxLength='10'
                disabled={activeUser.category !== 'Sócio'}
                value={partnerCode}
                onChange={(e) => setPartnerCode(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='partner-tax-id-type'>
              <label className='partner-label'>
                Tipo Documento
              </label>
              <select
                className='input'
                variant='outlined'
                size='small'
                type='text'
                style={{ textAlign: 'center' }}
                disabled={activeUser.category !== 'Sócio'}
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
              >
                <option>CNPJ</option>
                <option>CPF</option>
              </select>
            </div>
            <div className='partner-tax-id'>
              <label className='partner-label'>
                {documentType}
              </label>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <input
                  className='input'
                  variant='outlined'
                  size='small'
                  type='text'
                  maxLength={documentType === 'CNPJ' ? '18' : '14'}
                  disabled={activeUser.category !== 'Sócio'}
                  value={taxId}
                  onChange={(e) => formatTaxIdInput(e.target.value)}
                />
                {invalidTaxId && (
                  <span style={{ marginLeft: -35 }}>
                    <ErrorOutlineIcon style={{ color: 'red' }} />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className='partner-row1-col2-block2'
            style={{ marginTop: '8px' }}
          >
            <div className='partner-phone'>
              <label className='partner-label'>
                Telefone
              </label>
              <div className='partner-phone-input'>
                <input
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    width: '150px',
                  }}
                  className='input center-text'
                  placeholder='Telefone 01'
                  variant='outlined'
                  size='small'
                  type='text'
                  maxLength='14'
                  disabled={activeUser.category !== 'Sócio'}
                  value={phone1}
                  onChange={(e) => formatPhoneNumberInput(e.target.value, '1')}
                />
                {!!phone1
                  && (
                    <span className='whatsapp-button'>
                      <a
                        className='whatsapp-link'
                        href={`http://web.whatsapp.com/send?phone=${55 + phone1}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          style={{
                            width: '15px',
                            height: '15px',
                            marginBottom: '10px',
                          }}
                          src={whatsappIcon}
                          alt=''
                        />
                      </a>
                    </span>
                  )}
              </div>
            </div>
            <div className='partner-phone-details'>
              <label className='partner-label'>
                Detalhes
              </label>
              <input
                className='input left-text'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={phone1Details}
                onChange={(e) => setPhone1Details(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
          </div>
          <div
            className='partner-row1-col2-block2'
            style={{ marginTop: '11px' }}
          >
            <div className='partner-phone'>
              <div className='partner-phone-input'>
                <input
                  style={{
                    marginTop: '12px',
                    display: 'flex',
                    textAlign: 'left',
                    width: '150px',
                  }}
                  className='input center-text'
                  placeholder='Telefone 02'
                  variant='outlined'
                  size='small'
                  type='text'
                  maxLength='14'
                  disabled={activeUser.category !== 'Sócio'}
                  value={phone2}
                  onChange={(e) => formatPhoneNumberInput(e.target.value, '2')}
                />
                {!!phone2
                  && (
                    <span className='whatsapp-button'>
                      <a
                        className='whatsapp-link'
                        href={`http://web.whatsapp.com/send?phone=${55 + phone2}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          style={{
                            width: '15px',
                            height: '15px',
                          }}
                          src={whatsappIcon}
                          alt=''
                        />
                      </a>
                    </span>
                  )}
              </div>
            </div>
            <div className='partner-phone-details'>
              <input
                style={{ marginTop: '11px' }}
                className='input left-text'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={phone2Details}
                onChange={(e) => setPhone2Details(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerFormRow1;
