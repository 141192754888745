import {
  useEffect,
  useState,
} from 'react';

import {
  Checkbox,
  Popover,
} from 'antd';

import {
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';

import BlueButton from '../../Buttons/BlueButton';

import './style.css';

function CheckboxFilter({
  applyFilters, filterOptions, column, filter, active,
}) {
  const [checkedItems, setCheckedItems] = useState({});
  const [currentFilterOptions, setCurrentFilterOptions] = useState(filterOptions);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setCurrentFilterOptions(filterOptions.filter((item) => item && item.toLowerCase().includes(searchText.toLowerCase())));
  }, [searchText, filterOptions]);

  useEffect(() => {
    const recoveredFilters = JSON.parse(sessionStorage.getItem('MAADVEvaluationFilters'));
    if (recoveredFilters) {
      const checked = {};

      filterOptions.forEach((filterItem) => {
        if (recoveredFilters[column]?.includes(filterItem)) {
          checked[filterItem] = true;
        }
      });

      setCheckedItems(checked);
    }
  }, []);

  function handleUndoFilter() {
    const updatedFilter = { ...filter.current };
    delete updatedFilter[column];
    setCheckedItems({});
    filter.current = { ...updatedFilter };
    setSearchText('');
    applyFilters();
  }

  function handleChange(item, itemChecked) {
    const updatedFilter = { ...filter.current };

    if (itemChecked) {
      updatedFilter[column] = [...(updatedFilter[column] || []), item];
    } else {
      updatedFilter[column] = (updatedFilter[column] || []).filter((i) => i !== item);
      if (updatedFilter[column].length === 0) {
        delete updatedFilter[column];
      }
    }

    filter.current = { ...updatedFilter };

    const currentCheckedItems = { ...checkedItems };

    currentCheckedItems[item] = itemChecked;

    const checked = {};

    filterOptions.forEach((filterItem) => {
      if (filter.current[column]?.includes(filterItem)) {
        checked[filterItem] = true;
      }
    });

    setCheckedItems(checked);

    applyFilters();
  }

  const content = (
    <div className='content-checkbox-filter'>
      <input
        key={column}
        className='input-checkbox-filter'
        type='text'
        placeholder='Pesquisar'
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className='options-checkbox-filter'>
        {currentFilterOptions.map((item) => (
          <span
            key={item}
            style={{ marginBottom: '5px' }}
          >
            <Checkbox
              checked={checkedItems[item] || false}
              onChange={(e) => handleChange(item, e.target.checked)}
            />
            <span style={{ marginLeft: '5px' }}>{item}</span>
          </span>
        ))}
      </div>
      {active && (
        <div
          className='undo-filter-checkbox-filter'
          onClick={handleUndoFilter}
        >
          <BlueButton buttonText='Limpar filtro' />
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Popover
        placement='bottom'
        content={content}
        trigger='click'
      >
        {active ? (
          <FilterFilled style={{ cursor: 'pointer' }} />
        ) : (
          <FilterOutlined style={{ cursor: 'pointer' }} />
        )}
      </Popover>
    </div>
  );
}

export default CheckboxFilter;
