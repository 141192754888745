import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import Confirmation from '../Confirmation';
import ClientFormRow1 from './ClientFormRow1';
import ClientFormRow2 from './ClientFormRow2';
import ClientFormRow3 from './ClientFormRow3';
import GrayButton from '../../Buttons/GrayButton';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Client({
  openModal, setOpenModal, selectedClient, setSelectedClient, clientLogs, setClientLogs, setUpdateList, auxiliaryLists,
}) {
  const { activeUser, token } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [clientData, setClientData] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const title = selectedClient ? 'Editar Cliente' : 'Cadastrar Cliente';

  const handleCloseClientModal = useCallback(() => {
    setOpenModal(false);
    setClientData('');
    setSelectedClient('');
  }, [setOpenModal, setSelectedClient]);

  const handleSaveClient = useCallback(async () => {
    try {
      setAlertMessage('');
      if (clientData) {
        if (clientData !== undefined && clientData !== '') {
          if (!selectedClient && !clientData.name) {
            setAlertMessage({ title: 'Alerta!', message: 'É necessário informar o nome do cliente.' });
            setOpenAlert(true);
            return;
          }

          if (!selectedClient && !clientData.origin) {
            setAlertMessage({ title: 'Alerta!', message: 'É necessário informar a origem do cliente.' });
            setOpenAlert(true);
            return;
          }

          const reqData = {
            body: clientData,
            token,
          };

          let data;

          if (!selectedClient) {
            data = await API.fetch('POST', 'clients', reqData);
          } else {
            reqData.id = selectedClient.id;
            data = await API.fetch('PUT', 'clients', reqData);
          }

          setAlertMessage({
            title: 'Sucesso!',
            message: data,
          });

          setOpenAlert(true);

          handleCloseClientModal();

          setUpdateList(true);
        }
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Client/index.js - handleSaveClient',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, clientData, handleCloseClientModal, selectedClient, setUpdateList]);

  useEffect(() => {
    if (confirmation) {
      handleSaveClient();
    }
  }, [confirmation, handleSaveClient]);

  function handleLisPendensCheck() {
    if (!clientData.lisPendens) {
      setConfirmationMessage('Tem certeza de que deseja salvar sem indicar litispendência?');
      setOpenConfirmation(true);
    } else {
      handleSaveClient();
    }
  }

  return (
    <div className='client-form-modal'>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        style={{ zIndex: 9 }}
      >
        <div className='title-modal'>
          <div className='dialog-title'>
            {title}
            <div
              className='close-button'
              onClick={handleCloseClientModal}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <DialogContent>
          <ClientFormRow1
            selectedClient={selectedClient}
            clientData={clientData}
            setClientData={setClientData}
          />
          <ClientFormRow2
            selectedClient={selectedClient}
            clientData={clientData}
            setClientData={setClientData}
          />
          <ClientFormRow3
            selectedClient={selectedClient}
            clientData={clientData}
            setClientData={setClientData}
            clientLogs={clientLogs}
            setClientLogs={setClientLogs}
            setAlertMessage={setAlertMessage}
            setOpenAlert={setOpenAlert}
            auxiliaryLists={{
              originsList: auxiliaryLists.originsList,
              partnersList: auxiliaryLists.partnersList,
            }}
          />
        </DialogContent>
        <div className='dialog-actions'>
          <DialogActions
            sx={{ padding: '15px' }}
            style={{
              zIndex: 9999,
              display: 'flex',
              width: '100%',
            }}
          >
            <div className='action-buttons'>
              <div onClick={handleCloseClientModal}>
                <GrayButton
                  buttonText='Cancelar'
                  buttonWidth='90px'
                />
              </div>
              <div onClick={handleLisPendensCheck}>
                <BlueButton
                  className='save-button'
                  buttonText={selectedClient ? 'Salvar' : 'Criar'}
                  buttonWidth={selectedClient ? '70px' : '60px'}
                />
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}

    </div>
  );
}

export default Client;
