import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Popover,
} from 'antd';

import {
  FilterFilled,
  FilterOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';

import {
  getYear,
  isValid,
} from 'date-fns';

import './style.css';

function DateRangeFilter({
  applyFilters, column, filter, active,
}) {
  const [activityEndDate, setActivityEndDate] = useState('');
  const [activityStartDate, setActivityStartDate] = useState('');

  function handleUndoFilter() {
    const updatedFilter = { ...filter.current };
    delete updatedFilter[column];
    filter.current = { ...updatedFilter };
    setActivityStartDate('');
    setActivityEndDate('');
    applyFilters();
  }

  useEffect(() => {
    const recoveredFilters = JSON.parse(sessionStorage.getItem('MAADVEvaluationFilters'));
    if (recoveredFilters) {
      if (Object.keys(recoveredFilters[column] || {}).length > 0) {
        if (recoveredFilters[column].startDate) {
          if (getYear(new Date(recoveredFilters[column].startDate)) > 2000) {
            setActivityStartDate(recoveredFilters[column].startDate);
          }
        }

        if (recoveredFilters[column].endDate) {
          if (getYear(new Date(recoveredFilters[column].endDate)) > 2000) {
            setActivityEndDate(recoveredFilters[column].endDate);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const updatedFilter = { ...filter.current };

    if (activityStartDate === undefined || activityStartDate === '' || !isValid(new Date(activityStartDate))) {
      if (updatedFilter[column]) {
        if (updatedFilter[column].startDate) {
          delete updatedFilter[column].startDate;
          filter.current = { ...updatedFilter };
          applyFilters();
        }
      }
    } else if (getYear(new Date(activityStartDate)) > 2000) {
      if (updatedFilter[column]?.startDate !== activityStartDate) {
        if (!updatedFilter[column]) {
          updatedFilter[column] = {
            startDate: activityStartDate,
          };
        } else {
          updatedFilter[column].startDate = activityStartDate;
        }

        filter.current = { ...updatedFilter };
        applyFilters();
      }
    }
  }, [activityStartDate, filter, column, applyFilters]);

  useEffect(() => {
    const updatedFilter = { ...filter.current };
    if (activityEndDate === undefined || activityEndDate === '' || !isValid(new Date(activityEndDate))) {
      if (updatedFilter[column]) {
        if (updatedFilter[column].endDate) {
          delete updatedFilter[column].endDate;
          filter.current = { ...updatedFilter };
          applyFilters();
        }
      }
    } else if (getYear(new Date(activityEndDate)) > 2000) {
      if (updatedFilter[column]?.endDate !== activityEndDate) {
        if (!updatedFilter[column]) {
          updatedFilter[column] = {
            endDate: activityEndDate,
          };
        } else {
          updatedFilter[column].endDate = activityEndDate;
        }
        filter.current = { ...updatedFilter };
        applyFilters();
      }
    }
  }, [activityEndDate, filter, column, applyFilters]);

  const content = (
    <div className='date-picker-input-date-filter'>
      <div className='date-range-date-filter'>
        <input
          type='date'
          value={activityStartDate}
          onChange={(e) => setActivityStartDate(e.target.value)}
          className='date-input-date-filter'
        />
        <SwapRightOutlined />
        <input
          type='date'
          value={activityEndDate}
          onChange={(e) => setActivityEndDate(e.target.value)}
          className='date-input-date-filter'
        />
      </div>
      <div className='filter-buttons-date-filter'>
        {active && (
          <Button
            onClick={handleUndoFilter}
            className='undo-filter-date-filter'
          >
            Limpar Filtro
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      placement='bottom'
      content={content}
      trigger='click'
    >
      {active ? (
        <FilterFilled style={{ cursor: 'pointer' }} />
      ) : (
        <FilterOutlined style={{ cursor: 'pointer' }} />
      )}
    </Popover>
  );
}

export default DateRangeFilter;
