import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';

import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import pdfMake from 'pdfmake/build/pdfmake';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material';

import $ from 'jquery';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import Report1 from '../../components/Reports/Report1';
import Report2 from '../../components/Reports/Report2';
import Report2PDF from '../../components/Reports/Report2PDF';
import Report3 from '../../components/Reports/Report3';
import Report3PDF from '../../components/Reports/Report3PDF';
import Report4 from '../../components/Reports/Report4';
import Report4PDF from '../../components/Reports/Report4PDF';
import Report5 from '../../components/Reports/Report5';
import Report5PDF from '../../components/Reports/Report5PDF';
import Report6 from '../../components/Reports/Report6';
import Report6PDF from '../../components/Reports/Report6PDF';
import Report7 from '../../components/Reports/Report7';
import Report7PDF from '../../components/Reports/Report7PDF';
import Report8 from '../../components/Reports/Report8';
import Report8PDF from '../../components/Reports/Report8PDF';
import Report9 from '../../components/Reports/Report9';
import Report9PDF from '../../components/Reports/Report9PDF';
import Report10 from '../../components/Reports/Report10';
import Report10PDF from '../../components/Reports/Report10PDF';

import {
  report2Excel,
  report3Excel,
  report4Excel,
  report5Excel,
  report6Excel,
  report7Excel,
  report8Excel,
  report9Excel,
  report10Excel,
} from '../../services/export';

import { formatPhone } from '../../services/format';

import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';

import './style.css';

function Reports() {
  $(() => {
    const headerButton = $('#background-header');
    $(window).scroll(function () {
      if ($(this).scrollTop() > 1) {
        headerButton.addClass('background-header-fixed');
      } else {
        headerButton.removeClass('background-header-fixed');
      }
    });
  });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const navigate = useNavigate();

  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  let reportOptions = [
    'Resultados Detalhados - Quantitativo',
    'Controle de Parceiros - Quantitativo',
    'Casos em Avaliação',
    'Clientes Ativos',
    'Clientes (Geral)',
    'Avaliações - Quantitativo',
  ];

  if (activeUser.category === 'Sócio' || activeUser.category === 'Financeiro') {
    reportOptions = [
      'Resultados Financeiros',
      'Resultados Detalhados - Financeiro',
      'Resultados Detalhados - Quantitativo',
      'Controle de Parceiros - Financeiro',
      'Controle de Parceiros - Quantitativo',
      'Casos em Avaliação',
      'Clientes Ativos',
      'Clientes (Geral)',
      'Avaliações - Financeiro',
      'Avaliações - Quantitativo',
    ];
  }

  const periodOptions = [
    'Geral',
    'Ano atual',
    'Mês atual',
    'Customizado',
  ];

  const [alertMessage, setAlertMessage] = useState(false);
  const [activeReport, setActiveReport] = useState(0);
  const [base64, setBase64] = useState('');
  const [endDate, setEndDate] = useState();
  const [selectedEvaluation, setSelectedEvaluation] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [origin, setOrigin] = useState('Geral');
  const [originsList, setOriginsList] = useState([]);
  const [partner, setPartner] = useState('');
  const [partnersList, setPartnersList] = useState([]);
  const [period, setPeriod] = useState(periodOptions[0]);
  const [product, setProduct] = useState('Geral');
  const [productsList, setProductsList] = useState([]);
  const [report, setReport] = useState(reportOptions[0]);
  const [reportData, setReportData] = useState('');
  const [startDate, setStartDate] = useState();

  const retrieveProducts = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'products', reqData);

      setProductsList(data);
    } catch (error) {
      setProductsList([]);
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Reports/index.js - retrieveProducts',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveOrigins = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'origins', reqData);

      setOriginsList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Reports/index.js - retrieveOrigins',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrievePartners = useCallback(async () => {
    try {
      const reqData = { token };

      let data = await API.fetch('GET', 'partners', reqData);
      data = data.sort((a, b) => a.partnerCode.localeCompare(b.partnerCode));

      setPartnersList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Reports/index.js - retrievePartners',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      const promise1 = retrieveProducts();
      const promise2 = retrieveOrigins();
      const promise3 = retrievePartners();

      await Promise.all([promise1, promise2, promise3]);
    };

    fetchData();
  }, [retrieveProducts, retrieveOrigins, retrievePartners]);

  useEffect(() => {
    if (selectedEvaluation) {
      navigate(`/evaluation-workflow/${selectedEvaluation.workflowId}/${selectedEvaluation.evaluationId}`);
    }
  }, [selectedEvaluation]);

  useEffect(() => {
    if (report === 'Clientes Ativos' || report === 'Clientes (Geral)') {
      setPeriod('Geral');
    }
  }, [report]);

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'Geral':
          setStartDate('');
          setEndDate('');
          break;
        case 'Ano atual':
          setStartDate(format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'));
          setEndDate(format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd'));
          break;
        case 'Mês atual':
          setStartDate(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'));
          setEndDate(format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd'));
          break;
        default:
          break;
      }
    }
  }, [period, setStartDate, setEndDate]);

  useEffect(() => {
    if (reportData !== '') {
      switch (reportData.reportTitle) {
        case 'RESULTADOS FINANCEIROS':
          setActiveReport(1);
          break;

        case 'RESULTADOS DETALHADOS - FINANCEIRO':
          setActiveReport(2);
          break;

        case 'RESULTADOS DETALHADOS - QUANTITATIVO':
          setActiveReport(3);
          break;

        case 'CONTROLE DE PARCEIROS - FINANCEIRO':
          setActiveReport(4);
          break;

        case 'CONTROLE DE PARCEIROS - QUANTITATIVO':
          setActiveReport(5);
          break;

        case 'CASOS EM AVALIAÇÃO':
          setActiveReport(6);
          break;

        case 'CLIENTES ATIVOS':
          setActiveReport(7);
          break;

        case 'CLIENTES (GERAL)':
          setActiveReport(8);
          break;

        case 'AVALIAÇÕES - FINANCEIRO':
          setActiveReport(9);
          break;

        case 'AVALIAÇÕES - QUANTITATIVO':
          setActiveReport(10);
          break;

        default:
          setActiveReport(0);
          break;
      }
    }
  }, [reportData, setActiveReport]);

  async function handleGenerateReport() {
    try {
      setActiveReport(0);
      setReportData('');

      const reportQuery = {};
      let target = '';

      if (report) {
        switch (report) {
          case 'Resultados Financeiros':
            target = 'results';
            reportQuery.type = 'summary';
            break;

          case 'Resultados Detalhados - Financeiro':
            target = 'results';
            reportQuery.type = 'financial';
            break;

          case 'Resultados Detalhados - Quantitativo':
            target = 'results';
            reportQuery.type = 'quantitative';
            break;

          case 'Controle de Parceiros - Financeiro':
            target = 'partner';
            reportQuery.type = 'financial';
            break;

          case 'Controle de Parceiros - Quantitativo':
            target = 'partner';
            reportQuery.type = 'quantitative';
            break;

          case 'Casos em Avaliação':
            target = 'unclosed';
            break;

          case 'Clientes Ativos':
            target = 'active clients';
            break;

          case 'Clientes (Geral)':
            target = 'clients';
            break;

          case 'Avaliações - Financeiro':
            target = 'evaluations';
            reportQuery.type = 'financial';
            break;

          case 'Avaliações - Quantitativo':
            target = 'evaluations';
            reportQuery.type = 'quantitative';
            break;

          default:
            break;
        }
      } else {
        delete reportQuery.type;
      }

      if (period) {
        switch (period) {
          case 'Geral':
            delete reportQuery.period;
            break;
          case 'Ano atual':
            reportQuery.period = `${format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd')} to ${format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd')}`;
            break;
          case 'Mês atual':
            reportQuery.period = `${format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd')} to ${format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd')}`;
            break;
          default:
            if (startDate && endDate) {
              reportQuery.period = `${startDate} to ${endDate}`;
            } else {
              delete reportQuery.period;
            }
            break;
        }
      } else {
        delete reportQuery.period;
      }

      if (product) {
        if (product !== 'Geral') {
          reportQuery.product = product;
        } else {
          delete reportQuery.product;
        }
      } else {
        delete reportQuery.product;
      }

      if (origin) {
        if (origin !== 'Geral') {
          reportQuery.origin = origin;
        } else {
          delete reportQuery.origin;
        }
      } else {
        delete reportQuery.origin;
      }

      if (partner) {
        if (partner !== 'Geral' && partner !== '') {
          reportQuery.partner = partner;
        } else {
          delete reportQuery.partner;
        }
      } else {
        delete reportQuery.partner;
      }

      const reqData = {
        token,
      };

      if (Object.keys(reportQuery).length > 0) {
        reqData.query = reportQuery;
      }

      let data;

      switch (target) {
        case 'results':
          data = await API.fetch('GET', 'resultsReport', reqData);
          data.reportTitle = report.toUpperCase();
          if (reportQuery.period) {
            data.startDate = startDate;
            data.endDate = endDate;
          }
          if (reportQuery.product) {
            data.product = product;
          }
          if (reportQuery.origin) {
            data.origin = origin;
          }
          if (partner !== 'Geral' && partner !== '') {
            data.partner = partner;
          }
          if (data.casesToReceive) {
            if (data.casesToReceive.length > 0) {
              data.casesToReceive.forEach((currentCase) => {
                const partners = [];

                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.casesReceived) {
            if (data.casesReceived.length > 0) {
              data.casesReceived.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.casesToReceive && data.casesReceived) {
            data.totalEvaluationValue = (data.casesToReceive.reduce((accumulator, item) => accumulator + parseFloat(item.evaluationValue), 0)
              + data.casesReceived.reduce((accumulator, item) => accumulator + parseFloat(item.evaluationValue), 0)).toFixed(2);
          }
          break;

        case 'partner':
          if (!reportQuery.partner || reportQuery.partner === undefined) {
            setAlertMessage({ title: 'Seleção Incompleta!', message: 'É necessário selecionar um parceiro.' });
            setOpenAlert(true);
            return;
          }
          data = await API.fetch('GET', 'partnerReport', reqData);
          data.reportTitle = report.toUpperCase();
          if (reportQuery.period) {
            data.startDate = startDate;
            data.endDate = endDate;
          }
          if (reportQuery.product) {
            data.product = product;
          }
          data.partner = partner;
          break;

        case 'unclosed':
          data = await API.fetch('GET', 'unclosedReport', reqData);
          data.reportTitle = report.toUpperCase();
          if (reportQuery.period) {
            data.startDate = startDate;
            data.endDate = endDate;
          }
          if (reportQuery.product) {
            data.product = product;
          }
          if (reportQuery.origin) {
            data.origin = origin;
          }
          if (partner !== 'Geral' && partner !== '') {
            data.partner = partner;
          }
          if (data.leadsList) {
            if (data.leadsList.length > 0) {
              data.leadsList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingDocumentsList) {
            if (data.awaitingDocumentsList.length > 0) {
              data.awaitingDocumentsList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingCalculationList) {
            if (data.awaitingCalculationList.length > 0) {
              data.awaitingCalculationList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingKitList) {
            if (data.awaitingKitList.length > 0) {
              data.awaitingKitList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingDistributionList) {
            if (data.awaitingDistributionList.length > 0) {
              data.awaitingDistributionList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.negativeResultsList) {
            if (data.negativeResultsList.length > 0) {
              data.negativeResultsList.forEach((currentCase) => {
                const partners = [];

                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.coldCasesList) {
            if (data.coldCasesList.length > 0) {
              data.coldCasesList.forEach((currentCase) => {
                const partners = [];

                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          data.partner = partner;
          break;

        case 'active clients':
          data = await API.fetch('GET', 'activeClientsReport', reqData);
          data.reportTitle = report.toUpperCase();
          if (reportQuery.product) {
            data.product = product;
          }
          if (reportQuery.origin) {
            data.origin = origin;
          }
          if (partner !== 'Geral' && partner !== '') {
            data.partner = partner;
          }
          if (data.clients) {
            if (data.clients.length > 0) {
              data.clients.forEach((client) => {
                if (client.partners.length === 0) {
                  client.partners.push('-');
                }
                if (client.phones.length > 0) {
                  for (let i = 0; i < client.phones.length; i++) {
                    client.phones[i] = formatPhone(client.phones[i]);
                  }
                } else {
                  client.phones.push('-');
                }
              });
            }
          }
          break;

        case 'clients':
          data = await API.fetch('GET', 'clientsReport', reqData);
          data.reportTitle = report.toUpperCase();
          if (reportQuery.product) {
            data.product = product;
          }
          if (reportQuery.origin) {
            data.origin = origin;
          }
          if (partner !== 'Geral' && partner !== '') {
            data.partner = partner;
          }
          if (data.clients) {
            if (data.clients.length > 0) {
              data.clients.forEach((client) => {
                if (client.partners.length === 0) {
                  client.partners.push('-');
                }
                if (client.phones.length > 0) {
                  for (let i = 0; i < client.phones.length; i++) {
                    client.phones[i] = formatPhone(client.phones[i]);
                  }
                } else {
                  client.phones.push('-');
                }
              });
            }
          }
          break;

        case 'evaluations':
          data = await API.fetch('GET', 'evaluationsReport', reqData);
          data.reportTitle = report.toUpperCase();
          if (reportQuery.period) {
            data.startDate = startDate;
            data.endDate = endDate;
          }
          if (reportQuery.product) {
            data.product = product;
          }
          if (reportQuery.origin) {
            data.origin = origin;
          }
          if (partner !== 'Geral' && partner !== '') {
            data.partner = partner;
          }
          if (data.leadsList) {
            if (data.leadsList.length > 0) {
              data.leadsList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingDocumentsList) {
            if (data.awaitingDocumentsList.length > 0) {
              data.awaitingDocumentsList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingCalculationList) {
            if (data.awaitingCalculationList.length > 0) {
              data.awaitingCalculationList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingKitList) {
            if (data.awaitingKitList.length > 0) {
              data.awaitingKitList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.awaitingDistributionList) {
            if (data.awaitingDistributionList.length > 0) {
              data.awaitingDistributionList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.signedList) {
            if (data.signedList.length > 0) {
              data.signedList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.distributedList) {
            if (data.distributedList.length > 0) {
              data.distributedList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.negativeResultsList) {
            if (data.negativeResultsList.length > 0) {
              data.negativeResultsList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.closedList) {
            if (data.closedList.length > 0) {
              data.closedList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          if (data.coldCasesList) {
            if (data.coldCasesList.length > 0) {
              data.coldCasesList.forEach((currentCase) => {
                const partners = [];
                if (currentCase.partner1) {
                  partners.push(currentCase.partner1);
                }
                if (currentCase.partner2) {
                  partners.push(currentCase.partner2);
                }
                if (currentCase.partner3) {
                  partners.push(currentCase.partner3);
                }
                if (partners.length === 0) {
                  partners.push('-');
                }
                currentCase.partners = partners;
              });
            }
          }
          break;

        default:
          break;
      }

      setReportData(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Reports/index.js - handleGenerateReport',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleExportPDF() {
    let docDefinitions;
    switch (reportData.reportTitle) {
      case 'RESULTADOS DETALHADOS - FINANCEIRO':
        docDefinitions = Report2PDF(reportData);
        setActiveReport(2);
        break;

      case 'RESULTADOS DETALHADOS - QUANTITATIVO':
        docDefinitions = Report3PDF(reportData);
        setActiveReport(3);
        break;

      case 'CONTROLE DE PARCEIROS - FINANCEIRO':
        docDefinitions = Report4PDF(reportData);
        setActiveReport(4);
        break;

      case 'CONTROLE DE PARCEIROS - QUANTITATIVO':
        docDefinitions = Report5PDF(reportData);
        setActiveReport(5);
        break;

      case 'CASOS EM AVALIAÇÃO':
        docDefinitions = Report6PDF(reportData);
        setActiveReport(6);
        break;

      case 'CLIENTES ATIVOS':
        docDefinitions = Report7PDF(reportData);
        setActiveReport(7);
        break;

      case 'CLIENTES (GERAL)':
        docDefinitions = Report8PDF(reportData);
        setActiveReport(8);
        break;

      case 'AVALIAÇÕES - FINANCEIRO':
        docDefinitions = Report9PDF(reportData);
        setActiveReport(9);
        break;

      case 'AVALIAÇÕES - QUANTITATIVO':
        docDefinitions = Report10PDF(reportData);
        setActiveReport(10);
        break;

      default:
        break;
    }

    if (docDefinitions) {
      pdfMake.createPdf(docDefinitions).getBase64((data) => {
        setBase64(data);
      });
    }
  }

  function handleExportExcel() {
    switch (reportData.reportTitle) {
      case 'RESULTADOS DETALHADOS - FINANCEIRO':
        report2Excel(reportData);
        break;

      case 'RESULTADOS DETALHADOS - QUANTITATIVO':
        report3Excel(reportData);
        break;

      case 'CONTROLE DE PARCEIROS - FINANCEIRO':
        report4Excel(reportData);
        break;

      case 'CONTROLE DE PARCEIROS - QUANTITATIVO':
        report5Excel(reportData);
        break;

      case 'CASOS EM AVALIAÇÃO':
        report6Excel(reportData);
        break;

      case 'CLIENTES ATIVOS':
        report7Excel(reportData);
        break;

      case 'CLIENTES (GERAL)':
        report8Excel(reportData);
        break;

      case 'AVALIAÇÕES - FINANCEIRO':
        report9Excel(reportData);
        break;

      case 'AVALIAÇÕES - QUANTITATIVO':
        report10Excel(reportData);
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (base64) {
      const linkSource = `data:application/pdf;base64,${base64}`;
      const downloadLink = document.createElement('a');
      const fileName = `${reportData.reportTitle}${(activeReport === 4 || activeReport === 5) ? (` - PARCEIRO: ${partner}`) : ''} - ${format(new Date(), 'dd.MM.yyyy hhmmss')}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setBase64('');
    }
  }, [base64, reportData.reportTitle, activeReport, partner]);

  return (
    <div>
      <div className='display-reports'>
        <Header secondaryTitle='RELATÓRIOS' />
      </div>
      <div className='menu-reports'>
        <DrawerMenu />
      </div>
      <div className='background-filter' />
      <div className='filter-reports'>
        <div className='select-report'>
          <label className='label'>Relatório:</label>
          <div>
            <select
              className='input'
              type='text'
              value={report}
              onChange={(e) => setReport(e.target.value)}
            >
              {reportOptions.map((reportOption) => (
                <option>{reportOption}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='select-period'>
          <label className='label'>
            Período:
          </label>
          <div className='select-period-inputs'>
            <select
              className='input select-period-type'
              type='text'
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              disabled={report === 'Clientes Ativos' || report === 'Clientes (Geral)'}
            >
              {periodOptions.map((periodOption) => (
                <option>
                  {periodOption}
                </option>
              ))}
            </select>
            <div className='select-period-dates'>
              <input
                className='input'
                type='date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                disabled={period !== 'Customizado'}
              />
              <label className='label select-period-date-label'> a </label>
              <input
                className='input'
                type='date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                disabled={period !== 'Customizado'}
              />
            </div>
          </div>
        </div>
        <div className='select-product'>
          <label className='label'>
            Produto
          </label>
          <select
            className='input'
            type='text'
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          >
            <option>Geral</option>
            {productsList.map((productOption) => (
              <option>
                {productOption.product}
              </option>
            ))}
          </select>
        </div>
        <div className='select-origin'>
          <label className='label'>Origem</label>
          <select
            className='input'
            type='text'
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            <option>Geral</option>
            {originsList.map((originOption) => (
              <option>
                {originOption.origin}
              </option>
            ))}
          </select>
        </div>
        <div className='select-partner'>
          <label className='label'>
            Parceiro
          </label>
          <select
            className='input'
            type='text'
            value={partner}
            onChange={(e) => setPartner(e.target.value)}
          >
            <option />
            {report !== reportOptions[3] && report !== reportOptions[4] && (
              <option>Geral</option>
            )}
            {partnersList.map((partnerOption) => (
              <option>{partnerOption.partnerCode}</option>
            ))}
          </select>
        </div>
        <div
          className='generate-reports-btn'
          onClick={handleGenerateReport}
        >
          <BlueButton
            buttonText='Consultar'
            buttonWidth='100px'
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            height: '10px',
            zIndex: '1',
            justifyContent: 'flex-end',
            alignContent: 'flex-end',
          }}
        >
          {activeReport > 1 && (
            <div
              style={{
                padding: '0 10px',
                display: 'flex',
                width: '80px',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{ cursor: 'pointer' }}
                onClick={handleExportPDF}
              >
                <LightTooltip title='Gerar PDF'>
                  <PictureAsPdfIcon
                    fontSize='medium'
                    style={{ color: '#171f48' }}
                  />
                </LightTooltip>
              </div>
              {activeReport > 1 && (
                <div style={{ cursor: 'pointer' }} onClick={handleExportExcel}>
                  <LightTooltip title='Exportar para Excel'>
                    <TableViewIcon fontSize='medium' style={{ color: '#171f48' }} />
                  </LightTooltip>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className='interface-reports'>
          {activeReport === 1 && (
            <Report1 reportData={reportData} />
          )}
          {activeReport === 2 && (
            <Report2
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 3 && (
            <Report3
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 4 && (
            <Report4
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 5 && (
            <Report5
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 6 && (
            <Report6
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 7 && (
            <Report7
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 8 && (
            <Report8
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 9 && (
            <Report9
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
          {activeReport === 10 && (
            <Report10
              reportData={reportData}
              setSelectedEvaluation={setSelectedEvaluation}
            />
          )}
        </div>
      </div>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Reports;
