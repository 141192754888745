import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@mui/material';

import '../../../../style.css';

function ListRows({
  origin, setSelectedOrigin, handleConfirmRemoveOrigin, handleConfirmActivateOrigin,
}) {
  return (
    <>
      <tr
        key={origin.id}
        className='table-rows-component-tr'
      >
        <td
          className='table-rows-component-td table-rows-component-first-column'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.origin}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.description}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.clients}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.onGoing}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.distributed}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.unsuccessful}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.coldCases}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedOrigin(origin)}
        >
          {origin.active ? 'Ativo' : 'Inativo'}
        </td>
        <td
          className='table-rows-component-buttons-cell table-rows-component-last-column'
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          {origin.active ? (
            <span
              className='table-buttons'
              onClick={() => handleConfirmRemoveOrigin(origin)}
            >
              <Tooltip title='Excluir/Inativar'>
                <DeleteForeverIcon style={{ color: 'red' }} />
              </Tooltip>
            </span>
          ) : (
            <span
              className='table-buttons activate-button'
              onClick={() => handleConfirmActivateOrigin(origin)}
            >
              <Tooltip title='Reativar'>
                <ReplayIcon style={{ color: 'green' }} />
              </Tooltip>
            </span>
          )}
        </td>
      </tr>
    </>
  );
}

export default ListRows;
