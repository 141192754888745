import {
  useEffect,
  useState,
} from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { API } from '../../../../services/api';

import { doubleSpaceRegex } from '../../../../services/regex';

import {
  validatePhone,
  validateZipCode,
} from '../../../../services/validations';

import {
  formatPhone,
  formatZipCode,
} from '../../../../services/format';

import whatsappIcon from '../../../../images/whatsapp-icon.png';

import './style.css';

function ClientFormRow2({ selectedClient, clientData, setClientData }) {
  const [addressDetails, setAddressDetails] = useState();
  const [city, setCity] = useState();
  const [district, setDistrict] = useState();
  const [invalidZipCode, setInvalidZipCode] = useState(false);
  const [number, setNumber] = useState();
  const [phone1, setPhone1] = useState();
  const [phone1Details, setPhone1Details] = useState();
  const [phone2, setPhone2] = useState();
  const [phone2Details, setPhone2Details] = useState();
  const [phone3, setPhone3] = useState();
  const [phone3Details, setPhone3Details] = useState();
  const [phone4, setPhone4] = useState();
  const [phone4Details, setPhone4Details] = useState();
  const [phone5, setPhone5] = useState();
  const [phone5Details, setPhone5Details] = useState();
  const [state, setState] = useState();
  const [street, setStreet] = useState();
  const [zipCode, setZipCode] = useState();

  useEffect(() => {
    if (selectedClient !== undefined) {
      setAddressDetails(selectedClient.addressDetails ? selectedClient.addressDetails : '');
      setCity(selectedClient.city ? selectedClient.city : '');
      setDistrict(selectedClient.district ? selectedClient.district : '');
      setNumber(selectedClient.number ? selectedClient.number : '');
      setPhone1(selectedClient.phone1 ? formatPhone(selectedClient.phone1) : '');
      setPhone1Details(selectedClient.phone1Details ? selectedClient.phone1Details : '');
      setPhone2(selectedClient.phone2 ? formatPhone(selectedClient.phone2) : '');
      setPhone2Details(selectedClient.phone2Details ? selectedClient.phone2Details : '');
      setPhone3(selectedClient.phone3 ? formatPhone(selectedClient.phone3) : '');
      setPhone3Details(selectedClient.phone3Details ? selectedClient.phone3Details : '');
      setPhone4(selectedClient.phone4 ? formatPhone(selectedClient.phone4) : '');
      setPhone4Details(selectedClient.phone4Details ? selectedClient.phone4Details : '');
      setPhone5(selectedClient.phone5 ? formatPhone(selectedClient.phone5) : '');
      setPhone5Details(selectedClient.phone5Details ? selectedClient.phone5Details : '');
      setState(selectedClient.state ? selectedClient.state : '');
      setStreet(selectedClient.street ? selectedClient.street : '');
      setZipCode(selectedClient.zipCode ? formatZipCode(selectedClient.zipCode) : '');
    }
  }, [selectedClient]);

  useEffect(() => {
    let currentData = {};

    if (clientData !== undefined && clientData !== '') {
      currentData = clientData;
    }

    if (addressDetails) {
      if (addressDetails !== selectedClient.addressDetails) {
        currentData.addressDetails = addressDetails;
      }
    } else {
      currentData.addressDetails = null;
    }

    if (city) {
      if (city !== selectedClient.city) {
        currentData.city = city;
      }
    } else {
      currentData.city = null;
    }

    if (district) {
      if (district !== selectedClient.district) {
        currentData.district = district;
      }
    } else {
      currentData.district = null;
    }

    if (number) {
      if (number !== selectedClient.number) {
        currentData.number = number;
      }
    } else {
      currentData.number = null;
    }

    if (phone1) {
      if (validatePhone(phone1)) {
        if (phone1.replace(/\D/g, '') !== selectedClient.phone1) {
          currentData.phone1 = phone1.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone1 = null;
    }

    if (phone1Details) {
      if (phone1Details !== selectedClient.phone1Details) {
        currentData.phone1Details = phone1Details;
      }
    } else {
      currentData.phone1Details = null;
    }

    if (phone2) {
      if (validatePhone(phone2)) {
        if (phone2.replace(/\D/g, '') !== selectedClient.phone2) {
          currentData.phone2 = phone2.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone2 = null;
    }

    if (phone2Details) {
      if (phone2Details !== selectedClient.phone2Details) {
        currentData.phone2Details = phone2Details;
      }
    } else {
      currentData.phone2Details = null;
    }

    if (phone3) {
      if (validatePhone(phone3)) {
        if (phone3.replace(/\D/g, '') !== selectedClient.phone3) {
          currentData.phone3 = phone3.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone3 = null;
    }

    if (phone3Details) {
      if (phone3Details !== selectedClient.phone3Details) {
        currentData.phone3Details = phone3Details;
      }
    } else {
      currentData.phone3Details = null;
    }

    if (phone4) {
      if (validatePhone(phone4)) {
        if (phone4.replace(/\D/g, '') !== selectedClient.phone4) {
          currentData.phone4 = phone4.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone4 = null;
    }

    if (phone4Details) {
      if (phone4Details !== selectedClient.phone4Details) {
        currentData.phone4Details = phone4Details;
      }
    } else {
      currentData.phone4Details = null;
    }

    if (phone5) {
      if (validatePhone(phone5)) {
        if (phone5.replace(/\D/g, '') !== selectedClient.phone5) {
          currentData.phone5 = phone5.replace(/\D/g, '');
        }
      }
    } else {
      currentData.phone5 = null;
    }

    if (phone5Details) {
      if (phone5Details !== selectedClient.phone5Details) {
        currentData.phone5Details = phone5Details;
      }
    } else {
      currentData.phone5Details = null;
    }

    if (state) {
      if (state !== selectedClient.state) {
        currentData.state = state;
      }
    } else {
      currentData.state = null;
    }

    if (street) {
      if (street !== selectedClient.street) {
        currentData.street = street;
      }
    } else {
      currentData.street = null;
    }

    if (zipCode) {
      if (validateZipCode(zipCode)) {
        if (zipCode.replace(/\D/g, '') !== selectedClient.zipCode) {
          currentData.zipCode = zipCode.replace(/\D/g, '');
        }
        setInvalidZipCode(false);
      } else {
        setInvalidZipCode(true);
      }
    } else {
      currentData.zipCode = null;
    }
    setClientData(currentData);
  }, [addressDetails, city, district, number, phone1, phone1Details, phone2, phone2Details, phone3,
    phone3Details, phone4, phone4Details, phone5, phone5Details, state, street, zipCode, clientData,
    setClientData, selectedClient.addressDetails, selectedClient.city, selectedClient.district,
    selectedClient.number, selectedClient.phone1, selectedClient.phone1Details, selectedClient.phone2,
    selectedClient.phone2Details, selectedClient.phone3, selectedClient.phone3Details, selectedClient.phone4,
    selectedClient.phone4Details, selectedClient.phone5, selectedClient.phone5Details, selectedClient.state,
    selectedClient.street, selectedClient.zipCode]);

  async function handleRetrieveAddress(processedZipCode) {
    try {
      const data = await API.retrieveAddress(processedZipCode);

      setStreet(data.logradouro);
      setDistrict(data.bairro);
      setCity(data.localidade);
      setState(data.uf);
    } catch (error) {
      setStreet('');
      setDistrict('');
      setCity('');
      setState('');
    }
  }

  function formatAndValidateZipCodeInput(zipCode) {
    const formattedZipCode = zipCode
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '$1.$2')
      .replace(/(\d)(\d{3})$/, '$1-$2');
    setZipCode(formattedZipCode);

    if (zipCode.replace(/\D/g, '').length === 8) {
      const processedZipCode = zipCode.replace(/\D/g, '');
      handleRetrieveAddress(processedZipCode);
    }
  }

  function formatPhoneNumberInput(phoneNumber, id) {
    const formattedPhoneNumber = phoneNumber
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1)$2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
    switch (id) {
      case '1':
        setPhone1(formattedPhoneNumber);
        break;
      case '2':
        setPhone2(formattedPhoneNumber);
        break;
      case '3':
        setPhone3(formattedPhoneNumber);
        break;
      case '4':
        setPhone4(formattedPhoneNumber);
        break;
      case '5':
        setPhone5(formattedPhoneNumber);
        break;
      default:
        break;
    }
  }

  return (
    <div className='row2'>
      <div className='client-row2'>
        <div className='client-row2-col1'>
          <div className='client-row'>
            <div className='client-zip-code'>
              <label className='form-label'>
                CEP
              </label>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <input
                  className='input'
                  variant='outlined'
                  size='small'
                  type='text'
                  maxLength='10'
                  style={{ textAlign: 'center' }}
                  value={zipCode}
                  onChange={(e) => formatAndValidateZipCodeInput(e.target.value)}
                />
                {invalidZipCode && (
                  <span
                    style={{ marginLeft: -35 }}
                  >
                    <ErrorOutlineIcon style={{ color: 'red' }} />
                  </span>
                )}
              </div>
            </div>
            <div className='client-street'>
              <label className='form-label'>
                Logradouro
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={street}
                onChange={(e) => setStreet(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
          </div>
          <div className='client-row'>
            <div className='client-number'>
              <label className='form-label'>
                Número
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={number}
                onChange={(e) => setNumber(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='client-address-details'>
              <label className='form-label'>
                Complemento
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={addressDetails}
                onChange={(e) => setAddressDetails(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='client-district'>
              <label className='form-label'>
                Bairro
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={district}
                onChange={(e) => setDistrict(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
          </div>
          <div className='client-row'>
            <div className='client-city'>
              <label className='form-label'>
                Cidade
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                value={city}
                onChange={(e) => setCity(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='client-state'>
              <label className='form-label'>
                Estado
              </label>
              <input
                className='input center-text'
                variant='outlined'
                size='small'
                type='text'
                value={state}
                onChange={(e) => setState(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
          </div>
        </div>
        <div className='client-row2-col2'>
          <div className='client-phone'>
            <label className='form-label'>
              Telefone
            </label>
            <div className='client-phone-input'>
              <input
                className='input center-text'
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  width: '150px',
                }}
                placeholder='Telefone 01'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={phone1}
                onChange={(e) => formatPhoneNumberInput(e.target.value, '1')}
              />
              {!!phone1
                && (
                  <span className='whatsapp-button'>
                    <a
                      className='whatsapp-link'
                      href={`http://web.whatsapp.com/send?phone=${55 + phone1.replace(/\D/g, '')}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                          marginBottom: '10px',
                        }}
                        src={whatsappIcon}
                        alt=''
                      />
                    </a>
                  </span>
                )}
            </div>
            <div className='client-phone-input'>
              <input
                className='input center-text'
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  textAlign: 'left',
                  width: '150px',
                }}
                placeholder='Telefone 02'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={phone2}
                onChange={(e) => formatPhoneNumberInput(e.target.value, '2')}
              />
              {!!phone2
                && (
                  <span className='whatsapp-button'>
                    <a
                      className='whatsapp-link'
                      href={`http://web.whatsapp.com/send?phone=${55 + phone2.replace(/\D/g, '')}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                        }}
                        src={whatsappIcon}
                        alt=''
                      />
                    </a>
                  </span>
                )}
            </div>
            <div className='client-phone-input'>
              <input
                className='input center-text'
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  textAlign: 'left',
                  width: '150px',
                }}
                placeholder='Telefone 03'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={phone3}
                onChange={(e) => formatPhoneNumberInput(e.target.value, '3')}
              />
              {!!phone3
                && (
                  <span className='whatsapp-button'>
                    <a
                      className='whatsapp-link'
                      href={`http://web.whatsapp.com/send?phone=${55 + phone3.replace(/\D/g, '')}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                        }}
                        src={whatsappIcon}
                        alt=''
                      />
                    </a>
                  </span>
                )}
            </div>
            <div className='client-phone-input'>
              <input
                className='input center-text'
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  textAlign: 'left',
                  width: '150px',
                }}
                placeholder='Telefone 04'
                variant='outlined'
                size='small'
                type='text'
                maxLength='14'
                value={phone4}
                onChange={(e) => formatPhoneNumberInput(e.target.value, '4')}
              />
              {!!phone4
                && (
                  <span className='whatsapp-button'>
                    <a
                      className='whatsapp-link'
                      href={`http://web.whatsapp.com/send?phone=${55 + phone4.replace(/\D/g, '')}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                        }}
                        src={whatsappIcon}
                        alt=''
                      />
                    </a>
                  </span>
                )}
            </div>
            <div className='client-phone-input'>
              <input
                className='input center-text'
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  textAlign: 'left',
                  width: '150px',
                }}
                placeholder='Telefone 05'
                variant='outlined'
                size='small'
                type='text'
                maxLength='15'
                value={phone5}
                onChange={(e) => formatPhoneNumberInput(e.target.value, '5')}
              />
              {!!phone5
                && (
                  <span className='whatsapp-button'>
                    <a
                      className='whatsapp-link'
                      href={`http://web.whatsapp.com/send?phone=${55 + phone5.replace(/\D/g, '')}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                        }}
                        src={whatsappIcon}
                        alt=''
                      />
                    </a>
                  </span>
                )}
            </div>
          </div>
          <div className='client-phone-details'>
            <label className='form-label'>
              Detalhes
            </label>
            <input
              className='input left-text'
              variant='outlined'
              size='small'
              type='text'
              value={phone1Details}
              onChange={(e) => setPhone1Details(e.target.value.replace(doubleSpaceRegex, ' '))}
            />
            <input
              style={{ marginTop: '10px' }}
              className='input left-text'
              variant='outlined'
              size='small'
              type='text'
              value={phone2Details}
              onChange={(e) => setPhone2Details(e.target.value.replace(doubleSpaceRegex, ' '))}
            />
            <input
              style={{ marginTop: '10px' }}
              className='input left-text'
              variant='outlined'
              size='small'
              type='text'
              value={phone3Details}
              onChange={(e) => setPhone3Details(e.target.value.replace(doubleSpaceRegex, ' '))}
            />
            <input
              style={{ marginTop: '10px' }}
              className='input left-text'
              variant='outlined'
              size='small'
              type='text'
              value={phone4Details}
              onChange={(e) => setPhone4Details(e.target.value.replace(doubleSpaceRegex, ' '))}
            />
            <input
              style={{ marginTop: '10px' }}
              className='input left-text'
              variant='outlined'
              size='small'
              type='text'
              value={phone5Details}
              onChange={(e) => setPhone5Details(e.target.value.replace(doubleSpaceRegex, ' '))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientFormRow2;
