import {
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Origin({
  openModal, setOpenModal, selectedOrigin, setSelectedOrigin, setUpdateList,
}) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [description, setDescription] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [origin, setOrigin] = useState();

  const title = selectedOrigin ? 'Editar Origem' : 'Cadastrar Origem';

  useEffect(() => {
    if (selectedOrigin) {
      setOrigin(!selectedOrigin.origin ? '' : selectedOrigin.origin);
      setDescription(!selectedOrigin.description ? '' : selectedOrigin.description);
    }
  }, [selectedOrigin]);

  function handleCloseOriginModal() {
    setOpenModal(false);
    setOrigin('');
    setDescription('');
    setSelectedOrigin('');
  }

  async function handleSaveOrigin() {
    try {
      if (!origin) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o nome da origem.',
        });
        setOpenAlert(true);
        return;
      }

      const originData = { origin };

      if (description) {
        originData.description = description;
      }

      const reqData = {
        body: originData,
        token,
      };

      let data;

      if (!selectedOrigin) {
        data = await API.fetch('POST', 'origins', reqData);
      } else {
        reqData.id = selectedOrigin.id;
        data = await API.fetch('PUT', 'origins', reqData);
      }

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      handleCloseOriginModal();

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Origin/index.js - handleSaveOrigin',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
        >
          <div className='title-modal'>
            <div className='dialog-title'>
              {title}
              <div
                className='close-button'
                onClick={handleCloseOriginModal}
              >
                <CloseIcon />

              </div>
            </div>
          </div>
          <DialogContent sx={{ width: '450px' }}>
            <div className='origin-input'>
              <input
                style={{ marginBottom: '5px' }}
                autoFocus
                className='input'
                placeholder='Origem'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
              />
              <input
                className='input'
                placeholder='Descrição'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </DialogContent>
          {activeUser.category === 'Sócio' && (
            <div
              className='dialog-actions'
              style={{
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <DialogActions sx={{ padding: '20px 25px' }}>
                <div onClick={handleCloseOriginModal}>
                  <GrayButton
                    buttonText='Cancelar'
                    buttonWidth='90px'
                  />
                </div>
                <div onClick={handleSaveOrigin}>
                  <BlueButton
                    className='recover-button'
                    buttonText={selectedOrigin ? 'Salvar' : 'Criar'}
                    buttonWidth={selectedOrigin ? '70px' : '60px'}
                  />
                </div>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Origin;
