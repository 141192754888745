import {
  useContext,
  useState,
} from 'react';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemText,
} from '@mui/material';

import { AuthContext } from '../../contexts/auth';

import menuIcon from '../../images/menu.png';

import ChangePassword from '../Modals/ChangePassword';

import './style.css';

function DrawerMenu() {
  const location = useLocation();

  const navigate = useNavigate();

  const {
    activeUser,
    logout,
  } = useContext(AuthContext);

  const [menuState, setMenuState] = useState({ left: false });
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const menuItems = [
    ['Dashboard', '/home'],
    ['Usuários', '/users'],
    ['Origens', '/origins'],
    ['Produtos', '/products'],
    ['Clientes', '/clients'],
    ['Parceiros', '/partners'],
    ['Avaliações', '/evaluations'],
    ['Financeiro', '/financial-transactions'],
    ['Relatórios', '/reports'],
  ];

  function toggleDrawer(anchor, open) {
    return (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setMenuState({ ...menuState, [anchor]: open });
    };
  }

  const list = (anchor) => (
    <div
      className='list'
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className='list-container'>
        {menuItems.filter((item) => item[0] !== 'Financeiro' || activeUser.category !== 'Colaborador').map((item) => (
          <div
            key={item[0]}
            className={location.pathname !== item[1] ? 'background-list' : 'background-list-active'}
            button
            onClick={() => ((location.pathname !== item[1]) ? navigate(item[1]) : '')}
          >
            <ListItemText primary={item[0]} />
          </div>
        ))}

        <Divider />
        {['Alterar senha'].map((text, index) => (
          <div
            key={text}
            className='background-sub-list'
            button
            onClick={() => setOpenChangePasswordModal(true)}
          >
            <ListItemText primary={text} />
          </div>
        ))}
        {['Sair'].map((text, index) => (
          <div
            key={text}
            className='background-sub-list'
            button
            onClick={logout}
          >
            <ListItemText primary={text} />
          </div>
        ))}
      </List>
    </div>
  );

  return (
    <>
      {['left'].map((anchor) => (
        <>
          <div style={{ zIndex: -2 }}>
            <IconButton
              style={{ outline: 'none' }}
              onClick={toggleDrawer(anchor, true)}
              color='inherit'
              aria-label='open drawer'
              edge='end'
            >
              <img
                className='menu-icon'
                alt='Ícone de menu'
                src={menuIcon}
              />
            </IconButton>
          </div>
          <div style={{ zIndex: 3 }}>
            <Drawer
              anchor={anchor}
              open={menuState[anchor]}
              onClose={toggleDrawer(anchor, false)}
              style={{ zIndex: 3 }}
            >
              {list(anchor)}
            </Drawer>
          </div>
        </>
      ))}
      {openChangePasswordModal && (
        <ChangePassword
          openChangePasswordModal={openChangePasswordModal}
          setOpenChangePasswordModal={setOpenChangePasswordModal}
        />
      )}
    </>
  );
}

export default DrawerMenu;
