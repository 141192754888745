import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';

import Alert from '../../components/Modals/Alert';
import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';
import BlueButton from '../../components/Buttons/BlueButton';
import User from '../../components/Modals/User';
import UsersList from '../../components/Lists/Users';

import '../../style.css';

function Users() {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [updateList, setUpdateList] = useState(false);
  const [users, setUsers] = useState([]);

  const retrieveUsers = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'users', reqData);

      setUsers(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Users/index.js - retrieveUsers',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveCategories = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'userCategories', reqData);

      setCategoriesList(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Users/index.js - retrieveCategories',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  function handleNewUser() {
    setSelectedUser('');
    setOpenModal(true);
  }

  useEffect(() => {
    retrieveUsers();
  }, [retrieveUsers]);

  useEffect(() => {
    if (updateList) {
      retrieveUsers();
      setUpdateList(false);
    }
  }, [updateList, retrieveUsers]);

  useEffect(() => {
    if (selectedUser) {
      setOpenModal(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (openModal && categoriesList.length === 0) {
      retrieveCategories();
    }
  }, [openModal, retrieveCategories]);

  return (
    <div className='page-container'>
      <Header secondaryTitle='USUÁRIOS' />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
          {activeUser.category === 'Sócio' && (
            <div onClick={handleNewUser}>
              <BlueButton buttonText='Novo   +' />
            </div>
          )}
        </div>
        <div className='page-table-area'>
          {users.length > 0 && (
            <UsersList
              users={users}
              setSelectedUser={setSelectedUser}
              setUpdateList={setUpdateList}
            />
          )}
        </div>
      </div>
      {openModal && (
        <User
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setUpdateList={setUpdateList}
          auxiliaryLists={{
            categoriesList,
          }}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Users;
