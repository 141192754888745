import {
  Cep,
  Cnpj,
  Cpf,
  Phone,
} from 'br-helpers';

function validateEmail(email) {
  if (/^[\w%+-]+(\.[\w%+-]+)*@[\w%+-]+(\.[\w%+-]+)+$/.test(email)) {
    return (true);
  }
  return (false);
}

function validatePhone(phone) {
  return Phone.isValid(phone);
}

function validateTaxId(taxId, type) {
  if (type === 'cpf') {
    return Cpf.isValid(taxId);
  }
  if (type === 'cnpj') {
    return Cnpj.isValid(taxId);
  }
}

function validateZipCode(zipCode) {
  return Cep.isValid(zipCode);
}

export {
  validateEmail,
  validatePhone,
  validateTaxId,
  validateZipCode,
};
