import { useNavigate } from 'react-router-dom';

import {
  format,
  parse,
} from 'date-fns';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@mui/material';

import { formatTaxId } from '../../../../services/format';

import '../../../../style.css';

function ListRows({
  evaluation, handleConfirmDeactivateEvaluation, handleConfirmActivateEvaluation,
}) {
  const navigate = useNavigate();

  return (
    <>
      <tr
        key={evaluation.id}
        className='table-rows-component-tr'
      >
        <td
          className='table-rows-component-td table-rows-component-first-column'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.createdAt
            ? format(parse(evaluation.createdAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
            : '-'}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.client}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.taxId ? formatTaxId(evaluation.taxId, 'cpf') : '-'}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.phones.map((phone) => (
            <div>{phone}</div>
          ))}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.origin}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.partners.map((partner) => (
            <div>{partner}</div>
          ))}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.product}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.retirementBenefitDate
            ? format(parse(evaluation.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
            : '-'}
        </td>
        <td
          className='table-rows-component-td text-center'
          style={{ color: (evaluation.deadlineAlert && 'red') }}
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.deadline}
          {' '}
          {evaluation.deadlineAlert
            ? (
              <span>
                <ErrorOutlineIcon style={{
                  color: 'red',
                  margin: '-2px 0 0 4px',
                }}
                />
              </span>
            )
            : <span />}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.distributedAt
            ? format(parse(evaluation.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
            : '-'}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.type || '-'}
        </td>
        <td
          className='table-rows-component-td text-center'
          style={{
            position: 'sticky',
            right: 60,
            zIndex: 3,
            backgroundColor: '#FFF',
          }}
          onClick={() => navigate(`/evaluation-workflow/${evaluation.workflowId}/${evaluation.id}`)}
        >
          {evaluation.completeStatus}
        </td>
        <td
          className='table-rows-component-buttons-cell table-rows-component-last-column'
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          {evaluation.coldCase
            ? (
              <span className='table-buttons activate-button' onClick={() => handleConfirmActivateEvaluation(evaluation)}>
                <Tooltip title='Reativar'>
                  <ReplayIcon style={{ color: 'green' }} />
                </Tooltip>
              </span>
            ) : (
              <span className='table-buttons' onClick={() => handleConfirmDeactivateEvaluation(evaluation)}>
                <Tooltip title='Mover para casos frios'>
                  <RemoveCircleOutlineIcon style={{ color: 'red' }} />
                </Tooltip>
              </span>
            )}
        </td>
      </tr>
    </>
  );
}

export default ListRows;
