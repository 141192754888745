// Manter import { Chart as ChartJS } from 'chart.js/auto';
import { Chart as ChartJS } from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

import './style.css';

function DoughnutChart({ chartData }) {
  const data = {
    labels: ['Fechados*', 'Sem direito', 'Em avaliação', 'Casos frios'],
    datasets: [{
      label: '',
      data: Object.values(chartData),
      backgroundColor: ['#0FAA0F', '#F70707', '#EFC337', '#171f48'],
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            size: 14,
            weight: 'normal',
          },
          color: '#000',
        },
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label(context) {
            const label = context.label || '';
            const value = context.parsed || 0;
            const sum = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / sum) * 100).toFixed(1);
            return ` ${value} (${percentage}%)`;
          },
        },
        bodyFont: {
          size: 14,
        },
        titleColor: '#FFFFFF',
        bodyColor: '#FFFFFF',
      },
    },
    cutout: '40%',
  };

  return (
    <div
      className='dahsboard-doughnut-chart'
      style={{ width: '97%' }}
    >
      <Doughnut
        data={data}
        options={options}
      />
    </div>
  );
}

export default DoughnutChart;
