import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import {
  format,
  parse,
} from 'date-fns';

import './style.css';

function Report9({ reportData, setSelectedEvaluation }) {
  const columnsList01 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList02 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'docsRequestedAt', label: 'SOLICITAÇÃO DOCUMENTAÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList03 = columnsList02;

  const columnsList04 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluatedAt', label: 'DATA AVALIAÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'evaluationValue', label: 'VALOR DO CÁLCULO', minWidth: 75, width: '8%', align: 'right',
    },
    {
      id: 'kitRequestedAt', label: 'SOLICITAÇÃO KIT', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList05 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluationValue', label: 'VALOR DO CÁLCULO', minWidth: 75, width: '8%', align: 'right',
    },
    {
      id: 'kitReceivedAt', label: 'RECEBIMENTO KIT', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList06 = columnsList05;

  const columnsList07 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluationValue', label: 'VALOR DO CÁLCULO', minWidth: 75, width: '8%', align: 'right',
    },
    {
      id: 'distributedAt', label: 'DISTRIBUIÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList08 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluatedAt', label: 'DATA AVALIAÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList09 = [
    {
      id: 'client', label: 'CLIENTE', minWidth: 100, width: '20%',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 200, width: '30%', align: 'center',
    },
    {
      id: 'partners', label: 'PARCEIRO', minWidth: 65, width: '8%', align: 'center',
    },
    {
      id: 'product', label: 'PRODUTO', minWidth: 110, width: '8%', align: 'center',
    },
    {
      id: 'evaluatedAt', label: 'DATA AVALIAÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'evaluationValue', label: 'VALOR DO CÁLCULO', minWidth: 75, width: '8%', align: 'right',
    },
    {
      id: 'kitRequestedAt', label: 'SOLICITAÇÃO KIT', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'kitReceivedAt', label: 'RECEBIMENTO KIT', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'distributedAt', label: 'DISTRIBUIÇÃO', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'clientInformedAt', label: 'INFORMAÇÃO CLIENTE', minWidth: 80, width: '8%', align: 'center',
    },
    {
      id: 'status', label: 'STATUS', minWidth: 110, width: '8%', align: 'center',
    },
  ];

  const columnsList10 = columnsList09;

  return (
    <>
      <Paper sx={{
        width: '100%', height: '60vh', overflowY: 'scroll', paddingTop: '4px',
      }}
      >
        <header>
          <h3 className='report9-title'>
            {reportData.reportTitle}
          </h3>
        </header>
        <div className='report9-summary'>
          <div className='report9-summary-column' style={{ width: '30%' }}>
            <div>
              <span>
                <strong>Período: </strong>
                {(reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Produto: </strong>
                {reportData.product ? reportData.product : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Origem: </strong>
                {reportData.origin ? reportData.origin : 'Geral'}
              </span>
            </div>
          </div>
          <div className='report9-summary-column' style={{ width: '25%' }}>
            <div>
              <span>
                <strong>Parceiro: </strong>
                {reportData.partner ? reportData.partner : 'Geral'}
              </span>
            </div>
            <div>
              <span>
                <strong>Leads: </strong>
                {reportData.leadsList.length > 0 ? reportData.leadsList.length.toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Aguardando documentação: </strong>
                {reportData.awaitingDocumentsList.length > 0 ? reportData.awaitingDocumentsList.length.toLocaleString() : '-'}
              </span>
            </div>
          </div>
          <div className='report9-summary-column' style={{ width: '25%' }}>
            <div>
              <span>
                <strong>Aguardando cálculo: </strong>
                {reportData.awaitingCalculationList.length > 0 ? reportData.awaitingCalculationList.length.toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Aguardando kit: </strong>
                {reportData.awaitingKitList.length > 0 ? reportData.awaitingKitList.length.toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Aguardando distribuição: </strong>
                {reportData.awaitingDistributionList.length > 0 ? reportData.awaitingDistributionList.length.toLocaleString() : '-'}
              </span>
            </div>
          </div>
          <div className='report9-summary-column' style={{ width: '20%' }}>
            <div>
              <span>
                <Tooltip title='Casos distribuídos (judiciais) e kits recebidos (administrativos)'>
                  <strong>Casos fechados: </strong>
                </Tooltip>
                {(reportData.distributedList.length > 0 || reportData.signedList.length) ? (reportData.distributedList.length + reportData.signedList.length).toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Sem direito: </strong>
                {reportData.negativeResultsList.length > 0 ? reportData.negativeResultsList.length.toLocaleString() : '-'}
              </span>
            </div>
            <div>
              <span>
                <strong>Baixados: </strong>
                {reportData.closedList.length > 0 ? reportData.closedList.length.toLocaleString() : '-'}
              </span>
            </div>
          </div>
          <div className='report9-summary-column' style={{ width: '20%' }}>
            <div>
              <span>
                <strong>Casos frios: </strong>
                {reportData.coldCasesList.length > 0 ? reportData.coldCasesList.length.toLocaleString() : '-'}
              </span>
            </div>
          </div>
        </div>
        {reportData.leadsList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>LEADS</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table className='table-body-fixed'>
                  <TableHead>
                    <TableRow>
                      {columnsList01.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.leadsList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList01.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'client'
                                    ? (
                                      <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                        {value}
                                      </TableCell>
                                    )
                                    : (
                                      <TableCell key={column.id} align={column.align}>
                                        {value}
                                      </TableCell>
                                    )
                                  )}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.awaitingDocumentsList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>AGUARDANDO DOCUMENTAÇÃO</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList02.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.awaitingDocumentsList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList02.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'docsRequestedAt' || column.id === 'paymentReceivedAt'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'client'
                                      ? (
                                        <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                          {value}
                                        </TableCell>
                                      )
                                      : (
                                        <TableCell key={column.id} align={column.align}>
                                          {value}
                                        </TableCell>
                                      )
                                    ))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.awaitingCalculationList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>AGUARDANDO CÁLCULO</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList03.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.awaitingCalculationList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList03.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'docsRequestedAt'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'client'
                                      ? (
                                        <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                          {value}
                                        </TableCell>
                                      )
                                      : (
                                        <TableCell key={column.id} align={column.align}>
                                          {value}
                                        </TableCell>
                                      )
                                    )
                                  )}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.awaitingKitList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>AGUARDANDO KIT</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList04.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.awaitingKitList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList04.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluationValue'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0 ? (Number(value).toLocaleString() + (Number(value).toLocaleString().indexOf(',') === -1 ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')) : '-'}
                                      </TableCell>
                                    )
                                    : ((column.id === 'evaluatedAt' || column.id === 'kitRequestedAt')
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.awaitingDistributionList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>AGUARDANDO DISTRIBUIÇÃO</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList05.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.awaitingDistributionList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList05.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluationValue'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0 ? (Number(value).toLocaleString() + (Number(value).toLocaleString().indexOf(',') === -1 ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')) : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'kitReceivedAt'
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.signedList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>CASOS ADMINISTRATIVOS FECHADOS - KIT RECEBIDO</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList06.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.signedList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList06.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluationValue'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0 ? (Number(value).toLocaleString() + (Number(value).toLocaleString().indexOf(',') === -1 ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')) : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'kitReceivedAt'
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.distributedList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>CASOS JUDICIAIS FECHADOS - DISTRIBUÍDOS</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList07.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.distributedList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList07.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluationValue'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0 ? (Number(value).toLocaleString() + (Number(value).toLocaleString().indexOf(',') === -1 ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')) : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'distributedAt'
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.negativeResultsList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>SEM DIREITO - AGUARDANDO COMUNICAR CLIENTE</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList08.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.negativeResultsList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList08.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluatedAt'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                      </TableCell>
                                    )
                                    : (column.id === 'client'
                                      ? (
                                        <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                          {value}
                                        </TableCell>
                                      )
                                      : (
                                        <TableCell key={column.id} align={column.align}>
                                          {value}
                                        </TableCell>
                                      )
                                    ))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.closedList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>BAIXADOS</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList09.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.closedList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList09.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluationValue'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0 ? (Number(value).toLocaleString() + (Number(value).toLocaleString().indexOf(',') === -1 ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')) : '-'}
                                      </TableCell>
                                    )
                                    : ((column.id === 'evaluatedAt' || column.id === 'kitRequestedAt' || column.id === 'kitReceivedAt' || column.id === 'distributedAt' || column.id === 'clientInformedAt')
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {reportData.coldCasesList.length > 0
          && (
            <>
              <h4 className='report9-subtitle'>
                <strong>CASOS FRIOS</strong>
              </h4>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columnsList10.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, borderBottom: '2px rgb(87, 87, 87) solid' }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.coldCasesList
                      .map((record) => (
                        <TableRow key={record.id} className='table-body'>
                          {columnsList10.map((column) => {
                            const value = record[column.id];
                            return (
                              <>
                                {column.id === 'partners'
                                  ? (
                                    <TableCell key={column.id} align={column.align}>
                                      {value.map((partner) => (<div>{partner}</div>))}
                                    </TableCell>
                                  )
                                  : (column.id === 'evaluationValue'
                                    ? (
                                      <TableCell key={column.id} align={column.align}>
                                        R$
                                        {' '}
                                        {Number(value) > 0 ? (Number(value).toLocaleString() + (Number(value).toLocaleString().indexOf(',') === -1 ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')) : '-'}
                                      </TableCell>
                                    )
                                    : ((column.id === 'evaluatedAt' || column.id === 'kitRequestedAt' || column.id === 'kitReceivedAt' || column.id === 'distributedAt' || column.id === 'clientInformedAt')
                                      ? (
                                        <TableCell key={column.id} align={column.align}>
                                          {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                      )
                                      : (column.id === 'client'
                                        ? (
                                          <TableCell key={column.id} align={column.align} onClick={() => setSelectedEvaluation({ evaluationId: record.evaluationId, workflowId: record.workflowId })}>
                                            {value}
                                          </TableCell>
                                        )
                                        : (
                                          <TableCell key={column.id} align={column.align}>
                                            {value}
                                          </TableCell>
                                        )
                                      )))}
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </Paper>
    </>
  );
}

export default Report9;
