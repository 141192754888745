import { useContext } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@mui/material';

import { AuthContext } from '../../../../contexts/auth';

import '../../../../style.css';

function ListRows({
  user, setSelectedUser, handleConfirmRemoveUser, handleConfirmActivateUser,
}) {
  const { activeUser } = useContext(AuthContext);

  return (
    <>
      <tr
        key={user.id}
        className='table-rows-component-tr'
      >
        <td
          className='table-rows-component-td table-rows-component-first-column'
          onClick={() => setSelectedUser(user)}
        >
          {user.name}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedUser(user)}
        >
          {user.email}
        </td>
        <td
          className='table-rows-component-td'
          onClick={() => setSelectedUser(user)}
        >
          {user.category}
        </td>
        <td
          className='table-rows-component-td text-center'
          onClick={() => setSelectedUser(user)}
        >
          {user.active ? 'Ativo' : 'Inativo'}
        </td>
        <td
          className='table-rows-component-buttons-cell table-rows-component-last-column'
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          {(Number(activeUser.id) !== Number(user.id) && activeUser.category === 'Sócio')
            ? (user.active ? (
              <span
                className='table-buttons'
                onClick={() => handleConfirmRemoveUser(user)}
              >
                <Tooltip title='Excluir/Inativar'>
                  <DeleteForeverIcon style={{ color: 'red' }} />
                </Tooltip>
              </span>
            ) : (
              <span
                className='table-buttons activate-button'
                onClick={() => handleConfirmActivateUser(user)}
              >
                <Tooltip title='Reativar'>
                  <ReplayIcon style={{ color: 'green' }} />
                </Tooltip>
              </span>
            ))
            : <span className='table-buttons' />}
        </td>
      </tr>
    </>
  );
}

export default ListRows;
