import { useContext } from 'react';

import { AuthContext } from '../../contexts/auth';

import logo from '../../images/logo.png';

import './style.css';

function Header({ secondaryTitle }) {
  const { activeUser } = useContext(AuthContext);

  return (
    <div className='display-header'>
      <div className='header-logo-display'>
        <img className='header-logo' src={logo} alt='Logo MAADV' />
      </div>
      <div className='header-text'>
        <div>SISTEMA DE GESTÃO</div>
        <div className='header-secondary-title'>{secondaryTitle}</div>
      </div>
      <div className='header-user-name'>
        <div>
          Bem vindo(a),
          {' '}
          <b>{activeUser.name}</b>
        </div>
      </div>
    </div>
  );
}

export default Header;
