//Fluxo produto Isenção IRPF

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  format,
  isValid,
  parse,
} from 'date-fns';

import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';
import {
  formatCaseNumber, formatDecimalBR, formatPhone, formatTaxId,
} from '../../services/format';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import Client from '../../components/Modals/Client';
import ClientQuickInfo from '../../components/Modals/ClientQuickInfo';
import Confirmation from '../../components/Modals/Confirmation';
import GrayButton from '../../components/Buttons/GrayButton';
import Header from '../../components/Header';
import RedButton from '../../components/Buttons/RedButton';

import '../../style.css';

function EvaluationWorkflow5() {
  const navigate = useNavigate();

  const { id: evaluationId } = useParams();

  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [benefitPayingEntities, setBenefitPayingEntities] = useState([]);
  const [clientLogs, setClientLogs] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [estimatedGrossCommission, setEstimatedGrossCommission] = useState(0);
  const [estimatedNetCommission, setEstimatedNetCommission] = useState(0);
  const [evaluation, setEvaluation] = useState({});
  const [evaluationDocs, setEvaluationDocs] = useState([]);
  const [evaluationDocsStatus, setEvaluationDocsStatus] = useState(0);
  const [evaluationLogs, setEvaluationLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState();
  const [newPayingEntity, setNewPayingEntity] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [openClientQuickInfo, setOpenClientQuickInfo] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [origins, setOrigins] = useState([]);
  const [partners, setPartners] = useState([]);
  const [patologies, setPatologies] = useState([]);
  const [products, setProducts] = useState([]);
  const [removeCommentConfirmation, setRemoveCommentConfirmation] = useState('');
  const [pendingSave, setPendingSave] = useState();
  const [selectedClient, setSelectedClient] = useState({});
  const [updateData, setUpdateData] = useState('');
  const [, setUpdateList] = useState(false);

  const evaluationStatus = [
    'Lead',
    'Aguardando dados da doença',
    'Aguardando documentação',
    'Aguardando entidade(s) pagadora(s)',
    'Aguardando cálculo',
    'Aguardando kit',
    'Aguardando distribuição',
    'Aguardando deferimento',
    'Concluído',
    'Baixado',
  ];

  const retrieveEvaluation = useCallback(async (id) => {
    try {
      const reqData = {
        id,
        token,
      };

      const data = await API.fetch('GET', 'evaluations', reqData);

      setEvaluation(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - retrieveEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveEvaluationDocs = useCallback(async (id) => {
    try {
      if (id) {
        const reqData = {
          id,
          token,
        };

        const data = await API.fetch('GET', 'evaluationDocs', reqData);

        setEvaluationDocs(data);

        const receivedDocs = data.filter((doc) => !!doc.docReceivedAt).length;

        setEvaluationDocsStatus(receivedDocs / data.length);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - retrieveEvaluationDocs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveBenefitPayingEntities = useCallback(async (id) => {
    try {
      if (id) {
        const reqData = {
          id,
          token,
        };

        const data = await API.fetch('GET', 'benefitPayingEntities', reqData);

        setBenefitPayingEntities(data);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - retrieveBenefitPayingEntities',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveEvaluationLogs = useCallback(async (id) => {
    try {
      if (id) {
        const reqData = {
          id,
          token,
        };

        const data = await API.fetch('GET', 'evaluationLogs', reqData);

        setEvaluationLogs(data);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - retrieveEvaluationLogs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveProducts = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'products', reqData);

      setProducts(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - retrieveProducts',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrievePatologies = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'patologies', reqData);

      setPatologies(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - retrievePatologies',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  function calculateEstimatedCommission(evaluation) {
    const {
      commissionType, commissionFee, evaluationValue, partnersCommissionDetails,
    } = evaluation;

    let formattedEvaluationValue = evaluationValue;

    if (!evaluationValue) {
      formattedEvaluationValue = 0;
    } else if (evaluationValue?.includes(',')) {
      formattedEvaluationValue = parseFloat(evaluationValue.replace(/\./g, '').replace(/,/g, '.'));
    } else if (evaluationValue?.includes('.')) {
      formattedEvaluationValue = parseFloat(evaluationValue.replace(/,/g, ''));
    }

    let calculatedCommissionFee = 0;

    if (commissionFee) {
      if (Number(commissionFee.replace(/\D+/g, ''))) {
        calculatedCommissionFee = (parseInt(commissionFee.replace(/\D+/g, ''), 10) / 100);
      }
    }

    const calculatedGrossCommission = (commissionType === 'Fixo'
      ? calculatedCommissionFee
      : (commissionType === 'Percentual'
        ? (calculatedCommissionFee / 100) * Number(formattedEvaluationValue)
        : 0)).toFixed(2);

    const formattedGrossCommission = formatDecimalBR(calculatedGrossCommission);

    setEstimatedGrossCommission(formattedGrossCommission);

    let calculatedNetCommission = 0;

    if (partnersCommissionDetails.length === 0) {
      calculatedNetCommission = Number(calculatedGrossCommission);
    } else {
      const netCommissionBasis = Number(calculatedGrossCommission);
      let partnerCommission = 0;

      partnersCommissionDetails.forEach((partner) => {
        if (partner.typeEnd === 'Percentual' && partner.calculationBasisEnd === 'Honorários finais') {
          if (partner.valueEnd) {
            if (Number(partner.valueEnd.replace(/\D+/g, ''))) {
              partnerCommission += ((parseInt(partner.valueEnd.replace(/\D+/g, ''), 10) / 100) / 100) * netCommissionBasis;
            }
          }
        }
      });

      calculatedNetCommission = (Number(calculatedGrossCommission) - partnerCommission).toFixed(2);
    }

    const formattedNetCommission = formatDecimalBR(calculatedNetCommission);

    setEstimatedNetCommission(formattedNetCommission);
  }

  useEffect(() => {
    if (evaluationId) {
      setLoading(true);

      const fetchData = async () => {
        const promise1 = retrieveEvaluation(evaluationId);
        const promise2 = retrieveEvaluationDocs(evaluationId);
        const promise3 = retrieveBenefitPayingEntities(evaluationId);
        const promise4 = retrieveEvaluationLogs(evaluationId);
        const promise5 = retrieveProducts();
        const promise6 = retrievePatologies();

        await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]);

        setLoading(false);
      };

      fetchData();
    }
  }, [retrieveEvaluation, retrieveEvaluationDocs, retrieveBenefitPayingEntities, retrieveEvaluationLogs, retrieveProducts, retrievePatologies, evaluationId]);

  useEffect(() => {
    if (evaluationId && updateData) {
      setLoading(true);

      const fetchData = async () => {
        switch (updateData) {
          case 'evaluation':
            const promise1 = retrieveEvaluation(evaluationId);
            const promise2 = retrieveEvaluationDocs(evaluationId);
            const promise3 = retrieveBenefitPayingEntities(evaluationId);
            const promise4 = retrieveEvaluationLogs(evaluationId);
            await Promise.all([promise1, promise2, promise3, promise4]);
            break;

          case 'docs':
            await retrieveEvaluationDocs(evaluationId);
            break;

          case 'benefitPayingEntities':
            await retrieveBenefitPayingEntities(evaluationId);
            break;

          case 'logs':
            await retrieveEvaluationLogs(evaluationId);
            break;

          default:
            break;
        }

        setLoading(false);
        setUpdateData(false);
      };

      fetchData();
    }
  }, [updateData, retrieveEvaluation, retrieveEvaluationDocs, retrieveBenefitPayingEntities, retrieveProducts, retrieveEvaluationLogs, evaluationId]);

  function updateEvaluationData(state, data) {
    let newState = { ...state };

    switch (data.target) {
      case 'start':
      case 'undo':
        newState = { ...evaluation };
        newState.patology = evaluation.patology || '';
        newState.patologyDescription = evaluation.patologyDescription || '';
        setPendingSave(false);
        break;

      case 'product':
        if (newState.product !== data.value) {
          const currentProduct = products.find((product) => product.product === newState.product);
          const newProduct = products.find((product) => product.product === data.value);
          if (currentProduct.productGroupId !== newProduct.productGroupId || currentProduct.workflowId !== newProduct.workflowId) {
            setAlertMessage({
              title: 'Alerta!',
              message: `Alteração do produto só é permitida dentro do mesmo grupo (${currentProduct.productGroup}).`,
            });
            setOpenAlert(true);
          } else {
            newState.product = data.value;
            if (data.value) {
              newState.productId = newProduct.id;
            } else {
              newState.productId = null;
            }
            setPendingSave(true);
          }
        }
        break;

      case 'commissionType':
        if (newState.commissionType !== data.value) {
          newState.commissionType = data.value;
          calculateEstimatedCommission(newState);
          setPendingSave(true);
        }
        break;

      case 'commissionFee':
        if (newState.commissionFee !== data.value) {
          newState.commissionFee = data.value;
          calculateEstimatedCommission(newState);
          setPendingSave(true);
        }
        break;

      case 'coldCase':
        if (newState.coldCase !== data.value) {
          newState.coldCase = data.value;
          setPendingSave(true);
        }
        break;

      case 'status':
        if (newState.status !== data.value) {
          newState.status = data.value;
          setPendingSave(true);
        }
        break;

      case 'diseaseInitialDate':
        if (new Date(newState.diseaseInitialDate) !== new Date(data.value)) {
          newState.diseaseInitialDate = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'patology':
        if (newState.patology !== data.value) {
          newState.patology = data.value || '';
          if (data.value) {
            newState.patologyId = patologies.find((patology) => patology.patology === data.value).id;
          } else {
            newState.patologyId = null;
          }
          setPendingSave(true);
        }
        break;

      case 'patologyDescription':
        if (newState.patologyDescription !== data.value) {
          newState.patologyDescription = data.value || '';
          setPendingSave(true);
        }
        break;

      case 'docsRequestedAt':
        if (new Date(newState.docsRequestedAt) !== new Date(data.value)) {
          newState.docsRequestedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'evaluatedAt':
        if (new Date(newState.evaluatedAt) !== new Date(data.value)) {
          newState.evaluatedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'evaluationValue':
        if (newState.evaluationValue !== data.value) {
          newState.evaluationValue = data.value ? formatDecimalBR(data.value) : '';
          calculateEstimatedCommission(newState);
          setPendingSave(true);
        }
        break;

      case 'kitRequestedAt':
        if (new Date(newState.kitRequestedAt) !== new Date(data.value)) {
          newState.kitRequestedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'kitReceivedAt':
        if (new Date(newState.kitReceivedAt) !== new Date(data.value)) {
          newState.kitReceivedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'distributedAt':
        if (new Date(newState.distributedAt) !== new Date(data.value)) {
          newState.distributedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'caseNumber':
        if (newState.caseNumber !== data.value) {
          newState.caseNumber = data.value ? formatCaseNumber(data.value) : '';
          setPendingSave(true);
        }
        break;

      case 'requestGrantedAt':
        if (new Date(newState.requestGrantedAt) !== new Date(data.value)) {
          newState.requestGrantedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'requestGrantingOccasion':
        if (newState.requestGrantingOccasion !== data.value) {
          newState.requestGrantingOccasion = data.value;
          setPendingSave(true);
        }
        break;

      case 'paymentReceivedAt':
        if (new Date(newState.paymentReceivedAt) !== new Date(data.value)) {
          newState.paymentReceivedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      default:
        break;
    }

    return newState;
  }

  const [evaluationData, dispatch] = useReducer(updateEvaluationData);

  function handleConfirmDeleteEvaluation() {
    setConfirmationMessage('Tem certeza de que deseja remover essa avaliação?');
    setOpenConfirmation(true);
  }

  async function updateEvaluationDocs(documentData) {
    try {
      let reqData = {}

      if (documentData.date) {
        let newDate = new Date(documentData.date);

        if (isValid(newDate)) {
          const newDateYear = newDate.getFullYear();

          if (newDateYear >= 2000 && newDateYear <= 2100) {
            newDate = format(parse(documentData.date.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');

            reqData = {
              id: documentData.id,
              body: { receivedAt: newDate },
              token,
            };
          }
        }
      } else {
        reqData = {
          id: documentData.id,
          body: { receivedAt: null },
          token,
        };
      }

      if (Object.keys(reqData).length > 0) {
        await API.fetch('PUT', 'evaluationDocs', reqData);
        setUpdateData('docs');
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - updateEvaluationDocs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function updateEvaluation() {
    try {
      const reqData = {
        id: evaluationId,
        body: {
          clientId: evaluationData.clientId,
          productId: evaluationData.productId,
          commissionType: evaluationData.commissionType,
          commissionFee: evaluationData.commissionFee ? (parseInt(evaluationData.commissionFee.replace(/\D+/g, ''), 10) / 100).toFixed(2) : null,
          diseaseInitialDate: evaluationData.diseaseInitialDate ? format(parse(evaluationData.diseaseInitialDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          patologyId: evaluationData.patologyId || null,
          patologyDescription: evaluationData.patologyDescription ? evaluationData.patologyDescription.trim() : null,
          docsRequestedAt: evaluationData.docsRequestedAt ? format(parse(evaluationData.docsRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          evaluatedAt: evaluationData.evaluatedAt ? format(parse(evaluationData.evaluatedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          evaluationValue: evaluationData.evaluationValue ? (parseInt(evaluationData.evaluationValue.replace(/\D+/g, ''), 10) / 100).toFixed(2) : null,
          kitRequestedAt: evaluationData.kitRequestedAt ? format(parse(evaluationData.kitRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          kitReceivedAt: evaluationData.kitReceivedAt ? format(parse(evaluationData.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          distributedAt: evaluationData.distributedAt ? format(parse(evaluationData.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          caseNumber: evaluationData.caseNumber ? evaluationData.caseNumber.replace(/\D+/g, '') : null,
          requestGrantedAt: evaluationData.requestGrantedAt ? format(parse(evaluationData.requestGrantedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          requestGrantingOccasion: evaluationData.requestGrantingOccasion || null,
          paymentReceivedAt: evaluationData.paymentReceivedAt ? format(parse(evaluationData.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          status: evaluationData.status,
          coldCase: evaluationData.coldCase,
        },
        token,
      };

      const update = await API.fetch('PUT', 'evaluations', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: update,
      });

      setOpenAlert(true);

      setPendingSave(false);

      setUpdateData('evaluation');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - updateEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function removeEvaluation() {
    try {
      const reqData = {
        id: evaluationId,
        token,
      };

      const update = await API.fetch('DELETE', 'evaluations', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: update,
      });

      setOpenAlert(true);

      navigate('/evaluations');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - removeEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleAddBenefitPayingEntity() {
    try {
      const reqData = {
        id: evaluation.id,
        body: {
          benefitPayingEntity: newPayingEntity.toUpperCase().trim(),
        },
        token,
      };

      await API.fetch('POST', 'benefitPayingEntities', reqData);

      setUpdateData('benefitPayingEntities');
      setNewPayingEntity('');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - handleAddBenefitPayingEntity',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRemoveBenefitPayingEntity(id) {
    try {
      const reqData = {
        id,
        token,
      };

      await API.fetch('DELETE', 'benefitPayingEntities', reqData);

      setUpdateData('benefitPayingEntities');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - handleRemoveBenefitPayingEntity',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleAddComment() {
    try {
      if (newComment.trim().length > 0 && evaluationId) {
        const commentData = {
          target: 'Avaliação',
          targetId: evaluationId,
          details: newComment.trim(),
        };

        let reqData = {
          body: commentData,
          token,
        };

        const data = await API.fetch('POST', 'logs', reqData);

        reqData = {
          id: evaluationId,
          token,
        };

        retrieveEvaluationLogs(evaluationId);

        setNewComment('');
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - handleAddComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRemoveComment(logId) {
    try {
      const reqData = {
        id: logId,
        token,
      };

      await API.fetch('DELETE', 'logs', reqData);

      const currentData = evaluationLogs.filter((comment) => comment.id !== logId);

      setEvaluationLogs(currentData);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - handleRemoveComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleOpenClientModal(clientData) {
    try {
      const phones = [];
      if (clientData.phone1) {
        phones.push(formatPhone(clientData.phone1));
      }
      if (clientData.phone2) {
        phones.push(formatPhone(clientData.phone2));
      }
      if (clientData.phone3) {
        phones.push(formatPhone(clientData.phone3));
      }
      if (clientData.phone4) {
        phones.push(formatPhone(clientData.phone4));
      }
      if (clientData.phone5) {
        phones.push(formatPhone(clientData.phone5));
      }
      clientData.phones = phones;

      const partners = [];
      if (clientData.partnerCode1) {
        partners.push(clientData.partnerCode1);
      }
      if (clientData.partnerCode2) {
        partners.push(clientData.partnerCode2);
      }
      if (clientData.partnerCode3) {
        partners.push(clientData.partnerCode3);
      }
      clientData.partners = partners;

      let location = '';
      if (clientData.city && clientData.state) {
        location = `${clientData.city}/${clientData.state}`;
      } else if (clientData.city && !clientData.state) {
        location = clientData.city;
      } else if (!clientData.city && clientData.state) {
        location = clientData.state;
      }
      clientData.location = location;

      setSelectedClient(clientData);

      const reqData = { token };

      let data = await API.fetch('GET', 'origins', reqData);
      setOrigins(data);

      data = await API.fetch('GET', 'partners', reqData);
      setPartners(data);

      reqData.id = evaluationData.clientId;
      data = await API.fetch('GET', 'clientLogs', reqData);
      setClientLogs(data);

      setOpenClientModal(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - handleOpenClientModal',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleClientDetails(action) {
    try {
      const reqData = {
        id: evaluationData.clientId,
        token,
      };

      const data = await API.fetch('GET', 'clients', reqData);

      setSelectedClient(data);

      if (action === 'view') {
        setSelectedClient(data);
        setOpenClientQuickInfo(true);
      } else {
        handleOpenClientModal(data);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow5/index.js - handleClientDetails',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  function formatLogRecord(log) {
    return `[${format(Date.parse(log.dateTime), 'dd/MM/yyyy HH:mm:ss')} - ${log.user}]: ${log.details}`;
  }

  useEffect(() => {
    if (evaluation?.id) {
      dispatch({ value: null, target: 'start' });
      calculateEstimatedCommission(evaluation);
    }

    setLoading(false);
  }, [evaluation]);

  function step1Color() {
    return (!evaluationData?.diseaseInitialDate && !evaluationData?.patology && !evaluationData?.patologyDescription)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.diseaseInitialDate && evaluationData?.patology)
        ? '#3c56aa'
        : 'red');
  }

  function step2Color() {
    return (!evaluationData?.docsRequestedAt && !evaluationDocsStatus)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.docsRequestedAt && evaluationDocsStatus === 1)
        ? '#3c56aa'
        : 'red');
  }

  function step3Color() {
    return (benefitPayingEntities.length === 0)
      ? 'rgba(0, 0, 0, 0.38)'
      : '#3c56aa';
  }

  function step4Color() {
    return (!evaluationData?.evaluatedAt && !evaluationData?.evaluationValue)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.evaluatedAt && evaluationData?.evaluationValue)
        ? '#3c56aa'
        : 'red');
  }

  function step5Color() {
    return (!evaluationData?.kitRequestedAt && !evaluationData?.kitReceivedAt)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.kitRequestedAt && evaluationData?.kitReceivedAt)
        ? '#3c56aa'
        : 'red');
  }

  function step6Color() {
    return (!evaluationData?.distributedAt && !evaluationData?.caseNumber)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.distributedAt && evaluationData?.caseNumber)
        ? '#3c56aa'
        : 'red');
  }

  function step7Color() {
    return (!evaluationData?.requestGrantedAt && !evaluationData?.requestGrantingOccasion)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.requestGrantedAt && evaluationData?.requestGrantingOccasion)
        ? '#3c56aa'
        : 'red');
  }

  function step8Color() {
    return (!evaluationData?.paymentReceivedAt)
      ? 'rgba(0, 0, 0, 0.38)'
      : '#3c56aa';
  }

  useEffect(() => {
    if (confirmation) {
      removeEvaluation();
      setConfirmation(false);
    }
  }, [confirmation, removeEvaluation]);

  return (
    <div className='page-container'>
      <Header secondaryTitle={`AVALIAÇÃO - ${evaluationData?.client}`} />
      <div className='page-body-container'>
        <div
          className='page-top-menu-area'
          style={{ justifyContent: 'flex-end' }}
        >
          <div style={{
            display: 'flex',
            columnGap: 10,
          }}
          >
            {pendingSave && (
              <>
                <div onClick={() => dispatch({ value: null, target: 'undo' })}>
                  <GrayButton
                    buttonText='Desfazer Alterações'
                    buttonWidth={160}
                  />
                </div>
                <div onClick={updateEvaluation}>
                  <BlueButton
                    buttonText='Salvar Alterações'
                    buttonWidth={140}
                  />
                </div>
              </>
            )}
            {!pendingSave && (
              <div onClick={() => navigate('/evaluations')}>
                <BlueButton
                  buttonText='Voltar'
                  buttonWidth={70}
                />
              </div>
            )}
          </div>
        </div>
        <div className='page-form-area'>
          {!loading && (
            <div className='form-container'>
              <div className='form-header'>
                <div className='form-header-row'>
                  <div style={{
                    width: '60%',
                    minWidth: '200px',
                    maxWidth: '372px',
                  }}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    >
                      <label
                        className='form-label link'
                        onClick={() => handleClientDetails('view')}
                      >
                        Cliente
                      </label>
                      <span
                        className='edit-icon'
                        onClick={() => handleClientDetails('edit')}
                        style={{ height: '16px' }}
                      >
                        <EditIcon
                          fontSize='small'
                          color='primary'
                          style={{
                            height: '16px',
                            margin: '-6px 0 6px 0',
                          }}
                        />
                      </span>
                    </div>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.client}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '110px',
                    minWidth: '110px',
                  }}
                  >
                    <label className='form-label'>
                      CPF
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      style={{ textAlign: 'center' }}
                      value={formatTaxId(evaluationData?.taxId, 'cpf')}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label className='form-label'>
                      Parceiros
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.partner1}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label
                      className='form-label'
                      style={{ height: '18px' }}
                    />
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.partner2}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label
                      className='form-label'
                      style={{ height: '18px' }}
                    />
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.partner3}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '240px',
                    minWidth: '240px',
                    marginRight: 'max(auto, 8px)',
                  }}
                  >
                    <label className='form-label'>
                      Origem
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.origin}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '90px',
                    minWidth: '90px',
                  }}
                  >
                    <label className='form-label'>
                      DIB
                    </label>
                    <div className='evaluations-row1'>
                      <input
                        style={{ textAlign: 'center' }}
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.retirementBenefitDate
                          ? format(parse(evaluationData?.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
                          : '-'}
                        disabled
                      />
                    </div>
                  </div>
                  <div style={{
                    width: '150px',
                    minWidth: '150px',
                  }}
                  >
                    <label className='form-label'>
                      Prazo Decadência
                    </label>
                    <div>
                      <input
                        style={{
                          color: (evaluationData?.deadlineAlert && 'red'),
                          textAlign: 'center',
                        }}
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.deadline}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className='form-header-row'>
                  <div style={{
                    width: '60%',
                    minWidth: '200px',
                    maxWidth: '372px',
                  }}
                  >
                    <label className='form-label'>
                      Produto
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={evaluationData?.product}
                      onChange={(e) => dispatch({ value: e.target.value, target: 'product' })}
                    >
                      <option />
                      {products.map((item) => (
                        <option>{item.product}</option>
                      ))}
                    </select>
                  </div>
                  <div style={{
                    width: '110px',
                    minWidth: '110px',
                  }}
                  >
                    <label className='form-label'>
                      Tipo Honorário
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={evaluationData?.commissionType}
                      onChange={(e) => dispatch({ value: e.target.value, target: 'commissionType' })}
                    >
                      <option />
                      <option>Fixo</option>
                      <option>Percentual</option>
                    </select>
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label className='form-label'>
                      {evaluationData?.commissionType === 'Fixo'
                        ? 'Valor Honorário'
                        : (evaluationData?.commissionType === 'Percentual'
                          ? 'Alíquota Honorário'
                          : 'Honorário')}
                    </label>
                    <div>
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='6'
                        style={{
                          padding: '0 10%',
                          textAlign: (evaluationData?.commissionType === 'Fixo' ? 'right' : 'center'),
                        }}
                        value={evaluationData?.commissionFee ? formatDecimalBR(evaluationData?.commissionFee) : ''}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'commissionFee' })}
                      />
                      {evaluationData?.commissionType === 'Fixo' && (
                        <span style={{
                          marginLeft: '-95%',
                          alignSelf: 'center',
                          fontSize: '13px',
                        }}
                        >
                          R$
                        </span>
                      )}
                      {evaluationData?.commissionType === 'Percentual' && (
                        <span style={{
                          marginLeft: '-20%',
                          alignSelf: 'center',
                          fontSize: '13px',
                        }}
                        >
                          %
                        </span>
                      )}
                    </div>
                  </div>
                  {(activeUser.category === 'Sócio' || activeUser.category === 'Financeiro') && (
                    <div style={{
                      width: '105px',
                      minWidth: '105px',
                    }}
                    >
                      <label className='form-label'>
                        Estimado Bruto
                      </label>
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        style={{
                          padding: '0 10%',
                          textAlign: 'right',
                        }}
                        value={estimatedGrossCommission}
                        disabled
                      />
                      <span style={{
                        marginLeft: '-95%',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        R$
                      </span>
                    </div>
                  )}
                  {(activeUser.category === 'Sócio' || activeUser.category === 'Financeiro') && (
                    <div style={{
                      width: '105px',
                      minWidth: '105px',
                    }}
                    >
                      <label className='form-label'>
                        Estimado Líquido
                      </label>
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        style={{
                          padding: '0 10%',
                          textAlign: 'right',
                        }}
                        value={estimatedNetCommission}
                        disabled
                      />
                      <span style={{
                        marginLeft: '-95%',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        R$
                      </span>
                    </div>
                  )}
                  <div style={{
                    width: '240px',
                    minWidth: '240px',
                    marginRight: 'max(auto, 8px)',
                  }}
                  >
                    <label className='form-label'>
                      Caso Frio
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='checkbox'
                      style={{
                        height: 15,
                        width: 15,
                        marginRight: 0,
                        margin: '8px 0 0 0',
                      }}
                      checked={evaluationData?.coldCase}
                      onChange={(e) => dispatch({ value: e.target.checked, target: 'coldCase' })}
                    />
                  </div>
                  <div style={{
                    width: '248px',
                    minWidth: '248px',
                  }}
                  >
                    <label className='form-label'>
                      Etapa
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={evaluationData?.status}
                      onChange={(e) => dispatch({ value: e.target.value, target: 'status' })}
                    >
                      <option />
                      {evaluationStatus.map((status) => (
                        <option>{status}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='horizontal-separator' />

              <div className='form-data-container'>
                <div className='form-data-container-data'>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step1Color() }}
                      >
                        1
                      </span>
                      IDENTIFICAÇÃO DA DOENÇA
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        DID
                      </label>
                      <input
                        className='date-input distribution-date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.diseaseInitialDate ? format(parse(evaluationData?.diseaseInitialDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'diseaseInitialDate' })}
                      />
                      {!evaluationData?.diseaseInitialDate ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'diseaseInitialDate' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'diseaseInitialDate' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        Patologia
                      </label>
                      <select
                        className='input'
                        style={{ width: 180 }}
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.patology}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'patology' })}
                      >
                        <option />
                        {patologies.map((patology) => (
                          <option>{patology.patology}</option>
                        ))}
                      </select>
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Descrição/Detalhamento
                      </label>
                    </div>
                    <div className='form-data-container-card-row'>
                      <textarea
                        className='text-area-input'
                        style={{ height: 122 }}
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.patologyDescription}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'patologyDescription' })}
                      />
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step2Color() }}
                      >
                        2
                      </span>
                      DOCUMENTAÇÃO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label docs-label-column'>
                        Solicitação
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.docsRequestedAt ? format(parse(evaluationData?.docsRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'docsRequestedAt' })}
                      />
                      {!evaluationData?.docsRequestedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'docsRequestedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',

                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'docsRequestedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-horizontal-separator' />
                    {evaluationDocs.map((doc) => (
                      <div
                        key={doc?.id}
                        className='form-data-container-card-row'
                      >
                        <label className='form-label docs-label-column'>
                          {doc?.doc}
                        </label>
                        <input
                          className='date-input'
                          variant='outlined'
                          size='small'
                          type='date'
                          min='2000-01-01'
                          max='2100-12-31'
                          value={doc?.docReceivedAt}
                          onChange={(e) => updateEvaluationDocs({ id: doc?.id, date: e.target.value })}
                        />
                        {!doc?.docReceivedAt ? (
                          <RadioButtonUncheckedIcon
                            style={{
                              height: 30,
                              width: 30,
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            onClick={() => updateEvaluationDocs({ id: doc?.id, date: format(new Date(), 'yyyy-MM-dd') })}
                          />
                        ) : (
                          <CheckCircleOutlineRoundedIcon
                            style={{
                              height: 30,
                              width: 30,
                              color: '#171f48',
                              cursor: 'pointer',
                            }}
                            onClick={() => updateEvaluationDocs({ id: doc?.id, date: null })}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  <div
                    className='form-data-container-card-workflow-5'
                    style={{ width: 316 }}
                  >
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step3Color() }}
                      >
                        3
                      </span>
                      ENTIDADE PAGADORA
                    </div>
                    <label className='form-label distribution-label-column'>
                      Nova entidade
                    </label>
                    <div
                      className='form-data-container-card-row'
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <div className='evaluation-new-comment'>
                        <input
                          className='log-input'
                          maxLength={50}
                          style={{ padding: '0 8px' }}
                          value={newPayingEntity}
                          onChange={(e) => setNewPayingEntity(e.target.value.toUpperCase())}
                        />
                        {newPayingEntity && (
                          <AddIcon
                            onClick={handleAddBenefitPayingEntity}
                            style={{
                              cursor: 'pointer',
                              paddingRight: '5px',
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {benefitPayingEntities.map((entity, index) => (
                      <div
                        key={entity?.id}
                        className='form-data-container-card-row'
                      >
                        <label className='form-label'>
                          {`${index + 1}. ${entity?.benefitPayingEntity}`}
                        </label>
                        <DeleteForeverIcon
                          onClick={() => handleRemoveBenefitPayingEntity(entity?.id)}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                          }}
                        />
                      </div>
                    ))}
                  </div>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step4Color() }}
                      >
                        4
                      </span>
                      CÁLCULO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Avaliação
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.evaluatedAt ? format(parse(evaluationData?.evaluatedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'evaluatedAt' })}
                      />
                      {!evaluationData?.evaluatedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'evaluatedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'evaluatedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Valor
                      </label>
                      <span style={{
                        margin: 0,
                        transform: 'translateX(28px)',
                        fontSize: '13px',
                        zIndex: 2,
                      }}
                      >
                        R$
                      </span>
                      <input
                        className='monetary-value-input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='14'
                        value={evaluationData?.evaluationValue ? formatDecimalBR(evaluationData?.evaluationValue) : ''}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'evaluationValue' })}
                      />
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step5Color() }}
                      >
                        5
                      </span>
                      KIT
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label kit-label-column'>
                        Solicitação
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.kitRequestedAt ? format(parse(evaluationData?.kitRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'kitRequestedAt' })}
                      />
                      {!evaluationData?.kitRequestedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'kitRequestedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'kitRequestedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label kit-label-column'>
                        Recebimento
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.kitReceivedAt ? format(parse(evaluationData?.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'kitReceivedAt' })}
                      />
                      {!evaluationData?.kitReceivedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'kitReceivedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'kitReceivedAt' })}
                        />
                      )}
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step6Color() }}
                      >
                        6
                      </span>
                      DISTRIBUIÇÃO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        Distribuição
                      </label>
                      <input
                        className='date-input distribution-date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.distributedAt ? format(parse(evaluationData?.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'distributedAt' })}
                      />
                      {!evaluationData?.distributedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'distributedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'distributedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        Processo
                      </label>
                      <input
                        className='case-number-input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='25'
                        value={evaluationData?.caseNumber ? formatCaseNumber(evaluationData?.caseNumber) : ''}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'caseNumber' })}
                      />
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step7Color() }}
                      >
                        7
                      </span>
                      DEFERIMENTO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Deferimento
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.requestGrantedAt ? format(parse(evaluationData?.requestGrantedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'requestGrantedAt' })}
                      />
                      {!evaluationData?.requestGrantedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'requestGrantedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'requestGrantedAt' })}
                        />
                      )}
                    </div>
                    <div
                      className='form-data-container-card-row'
                      style={{ marginTop: 12 }}
                    >
                      <input
                        type='radio'
                        name='requestGrantingOccasion'
                        value='Sentença'
                        checked={evaluationData?.requestGrantingOccasion === 'Sentença'}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'requestGrantingOccasion' })}
                      />
                      <label className='form-label input-label'>
                        Sentença
                      </label>
                      <input
                        type='radio'
                        name='requestGrantingOccasion'
                        value='Tutela'
                        checked={evaluationData?.requestGrantingOccasion === 'Tutela'}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'requestGrantingOccasion' })}
                      />
                      <label className='form-label input-label'>
                        Tutela
                      </label>
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-5'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step8Color() }}
                      >
                        8
                      </span>
                      RECEBIMENTO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Recebimento
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.paymentReceivedAt ? format(parse(evaluationData?.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'paymentReceivedAt' })}
                      />
                      {!evaluationData?.paymentReceivedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'paymentReceivedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'paymentReceivedAt' })}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className='vertical-separator' />

                <div className='form-data-container-logs'>
                  <label className='form-label'>
                    Comentários e logs de sistema
                  </label>
                  <div className='evaluation-new-comment'>
                    <input
                      className='log-input'
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                    />
                    <AddIcon
                      onClick={handleAddComment}
                      style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                      }}
                    />
                  </div>
                  <div className='evaluations-log-field'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {evaluationLogs.map((log) => (
                        <div
                          className='log-row'
                          style={{ borderBottom: '1px gray solid' }}
                        >
                          {formatLogRecord}
                          {log.type === 'Comentário'
                            ? <strong><span>{formatLogRecord(log)}</span></strong>
                            : <span>{formatLogRecord(log)}</span>}
                          {removeCommentConfirmation === log.id && (
                            <span style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              gap: 10,
                              alignItems: 'center',
                            }}
                            >
                              <span style={{
                                color: 'red',
                                width: '60px',
                              }}
                              >
                                <b>Confirma a exclusão?</b>
                              </span>
                              <CheckCircleOutlineIcon
                                onClick={() => handleRemoveComment(log.id)}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                  color: 'green',
                                  backgroundColor: 'rgb(240, 240, 240)',
                                }}
                              />
                              <HighlightOffIcon
                                onClick={() => setRemoveCommentConfirmation('')}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                  color: 'red',
                                  backgroundColor: 'rgb(240, 240, 240)',
                                }}
                              />
                            </span>
                          )}
                          {(log.type === 'Comentário' && removeCommentConfirmation !== log.id) && (
                            <DeleteForeverIcon
                              onClick={() => setRemoveCommentConfirmation(log.id)}
                              style={{
                                cursor: 'pointer',
                                paddingRight: '5px',
                                color: 'red',
                                backgroundColor: 'rgb(240, 240, 240)',
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 8,
                    }}
                    onClick={handleConfirmDeleteEvaluation}
                  >
                    <RedButton
                      buttonText='Excluir Avaliação'
                      buttonWidth={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openClientModal && (
        <Client
          openModal={openClientModal}
          setOpenModal={setOpenClientModal}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          clientLogs={clientLogs}
          setClientLogs={setClientLogs}
          setUpdateList={setUpdateList}
          auxiliaryLists={{
            originsList: origins,
            partnersList: partners,
          }}
        />
      )}
      {openClientQuickInfo && (
        <ClientQuickInfo
          openClientQuickInfo={openClientQuickInfo}
          setOpenClientQuickInfo={setOpenClientQuickInfo}
          clientQuickInfoData={selectedClient}
          setClientQuickInfoData={setSelectedClient}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
    </div>
  );
}

export default EvaluationWorkflow5;
