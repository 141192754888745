import './style.css';

function YellowButton({ buttonText, buttonWidth }) {
  return (
    <>
      <input type='button' className='yellow-button' value={buttonText} style={{ width: buttonWidth }} />
    </>
  );
}

export default YellowButton;
