import './style.css';

function Card({
  cardText, cardWidth, cardMinWidth, cardHeight, cardColor, cardBorder, justifyContent, marginRight,
}) {
  return (
    <>
      <input
        type='button'
        className='dashboard-card'
        value={cardText}
        style={{
          width: cardWidth,
          height: cardHeight,
          minWidth: cardMinWidth,
          backgroundColor: cardColor,
          color: cardColor === '#FFF' ? '#171f48' : '#FFF',
          border: cardBorder,
          marginRight,
          justifyContent,
        }}
      />
    </>
  );
}

export default Card;
