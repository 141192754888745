import { useContext } from 'react';

import {
  Pagination,
  PaginationItem,
} from '@mui/material';

import { PaginationContext } from '../../contexts/pagination';

import './style.css';

function TablePagination({ array, currentPage, setCurrentPage }) {
  const { totalPages } = useContext(PaginationContext);

  const maxPage = totalPages(array);

  function handleChangePage(e, p) {
    setCurrentPage(p);
  }

  if (maxPage === 1) {
    return <></>;
  }

  return (
    <div className='table-pagination'>
      <Pagination
        size='small'
        count={maxPage}
        page={currentPage}
        shape='rounded'
        onChange={handleChangePage}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            style={{
              backgroundColor: item.selected ? '#171f48' : '#FFF',
              border: '1px solid #171f48',
              color: item.selected ? '#fff' : '#171f48',
            }}
          />
        )}
      />
    </div>
  );
}

export default TablePagination;
