import {
  useEffect,
  useState,
} from 'react';

import {
  Popover,
  Radio,
} from 'antd';

import {
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';

import BlueButton from '../../Buttons/BlueButton';

import './style.css';

function RadioFilter({
  applyFilters, filterOptions, column, filter, active,
}) {
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    const recoveredFilters = JSON.parse(sessionStorage.getItem('MAADVEvaluationFilters'));
    if (recoveredFilters) {
      const checked = {};

      filterOptions.forEach((filterItem) => {
        if (recoveredFilters[column]?.includes(filterItem)) {
          checked[filterItem] = true;
        }
      });

      setCheckedItems(checked);
    }
  }, []);

  function handleUndoFilter() {
    const updatedFilter = { ...filter.current };
    delete updatedFilter[column];
    setCheckedItems({});
    filter.current = { ...updatedFilter };
    applyFilters();
  }

  function handleChange(e) {
    const selectedValue = e.target.value;
    const updatedFilter = { ...filter.current };

    filter.current = { ...updatedFilter, [column]: selectedValue };

    const checked = {};
    filterOptions.forEach((item) => {
      if (filter.current[column] && filter.current[column].includes(item)) {
        checked[item] = true;
      }
    });

    setCheckedItems(checked);

    applyFilters();
  }

  const content = (
    <div className='content-radio-filter'>
      <div className='options-radio-filter'>
        {filterOptions.map((item) => (
          <span
            key={item}
            style={{ marginBottom: '5px' }}
          >
            <Radio.Group
              value={checkedItems[item] ? item : null}
              onChange={handleChange}
            >
              <Radio value={item} />
            </Radio.Group>
            <span
              style={{ marginLeft: '5px' }}
            >
              {item}
            </span>
          </span>
        ))}
      </div>
      {active && (
        <div
          className='undo-filter-radio-filter'
          onClick={handleUndoFilter}
        >
          <BlueButton buttonText='Limpar filtro' />
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Popover
        placement='bottom'
        content={content}
        trigger='click'
      >
        {active ? (
          <FilterFilled style={{ cursor: 'pointer' }} />
        ) : (
          <FilterOutlined style={{ cursor: 'pointer' }} />
        )}
      </Popover>
    </div>
  );
}

export default RadioFilter;
