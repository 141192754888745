import { forwardRef } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function Confirmation({
  openConfirmation, setOpenConfirmation, confirmationMessage, setConfirmationMessage, setConfirmation,
}) {
  function handleCloseConfirmation() {
    setOpenConfirmation(false);
    setConfirmationMessage('');
  }

  function handleConfirmation() {
    setConfirmation(true);
    handleCloseConfirmation();
  }

  return (
    <div style={{ zIndex: '9999' }}>
      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseConfirmation}
      >
        <div className='dialog-title'>
          Confirmação
        </div>
        <DialogContent>
          <DialogContent>
            <div>{confirmationMessage}</div>
          </DialogContent>
        </DialogContent>
        <div
          className='dialog-actions'
          style={{
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <DialogActions sx={{ padding: '20px' }}>
            <div onClick={handleCloseConfirmation}>
              <GrayButton
                buttonText='Cancelar'
                buttonWidth='90px'
              />
            </div>
            <div onClick={handleConfirmation}>
              <BlueButton
                className='recover-button'
                buttonText='Confirmar'
              />
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default Confirmation;
