// Manter import { Chart as ChartJS } from 'chart.js/auto';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

import './style.css';

function BarChart({ chartData }) {
  const data = {
    labels: chartData.map((item) => item.period),
    datasets: [{
      label: 'Valor dos cálculos',
      data: chartData.map((item) => item.value),
      backgroundColor: '#171f48',
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `R$ ${Number(value).toLocaleString()}`,
          font: {
            size: 12,
            weight: '600',
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 12,
            weight: '600',
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'CASOS FECHADOS* - CÁLCULOS ACUMULADOS',
        font: {
          size: 24,
          weight: '600',
        },
        color: '#4E5560',
        padding: 16,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const datasetLabel = context.dataset.label || '';
            const value = context.dataset.data[context.dataIndex];
            return ` ${datasetLabel}: R$ ${Number(value).toLocaleString() + ((Number(value).toLocaleString().indexOf(',') === -1) ? ',00' : (Number(value).toLocaleString().length - (Number(value).toLocaleString().indexOf(',') + 1) === 1) ? '0' : '')}`;
          },
        },
        bodyFont: {
          size: 14,
        },
        titleColor: '#FFFFFF',
        bodyColor: '#FFFFFF',
      },
    },
  };

  return (
    <div className='dashboard-bar-chart'>
      <Bar
        data={data}
        options={options}
      />
    </div>
  );
}

export default BarChart;
