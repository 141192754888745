import { parse } from 'date-fns';

class FilterFunctions {
  filterArrays(data, item) {
    return !data
      || data.length === 0
      || item.some((record) => data.includes(record));
  }

  filterData(data, item) {
    return !data
      || data.length === 0
      || data.includes(item);
  }

  filterDate(period, startDate, endDate) {
    return (!period?.startDate
      || parse(startDate?.substring(0, 10), 'yyyy-MM-dd', new Date()) >= parse(period.startDate, 'yyyy-MM-dd', new Date()))
      && (!period?.endDate
        || parse(endDate?.substring(0, 10), 'yyyy-MM-dd', new Date()) <= parse(period.endDate, 'yyyy-MM-dd', new Date()));
  }

  filterText(text, item) {
    return !text
      || text.length === 0
      || item?.toLowerCase().includes(text.toLowerCase());
  }
}

const Filters = new FilterFunctions();

export default Filters;
