import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import {
  format,
  parse,
} from 'date-fns';
import { formatTaxId } from '../../services/format';

import './style.css';

function SearchField({
  target, originalList, setCurrentList, selectedFilter,
}) {
  const [search, setSearch] = useState('');

  const transactionsList = useRef(originalList);

  useEffect(() => {
    setSearch('');
  }, [originalList]);

  const filterClients = useCallback(() => {
    const filter = [];

    originalList.forEach((item) => {
      let counter = 0;
      if (item.name.toLowerCase().includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.taxId) {
        if (formatTaxId(item.taxId, 'cpf').includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.phones) {
        item.phones.forEach((phone) => {
          if (phone.replace(' ', '').includes(search.trim().toLowerCase())) {
            counter++;
          }
        });
      }
      if (item.email) {
        if (item.email.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.location) {
        if (item.location.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.origin.toLowerCase().includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.partners) {
        item.partners.forEach((partner) => {
          if (partner.toLowerCase().includes(search.trim().toLowerCase())) {
            counter++;
          }
        });
      }
      if (item.retirementBenefitDate) {
        if (format(parse(item.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy').includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.products) {
        item.products.forEach((product) => {
          if (product.product.toLowerCase().includes(search.trim().toLowerCase())) {
            counter++;
          }
        });
      }
      if (counter > 0) {
        filter.push(item);
      }
    });
    setCurrentList(filter);
  }, [originalList, search, setCurrentList]);

  const filterOrigins = useCallback(() => {
    const filter = [];
    originalList.forEach((item) => {
      if (item.origin.toLowerCase().includes(search.trim().toLowerCase())) {
        filter.push(item);
      } else if (item.description) {
        if (item.description.toLowerCase().includes(search.trim().toLowerCase())) {
          filter.push(item);
        }
      }
    });
    setCurrentList(filter);
  }, [originalList, search, setCurrentList]);

  const filterPartners = useCallback(() => {
    const filter = [];

    originalList.forEach((item) => {
      let counter = 0;
      if (item.name.toLowerCase().includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.partnerCode.toLowerCase().includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.taxId) {
        if (item.taxId.length === 11) {
          if (formatTaxId(item.taxId, 'cpf').includes(search.trim().toLowerCase())) {
            counter++;
          }
        } else if (formatTaxId(item.taxId, 'cnpj').includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.phones) {
        item.phones.forEach((phone) => {
          if (phone.replace(' ', '').includes(search.trim().toLowerCase())) {
            counter++;
          }
        });
      }
      if (item.email) {
        if (item.email.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.location) {
        if (item.location.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (counter > 0) {
        filter.push(item);
      }
    });
    setCurrentList(filter);
  }, [originalList, search, setCurrentList]);

  const filterProducts = useCallback(() => {
    const filter = [];
    originalList.forEach((item) => {
      if (item.product.toLowerCase().includes(search.trim().toLowerCase())) {
        filter.push(item);
      }
    });
    setCurrentList(filter);
  }, [originalList, search, setCurrentList]);

  const filterTransactions = useCallback(() => {
    const filter = [];

    transactionsList.current.forEach((item) => {
      let counter = 0;

      if (format(parse(item.transactionDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy').includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.transactionValue.toLowerCase().includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.transactionType.toLowerCase().includes(search.trim().toLowerCase())) {
        counter++;
      }
      if (item.transactionCategory) {
        if (item.transactionCategory.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.origin) {
        if (item.origin.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.partnerCode) {
        if (item.partnerCode.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.details) {
        if (item.details.toLowerCase().includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (item.completedAt) {
        if (format(parse(item.completedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy').includes(search.trim().toLowerCase())) {
          counter++;
        }
      }
      if (counter > 0) {
        filter.push(item);
      }
    });
    setCurrentList(filter);
  }, [transactionsList, search, setCurrentList]);

  useEffect(() => {
    if (target === 'Financeiro') {
      transactionsList.current = originalList;
      switch (selectedFilter) {
        case 'Pagamento':
          transactionsList.current = originalList.filter((transaction) => (transaction.transactionType === 'Pagamento'));
          break;

        case 'Valor Devido':
          transactionsList.current = originalList.filter((transaction) => (transaction.transactionType === 'Valor devido'));
          break;

        default:
          break;
      }
    }

    if (search !== undefined) {
      if (search.trim().length > 0) {
        switch (target) {
          case 'Clientes':
            filterClients();
            break;
          case 'Financeiro':
            filterTransactions();
            break;
          case 'Origens':
            filterOrigins();
            break;
          case 'Parceiros':
            filterPartners();
            break;
          case 'Produtos':
            filterProducts();
            break;
          default:
            break;
        }
      } else {
        setCurrentList(originalList);
      }
    } else {
      setCurrentList(originalList);
    }
  }, [search, filterClients, filterOrigins, filterPartners, filterProducts, filterTransactions, originalList, setCurrentList, target, selectedFilter]);

  return (
    <>
      <div className='search-filter'>
        {/* hidden dummy inputs to be included in forms in order to remove login/password autofill */}
        <div className='hidden'>
          <input className='input' style={{ width: '0' }} type='text' autoComplete='username' />
          <input className='input' style={{ width: '0' }} type='email' autoComplete='email' />
          <input className='input' style={{ width: '0' }} type='password' autoComplete='current-password' />
        </div>
        <InputAdornment position='start' style={{ marginLeft: '20px', height: '35px', marginBottom: '-10px' }}>
          <SearchIcon />
        </InputAdornment>
        <input
          className='input search-input'
          autoComplete='off'
          variant='outlined'
          size='small'
          type='text'
          value={search}
          style={{ height: '35px' }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </>
  );
}

export default SearchField;
