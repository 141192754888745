import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { differenceInCalendarDays } from 'date-fns';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';
import {
  formatPhone,
  formatTaxId,
} from '../../services/format';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import DrawerMenu from '../../components/DrawerMenu';
import Evaluation from '../../components/Modals/Evaluation';
import EvaluationsList from '../../components/Lists/Evaluations';
import Header from '../../components/Header';

import '../../style.css';

function Evaluations() {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [clients, setClients] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [updateList, setUpdateList] = useState(false);

  const retrieveEvaluations = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'evaluations', reqData);

      data.forEach((item) => {
        item.taxId = formatTaxId(item.taxId, 'cpf');

        const phones = [];
        if (item.phone1) {
          phones.push(formatPhone(item.phone1));
        }
        if (item.phone2) {
          phones.push(formatPhone(item.phone2));
        }
        if (item.phone3) {
          phones.push(formatPhone(item.phone3));
        }
        if (item.phone4) {
          phones.push(formatPhone(item.phone4));
        }
        if (item.phone5) {
          phones.push(formatPhone(item.phone5));
        }
        item.phones = phones;

        const partners = [];
        if (item.partner1) {
          partners.push(item.partner1);
        }
        if (item.partner2) {
          partners.push(item.partner2);
        }
        if (item.partner3) {
          partners.push(item.partner3);
        }
        item.partners = partners;

        if (item.coldCase) {
          item.completeStatus = `${item.status} (Casos frios)`;
        } else {
          item.completeStatus = item.status;
        }

        if (item.retirementBenefitDate) {
          item.deadline = (10 * 365) - differenceInCalendarDays(new Date(), Date.parse(item.retirementBenefitDate));
        } else {
          item.deadline = '-';
        }

        if (item.deadline !== '-' && item.deadline <= 90) {
          item.deadlineAlert = true;
        } else {
          item.deadlineAlert = false;
        }

        if (item.deadline !== '-') {
          let deadlineText = (item.deadline > 0 ? 'Prazo de ' : 'Vencido há ');
          let formattedDeadline = Math.abs(item.deadline);
          if (formattedDeadline > 365) {
            formattedDeadline = `${(formattedDeadline / 365).toFixed(1).replace('.', ',')} anos`;
          } else if (formattedDeadline > 30) {
            formattedDeadline = `${(formattedDeadline / 30).toFixed(1).replace('.', ',')} meses`;
          } else {
            formattedDeadline += ' dias';
          }
          deadlineText += formattedDeadline;
          item.deadline = deadlineText;
        }
      });

      setEvaluations(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Evaluations/index.js - retrieveEvaluations',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveClients = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'clients', reqData);

      setClients(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Evaluations/index.js - retrieveClients',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveProducts = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'products', reqData);

      setProducts(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Evaluations/index.js - retrieveProducts',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  async function handleNewEvaluation() {
    const promise1 = retrieveClients();
    const promise2 = retrieveProducts();

    await Promise.all([promise1, promise2]);

    setOpenModal(true);
  }

  useEffect(() => {
    retrieveEvaluations();
  }, [retrieveEvaluations]);

  useEffect(() => {
    if (updateList) {
      retrieveEvaluations();
      setUpdateList(false);
    }
  }, [updateList, retrieveEvaluations]);

  return (
    <div className='page-container'>
      <Header secondaryTitle='AVALIAÇÕES' />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
          <div onClick={handleNewEvaluation}>
            <BlueButton buttonText='Nova   +' />
          </div>
        </div>
        <div className='page-table-area'>
          {evaluations.length > 0 && (
            <EvaluationsList
              evaluations={evaluations}
              setUpdateList={setUpdateList}
            />
          )}
        </div>
      </div>
      {openModal && (
        <Evaluation
          openModal={openModal}
          setOpenModal={setOpenModal}
          clients={clients}
          products={products}
          evaluations={evaluations}
          setUpdateList={setUpdateList}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default Evaluations;
