import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';
import { formatPhone } from '../../services/format';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';
import Partner from '../../components/Modals/Partner';
import PartnersList from '../../components/Lists/Partners';

import '../../style.css';

function Partners() {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [partnerLogs, setPartnerLogs] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState();
  const [updateList, setUpdateList] = useState(false);

  const retrievePartners = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'partners', reqData);

      data.forEach((item) => {
        const phones = [];
        if (item.phone1) {
          phones.push(formatPhone(item.phone1));
        }
        if (item.phone2) {
          phones.push(formatPhone(item.phone2));
        }
        item.phones = phones;

        let location = '';
        if (item.city && item.state) {
          location = `${item.city}/${item.state}`;
        } else if (item.city && !item.state) {
          location = item.city;
        } else if (!item.city && item.state) {
          location = item.state;
        }
        item.location = location;
      });
      setPartners(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Partners/index.js - retrievePartners',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrievePartnerLogs = useCallback(async (partnerId) => {
    try {
      if (partnerId) {
        const reqData = {
          id: partnerId,
          token,
        };

        const data = await API.fetch('GET', 'partnerLogs', reqData);

        setPartnerLogs(data);
      }
    } catch (error) {
      setPartnerLogs([]);

      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Partners/index.js - retrievePartnerLogs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  function handleNewPartner() {
    setSelectedPartner('');
    setOpenModal(true);
  }

  useEffect(() => {
    retrievePartners();
  }, [retrievePartners]);

  useEffect(() => {
    if (updateList) {
      retrievePartners();
      setUpdateList(false);
    }
  }, [updateList, retrievePartners]);

  useEffect(() => {
    if (selectedPartner) {
      retrievePartnerLogs(selectedPartner.id);
      setOpenModal(true);
    } else {
      setPartnerLogs([]);
    }
  }, [selectedPartner, retrievePartnerLogs]);

  return (
    <div className='page-container'>
      <Header secondaryTitle='PARCEIROS' />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
          <div onClick={handleNewPartner}>
            <BlueButton buttonText='Novo   +' />
          </div>
        </div>
        <div className='page-table-area'>
          {partners.length > 0 && (
            <PartnersList
              partners={partners}
              setSelectedPartner={setSelectedPartner}
              setUpdateList={setUpdateList}
            />
          )}
        </div>
      </div>
      {openModal && (
        <Partner
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedPartner={selectedPartner}
          setSelectedPartner={setSelectedPartner}
          partnerLogs={partnerLogs}
          setPartnerLogs={setPartnerLogs}
          setUpdateList={setUpdateList}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>

  );
}

export default Partners;
