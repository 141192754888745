import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import {
  format,
  parse,
} from 'date-fns';

import logoReports from '../../../images/base64Images';

function Report2PDF(reportData) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', 150],
        body: [
          [
            {
              text: reportData.reportTitle,
              fontSize: 15,
              bold: true,
              alignment: 'center',
              margin: [140, 15, 0, 15],
              colSpan: 6,
              border: [true, true, false, true],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              width: 90,
              image: logoReports,
              border: [false, true, true, true],
              margin: [50, 10, 10, 10],
            },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
  ];

  const dataPart1 = reportData.casesToReceive.map((caseToReceive) => [
    {
      text: caseToReceive.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: caseToReceive.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: caseToReceive.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: caseToReceive.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(caseToReceive.evaluationValue) > 0
        ? ((Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString()
          + (Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseToReceive.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : '')))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: format(parse(caseToReceive.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: (caseToReceive.caseNumber ? caseToReceive.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(caseToReceive.amountToReceive) > 0
        ? (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString()
          + (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseToReceive.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : ''))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, true, true],
    },
  ]);

  const dataPart2 = reportData.casesReceived.map((caseReceived) => [
    {
      text: caseReceived.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: caseReceived.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: caseReceived.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: caseReceived.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(caseReceived.evaluationValue) > 0
        ? ((Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString()
          + (Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseReceived.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : '')))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: format(parse(caseReceived.distributedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: (caseReceived.caseNumber ? caseReceived.caseNumber.replace(/\D/g, '').replace(/^(\d{7})(\d{2})?(\d{4})?(\d{1})?(\d{2})?(\d{4})?/, '$1-$2.$3.$4.$5.$6') : '-'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: format(parse(caseReceived.paymentReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(caseReceived.amountReceived) > 0
        ? ((Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString()
          + (Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString().length - (Number(Number(caseReceived.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : '')))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, true, true],
    },
  ]);

  const header = [
    {
      table: {
        widths: [35, '*', 35, '*', 90, '*', 85, '*'],
        body: [
          [
            {
              text: 'Período:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: (reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Origem:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: reportData.origin ? reportData.origin : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Honorários previstos:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: `R$ ${Number(reportData.amountToReceive) > 0
                ? (`${Number(Number(reportData.amountToReceive).toFixed(2).toString()).toLocaleString()
                  + (Number(Number(reportData.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
                    ? ',00'
                    : (Number(Number(reportData.amountToReceive).toFixed(2).toString()).toLocaleString().length - (Number(Number(reportData.amountToReceive).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
                      ? '0'
                      : '')} (${reportData.casesToReceive.length} casos)`)
                : '-'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Valor total de cálculos:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: `R$ ${Number(reportData.totalEvaluationValue) > 0
                ? ((Number(Number(reportData.totalEvaluationValue).toFixed(2).toString()).toLocaleString()
                  + (Number(Number(reportData.totalEvaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
                    ? ',00'
                    : (Number(Number(reportData.totalEvaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(reportData.totalEvaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
                      ? '0'
                      : '')))
                : '-'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
          ],
          [
            {
              text: 'Produto:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.product ? reportData.product : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: 'Parceiro:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.partner ? reportData.partner : 'Geral',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: 'Honorários recebidos:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: `R$ ${Number(reportData.amountReceived) > 0
                ? (`${Number(Number(reportData.amountReceived).toFixed(2).toString()).toLocaleString()
                  + (Number(Number(reportData.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
                    ? ',00'
                    : (Number(Number(reportData.amountReceived).toFixed(2).toString()).toLocaleString().length - (Number(Number(reportData.amountReceived).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
                      ? '0'
                      : '')} (${reportData.casesReceived.length} casos)`)
                : '-'}`,
              style: 'tableHeader',
              colSpan: 3,
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {},
            {},
          ],
        ],
      },
    },
  ];

  const details1 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 45, 45, '*', 75, 75, 90, 70],
        body: [
          [
            {
              text: 'CASOS DISTRIBUÍDOS COM RECEBIMENTOS PREVISTOS',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'VALOR DO CÁLCULO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              orderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA DISTRIBUIÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'Nº PROCESSO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'HONORÁRIOS PREVISTOS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart1,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details2 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 45, 45, '*', 75, 75, 90, 80, 70],
        body: [
          [
            {
              text: 'CASOS RECEBIDOS',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'VALOR DO CÁLCULO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA DISTRIBUIÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'Nº PROCESSO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA RECEBIMENTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'HONORÁRIOS RECEBIDOS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart2,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  function footer(currentPage, pageCount) {
    return {
      columns: [
        {
          text: `Gerado em: ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`,
          fontSize: 7,
          margin: [20, 10, 0, 0], // left, top, right, bottom
        },
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0], // left, top, right, bottom
        },
      ],
    };
  }

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [15, 40, 15, 40],
    content: [reportTitle, header, reportData.casesToReceive.length > 0 ? details1 : '', reportData.casesReceived.length > 0 ? details2 : ''],
    footer,
  };
  return docDefinitions;
}

export default Report2PDF;
