import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';
import Product from '../../components/Modals/Product';
import ProductsList from '../../components/Lists/Products';

import '../../style.css';

function Products() {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsGroups, setProductsGroups] = useState([]);
  const [productsTypes, setProductsTypes] = useState(['Administrativo', 'Judicial']);
  const [productsWorkflows, setProductsWorkflows] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [updateList, setUpdateList] = useState(false);

  const retrieveProducts = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'products', reqData);

      setProducts(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Products/index.js - retrieveProducts',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  const retrieveAuxiliaryLists = useCallback(async (target) => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', target, reqData);

      switch (target) {
        case 'productsGroups':
          setProductsGroups(data);
          break;

        case 'productsWorkflows':
          setProductsWorkflows(data);
          break;

        default:
          break;
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Products/index.js - retrieveAuxiliaryLists',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  function handleNewProduct() {
    setSelectedProduct('');

    const fetchData = async () => {
      const promise1 = retrieveAuxiliaryLists('productsGroups');
      const promise2 = retrieveAuxiliaryLists('productsWorkflows');

      await Promise.all([promise1, promise2]);

      setOpenModal(true);
    };

    fetchData();
  }

  useEffect(() => {
    retrieveProducts();
    setUpdateList(false);
  }, [updateList]);

  useEffect(() => {
    if (selectedProduct) {
      const fetchData = async () => {
        const promise1 = retrieveAuxiliaryLists('productsGroups');
        const promise2 = retrieveAuxiliaryLists('productsWorkflows');

        await Promise.all([promise1, promise2]);

        setOpenModal(true);
      };

      fetchData();
    }
  }, [selectedProduct]);

  return (
    <div className='page-container'>
      <Header secondaryTitle='PRODUTOS' />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
          <div onClick={handleNewProduct}>
            <BlueButton buttonText='Novo   +' />
          </div>
        </div>
        <div className='page-table-area'>
          {products.length > 0 && (
            <ProductsList
              products={products}
              setSelectedProduct={setSelectedProduct}
              setUpdateList={setUpdateList}
            />
          )}
        </div>
      </div>
      {openModal && (
        <Product
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          setUpdateList={setUpdateList}
          auxiliaryLists={{
            productsGroups,
            productsTypes,
            productsWorkflows,
          }}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>

  );
}

export default Products;
