import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';
import Origin from '../../components/Modals/Origin';
import OriginsList from '../../components/Lists/Origins';

import '../../style.css';

function Origins() {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [origins, setOrigins] = useState([]);
  const [selectedOrigin, setSelectedOrigin] = useState();
  const [updateList, setUpdateList] = useState(false);

  const retrieveOrigins = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'origins', reqData);

      setOrigins(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/Origins/index.js - retrieveOrigins',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token]);

  function handleNewOrigin() {
    setSelectedOrigin('');
    setOpenModal(true);
  }

  useEffect(() => {
    retrieveOrigins();
    setUpdateList(false);
  }, [updateList]);

  useEffect(() => {
    if (selectedOrigin) {
      setOpenModal(true);
    }
  }, [selectedOrigin]);

  return (
    <div className='page-container'>
      <Header secondaryTitle='ORIGENS' />
      <div className='page-body-container'>
        <div className='page-top-menu-area'>
          <div>
            <DrawerMenu />
          </div>
          <div onClick={handleNewOrigin}>
            <BlueButton buttonText='Novo   +' />
          </div>
        </div>
        <div className='page-table-area'>
          {origins.length > 0 && (
            <OriginsList
              origins={origins}
              setSelectedOrigin={setSelectedOrigin}
              setUpdateList={setUpdateList}
            />
          )}
        </div>
      </div>
      {openModal && (
        <Origin
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedOrigin={selectedOrigin}
          setSelectedOrigin={setSelectedOrigin}
          setUpdateList={setUpdateList}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>

  );
}

export default Origins;
