import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  format,
  parse,
} from 'date-fns';

import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { doubleSpaceRegex } from '../../../../services/regex';

import { API } from '../../../../services/api';

import { AuthContext } from '../../../../contexts/auth';

import Alert from '../../Alert';

import './style.css';

function PartnerFormRow2({
  selectedPartner, partnerData, setPartnerData, partnerLogs, setPartnerLogs,
}) {
  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [arrivedAt, setArrivedAt] = useState();
  const [bank, setBank] = useState();
  const [bankAccount, setBankAccount] = useState();
  const [bankBranch, setBankBranch] = useState();
  const [bankCode, setBankCode] = useState();
  const [calculationBasisEnd, setCalculationBasisEnd] = useState();
  const [calculationBasisStart, setCalculationBasisStart] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [newComment, setNewComment] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [participationAtSuccumbence, setParticipationAtSuccumbence] = useState();
  const [pixTransferKey, setPixTransferKey] = useState();
  const [removeCommentConfirmation, setRemoveCommentConfirmation] = useState('');
  const [typeEnd, setTypeEnd] = useState();
  const [typeStart, setTypeStart] = useState();
  const [valueEnd, setValueEnd] = useState();
  const [valueStart, setValueStart] = useState();

  useEffect(() => {
    if (selectedPartner !== undefined) {
      setArrivedAt(selectedPartner.arrivedAt ? format(parse(selectedPartner.arrivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '');
      setBank(selectedPartner.bank ? selectedPartner.bank : '');
      setBankAccount(selectedPartner.bankAccount ? selectedPartner.bankAccount : '');
      setBankBranch(selectedPartner.bankBranch ? selectedPartner.bankBranch : '');
      setBankCode(selectedPartner.bankCode ? selectedPartner.bankCode : '');
      setCalculationBasisEnd(selectedPartner.calculationBasisEnd ? selectedPartner.calculationBasisEnd : '');
      setCalculationBasisStart(selectedPartner.calculationBasisStart ? selectedPartner.calculationBasisStart : '');
      setCreatedAt(selectedPartner.createdAt ? format(parse(selectedPartner.createdAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '');
      setParticipationAtSuccumbence(selectedPartner.participationAtSuccumbence);
      setPixTransferKey(selectedPartner.pixTransferKey ? selectedPartner.pixTransferKey : '');
      setTypeEnd(selectedPartner.typeEnd ? selectedPartner.typeEnd : '');
      setTypeStart(selectedPartner.typeStart ? selectedPartner.typeStart : '');
      setValueEnd(selectedPartner.valueEnd ? selectedPartner.valueEnd.replace('.', ',') : '');
      setValueStart(selectedPartner.valueStart ? selectedPartner.valueStart.replace('.', ',') : '');
    }
  }, [selectedPartner]);

  useEffect(() => {
    let currentData = {};
    if (partnerData !== undefined) {
      currentData = partnerData;
    }

    if (arrivedAt) {
      if (format(parse(arrivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') !== selectedPartner.arrivedAt) {
        currentData.arrivedAt = format(parse(arrivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');
      }
    } else {
      currentData.arrivedAt = null;
    }

    if (bank) {
      if (bank !== selectedPartner.bank) {
        currentData.bank = bank;
      }
    } else {
      currentData.bank = null;
    }

    if (bankAccount) {
      if (bankAccount !== selectedPartner.bankAccount) {
        currentData.bankAccount = bankAccount;
      }
    } else {
      currentData.bankAccount = null;
    }

    if (bankBranch) {
      if (bankBranch !== selectedPartner.bankBranch) {
        currentData.bankBranch = bankBranch;
      }
    } else {
      currentData.bankBranch = null;
    }

    if (bankCode) {
      if (bankCode !== selectedPartner.bankCode) {
        currentData.bankCode = bankCode;
      }
    } else {
      currentData.bankCode = null;
    }

    if (calculationBasisEnd) {
      if (calculationBasisEnd !== selectedPartner.calculationBasisEnd && calculationBasisEnd !== '') {
        currentData.calculationBasisEnd = calculationBasisEnd;
      }
    } else {
      currentData.calculationBasisEnd = null;
    }

    if (calculationBasisStart) {
      if (calculationBasisStart !== selectedPartner.calculationBasisStart && calculationBasisStart !== '') {
        currentData.calculationBasisStart = calculationBasisStart;
      }
    } else {
      currentData.calculationBasisStart = null;
    }

    if (pixTransferKey) {
      if (pixTransferKey !== selectedPartner.pixTransferKey) {
        currentData.pixTransferKey = pixTransferKey;
      }
    } else {
      currentData.pixTransferKey = null;
    }

    if (typeEnd) {
      if (typeEnd !== selectedPartner.typeEnd && typeEnd !== '') {
        currentData.typeEnd = typeEnd;
      }
    } else {
      currentData.typeEnd = null;
    }

    if (typeStart) {
      if (typeStart !== selectedPartner.typeStart && typeStart !== '') {
        currentData.typeStart = typeStart;
      }
    } else {
      currentData.typeStart = null;
    }

    if (valueEnd) {
      if (valueEnd.replace(',', '.') !== selectedPartner.valueEnd) {
        currentData.valueEnd = (parseInt(valueEnd.replace(/\D+/g, ''), 10) / 100).toFixed(2);
      }
    } else {
      currentData.valueEnd = null;
    }

    if (valueStart) {
      if (valueStart.replace(',', '.') !== selectedPartner.valueStart) {
        currentData.valueStart = (parseInt(valueStart.replace(/\D+/g, ''), 10) / 100).toFixed(2);
      }
    } else {
      currentData.valueStart = null;
    }

    currentData.participationAtSuccumbence = participationAtSuccumbence;

    setPartnerData(currentData);
  }, [arrivedAt, bank, bankAccount, bankBranch, bankCode, calculationBasisEnd, calculationBasisStart, pixTransferKey, typeEnd, typeStart,
    valueEnd, valueStart, participationAtSuccumbence, partnerData, setPartnerData, selectedPartner.arrivedAt, selectedPartner.bank, selectedPartner.bankAccount,
    selectedPartner.bankBranch, selectedPartner.bankCode, selectedPartner.calculationBasisEnd, selectedPartner.calculationBasisStart,
    selectedPartner.pixTransferKey, selectedPartner.typeEnd, selectedPartner.typeStart, selectedPartner.valueEnd, selectedPartner.valueStart]);

  async function handleAddComment() {
    try {
      if (newComment.trim().length > 0 && selectedPartner.id !== undefined) {
        const commentData = {
          target: 'Parceiro',
          targetId: selectedPartner.id,
          details: newComment.trim(),
        };

        let reqData = {
          body: commentData,
          token,
        };

        let data = await API.fetch('POST', 'logs', reqData);

        reqData = {
          id: selectedPartner.id,
          token,
        };

        data = await API.fetch('GET', 'partnerLogs', reqData);

        setPartnerLogs(data);

        setNewComment('');
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Partner/PartnerFormRow2/index.js - handleAddComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRemoveComment(logId) {
    try {
      const reqData = {
        id: logId,
        token,
      };

      await API.fetch('DELETE', 'logs', reqData);

      const currentData = partnerLogs.filter((comment) => comment.id !== logId);

      setPartnerLogs(currentData);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Partner/PartnerFormRow2/index.js - handleRemoveComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    } finally {
      setRemoveCommentConfirmation('');
    }
  }

  function formatValueStart(value) {
    const newValue = value.replace(/\D+/g, '');
    setValueStart((parseInt(newValue, 10) / 100).toFixed(2).replace('.', ','));
  }

  function formatValueEnd(value) {
    const newValue = value.replace(/\D+/g, '');

    setValueEnd((parseInt(newValue, 10) / 100).toFixed(2).replace('.', ','));
  }

  return (
    <div className='row3'>
      <div className='partner-row3'>
        <div className='partner-row3-col1'>
          {(activeUser.category === 'Sócio' || activeUser.category === 'Financeiro') && (
            <div className='partner-benefit-and-origin'>
              <div className='input-benefit-and-origin-group'>
                <div className='benefit-and-origin-row'>
                  <div className='partner-commission-stage'>
                    <label />
                    <label className='partner-label'>
                      Início
                    </label>
                  </div>
                  <div className='partner-commission'>
                    <label className='partner-label'>
                      Tipo Participação
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      disabled={activeUser.category !== 'Sócio'}
                      value={typeStart}
                      onChange={(e) => setTypeStart(e.target.value)}
                    >
                      <option />
                      <option>Fixo</option>
                      <option>Percentual</option>
                    </select>
                  </div>
                  <div className='partner-commission-calculation-basis'>
                    <label className='partner-label'>
                      Base de Cálculo
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      disabled={activeUser.category !== 'Sócio'}
                      value={calculationBasisStart}
                      onChange={(e) => setCalculationBasisStart(e.target.value)}
                    >
                      <option />
                      <option>Cálculo avaliação</option>
                    </select>
                  </div>
                  <div className='partner-origin'>
                    <label className='partner-label'>
                      Valor/Alíquota
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='10'
                      disabled={activeUser.category !== 'Sócio'}
                      style={{
                        padding: '0 10%',
                        textAlign: 'center',
                      }}
                      value={valueStart}
                      onChange={(e) => formatValueStart(e.target.value)}
                    />
                    {typeStart === 'Fixo' && (
                      <span style={{
                        marginLeft: '-95%',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        R$
                      </span>
                    )}
                    {typeStart === 'Percentual' && (
                      <span style={{
                        marginLeft: '-15%',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        %
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className='input-benefit-and-origin-group'>
                <div className='benefit-and-origin-row next-row'>
                  <div className='partner-commission-stage'>
                    <label className='partner-label'>
                      Fim
                    </label>
                  </div>
                  <div className='partner-commission'>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      disabled={activeUser.category !== 'Sócio'}
                      value={typeEnd}
                      onChange={(e) => setTypeEnd(e.target.value)}
                    >
                      <option />
                      <option>Percentual</option>
                    </select>
                  </div>
                  <div className='partner-commission-calculation-basis'>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      disabled={activeUser.category !== 'Sócio'}
                      value={calculationBasisEnd}
                      onChange={(e) => setCalculationBasisEnd(e.target.value)}
                    >
                      <option />
                      <option>Honorários finais</option>
                    </select>
                  </div>
                  <div className='partner-origin'>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='10'
                      style={{
                        padding: '0 10%',
                        textAlign: 'center',
                      }}
                      disabled={activeUser.category !== 'Sócio'}
                      value={valueEnd}
                      onChange={(e) => formatValueEnd(e.target.value)}
                    />
                    <span style={{
                      marginLeft: '-15%',
                      alignSelf: 'center',
                      fontSize: '13px',
                    }}
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div
                className='input-benefit-and-origin-group next-row'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <label
                  className='partner-label'
                  style={{ width: 180 }}
                >
                  Participação na sucumbência:
                </label>
                <input
                  className='input'
                  variant='outlined'
                  size='small'
                  type='checkbox'
                  maxLength='10'
                  style={{
                    height: 15,
                    width: 15,
                    marginRight: 0,
                  }}
                  disabled={activeUser.category !== 'Sócio'}
                  checked={participationAtSuccumbence}
                  onChange={() => setParticipationAtSuccumbence(!participationAtSuccumbence)}
                />
              </div>
            </div>
          )}
          <div
            className='partner-partners'
            style={{ marginTop: ((activeUser.category !== 'Sócio' && activeUser.category !== 'Financeiro') && '-20px') }}
          >
            <div className='input-benefit-and-origin-group'>
              <div className='benefit-and-origin-row next-row'>
                <div className='partner-bank'>
                  <label className='partner-label'>
                    Banco
                  </label>
                  <input
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    disabled={activeUser.category !== 'Sócio'}
                    value={bank}
                    onChange={(e) => setBank(e.target.value.replace(doubleSpaceRegex, ' '))}
                  />
                </div>
                <div className='partner-bank-code'>
                  <label className='partner-label'>
                    Código
                  </label>
                  <input
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    disabled={activeUser.category !== 'Sócio'}
                    value={bankCode}
                    onChange={(e) => setBankCode(e.target.value.replace(doubleSpaceRegex, ' '))}
                  />
                </div>
                <div className='partner-bank-pix'>
                  <label className='partner-label'>
                    Pix
                  </label>
                  <input
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    disabled={activeUser.category !== 'Sócio'}
                    value={pixTransferKey}
                    onChange={(e) => setPixTransferKey(e.target.value.replace(doubleSpaceRegex, ' '))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='partner-admission-group next-row'>
            <div className='partner-bank-branch'>
              <label className='partner-label'>
                Agência
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={bankBranch}
                onChange={(e) => setBankBranch(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='partner-bank-account'>
              <label className='partner-label'>
                Conta
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={bankAccount}
                onChange={(e) => setBankAccount(e.target.value.replace(doubleSpaceRegex, ' '))}
              />
            </div>
            <div className='partner-admission'>
              <label className='partner-label'>
                Data de Entrada
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='date'
                disabled={activeUser.category !== 'Sócio'}
                value={arrivedAt}
                onChange={(e) => setArrivedAt(e.target.value)}
              />
            </div>
            {selectedPartner && (
              <div className='partner-registration'>
                <label className='partner-label'>
                  Data de Cadastro
                </label>
                <input
                  className='input'
                  variant='outlined'
                  size='small'
                  type='text'
                  style={{ textAlign: 'center' }}
                  value={createdAt}
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div className='partner-row3-col2'>
          {selectedPartner && (
            <div className='partner-logs'>
              <label className='partner-label'>
                Comentários e logs de sistema
              </label>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginBottom: '10px',
                border: '1px #818a91 solid',
                borderRadius: '3px',
                height: '35px',
                alignItems: 'center',
              }}
              >
                <input
                  className='log-input'
                  disabled={activeUser.category !== 'Sócio'}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value.replace(doubleSpaceRegex, ' '))}
                />
                {(selectedPartner.id !== undefined && activeUser.category === 'Sócio') && (
                  <AddIcon
                    onClick={handleAddComment}
                    style={{
                      cursor: 'pointer',
                      paddingRight: '5px',
                    }}
                  />
                )}
              </div>
              <div className='partner-log-field'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {partnerLogs.map((log) => (
                    <div
                      className='log-row'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: '1px gray solid',
                      }}
                    >
                      {log.type === 'Comentário'
                        ? <strong><span>{`[${format(Date.parse(log.dateTime), 'dd/MM/yyyy HH:mm:ss')} - ${log.user}]: ${log.details}`}</span></strong>
                        : <span>{`[${format(Date.parse(log.dateTime), 'dd/MM/yyyy HH:mm:ss')} - ${log.user}]: ${log.details}`}</span>}
                      {(removeCommentConfirmation === log.id && activeUser.category === 'Sócio')
                        && (
                          <>
                            <span style={{
                              color: 'red',
                              width: '60px',
                            }}
                            >
                              <b>Confirma a exclusão?</b>

                            </span>
                            <CheckCircleOutlineIcon
                              onClick={() => handleRemoveComment(log.id)}
                              style={{
                                cursor: 'pointer',
                                paddingRight: '5px',
                                color: 'green',
                                backgroundColor: 'rgb(240, 240, 240)',
                              }}
                            />
                            <HighlightOffIcon
                              onClick={() => setRemoveCommentConfirmation('')}
                              style={{
                                cursor: 'pointer',
                                paddingRight: '5px',
                                color: 'red',
                                backgroundColor: 'rgb(240, 240, 240)',
                              }}
                            />
                          </>
                        )}
                      {(log.type === 'Comentário' && removeCommentConfirmation !== log.id && activeUser.category === 'Sócio') && (
                        <DeleteForeverIcon
                          onClick={() => setRemoveCommentConfirmation(log.id)}
                          style={{
                            cursor: 'pointer',
                            paddingRight: '5px',
                            color: 'red',
                            backgroundColor: 'rgb(240, 240, 240)',
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default PartnerFormRow2;
