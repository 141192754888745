import {
  createContext,
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { API } from '../services/api';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [token, setToken] = useState();
  const [activeUser, setActiveUser] = useState();

  const emailDomain = '@marcosandreadvogados.com.br';

  useEffect(() => {
    const recoveredUserData = JSON.parse(localStorage.getItem('MAADV'));

    if (recoveredUserData?.userId && recoveredUserData?.userName) {
      setActiveUser({
        id: recoveredUserData.userId,
        name: recoveredUserData.userName,
        category: recoveredUserData.userCategory,
      });
    }

    if (recoveredUserData?.token) {
      setToken(recoveredUserData.token);
    }

    setLoading(false);
  }, []);

  async function login(email, password) {
    try {
      const reqData = {
        body: {
          email,
          password,
        },
      };

      const data = await API.fetch('POST', 'login', reqData);

      localStorage.setItem('MAADV', JSON.stringify(data));

      setActiveUser({
        id: data.userId,
        name: data.userName,
        category: data.userCategory,
      });

      setToken(data.token);
      navigate('/home');
      setLoginError('');
    } catch (error) {
      setLoginError(error.message);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'src/contexts/auth.js - login',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  const logout = () => {
    localStorage.removeItem('MAADV');

    setActiveUser();
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: activeUser !== undefined,
        activeUser,
        setActiveUser,
        emailDomain,
        loading,
        login,
        loginError,
        setLoginError,
        logout,
        token,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
