import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';

import hide from '../../images/hide.png';
import logo from '../../images/logo.png';
import show from '../../images/show.png';

import RecoverPassword from '../../components/Modals/RecoverPassword';
import YellowButton from '../../components/Buttons/YellowButton';

import './style.css';

function Login() {
  const navigate = useNavigate();

  const {
    login,
    loginError, setLoginError,
    setActiveUser,
    emailDomain,
    setToken,
  } = useContext(AuthContext);

  const [email, setEmail] = useState();
  const [openRecoverPasswordModal, setOpenRecoverPasswordModal] = useState(false);
  const [password, setPassword] = useState();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const recoveredUserData = JSON.parse(localStorage.getItem('MAADV'));

    if (recoveredUserData?.userId && recoveredUserData?.userName && recoveredUserData?.token) {
      setActiveUser({
        id: recoveredUserData.userId,
        name: recoveredUserData.userName,
        category: recoveredUserData.userCategory,
      });

      setToken(recoveredUserData.token);

      navigate('/home');
    }
  }, [navigate, setActiveUser, setToken]);

  useEffect(() => {
    setLoginError('');
  }, [email, password, setLoginError]);

  function handleLogin() {
    if (!email || !password) {
      setLoginError('E-mail e senha são obrigatórios.');
    } else {
      login(email + emailDomain, password);
    }
  }

  return (
    <div className='login'>
      <div className='card'>
        <div className='card-content'>
          <img
            width='250'
            height='73'
            src={logo}
            alt='Logo MAADV'
            className='attachment-full size-full'
            loading='lazy'
          />
          <div className='login-title'>
            Sistema de Gestão
          </div>
        </div>
        <div className='login-content'>
          <div className='login-row-email'>
            <div className='login-input'>
              <input
                autoFocus
                className='input'
                placeholder='E-mail'
                variant='outlined'
                size='small'
                type='email'
                maxLength='22'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => { if (e.key === 'Enter') { handleLogin(); } }}
              />
              <span className='login-email'>
                {emailDomain}
              </span>
            </div>
          </div>
          <div className='login-row-password'>
            <input
              className='input'
              placeholder='Senha'
              variant='outlined'
              size='small'
              type={visible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => { if (e.key === 'Enter') { handleLogin(); } }}
            />
            <span className='login-password-visibility'>
              <img
                src={visible ? hide : show}
                alt={visible ? 'Ocultar' : 'Mostrar'}
                onClick={() => setVisible(!visible)}
                style={{ width: '20px' }}
              />
            </span>
          </div>
          <div
            className='recover-password'
            onClick={() => setOpenRecoverPasswordModal(true)}
          >
            Esqueci a senha
          </div>
          <div
            className='login-button'
            onClick={handleLogin}
          >
            <YellowButton
              size='small'
              buttonText='Entrar'
            />
          </div>
          <div className='error'>
            {loginError}
          </div>
        </div>
      </div>
      <div>
        <RecoverPassword
          openRecoverPasswordModal={openRecoverPasswordModal}
          setOpenRecoverPasswordModal={setOpenRecoverPasswordModal}
        />
      </div>
    </div>
  );
}

export default Login;
