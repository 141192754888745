export default class APIError extends Error {
  constructor(response, body) {
    super();

    this.name = 'APIError';
    this.response = response || { status: '400' };
    this.message = !response
      ? 'Requisição inválida.'
      : (body || `${response.status} - ${response.statusText}`);
  }
}
