import { format, setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Card from '../Card';

import './style.css';

function CasesCount({ dashboardData }) {
  setDefaultOptions({ locale: ptBR });

  return (
    <div className='dashboard-cases-count'>
      <div className='dashboard-cases-count-section'>
        <Card
          cardText=''
          cardWidth='36%'
          cardMinWidth='72px'
          cardColor='#FFF'
        />
        <Card
          cardText='GERAL'
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#171f48'
        />
        <Card
          cardText={(new Date()).getFullYear()}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#171f48'
        />
        <Card
          cardText={format(new Date(), 'MMM/yyyy').toUpperCase()}
          cardWidth='32%'
          cardMinWidth='60px'
          cardColor='#171f48'
        />
      </div>
      <div className='dashboard-cases-count-section'>
        <Card
          cardText='Fechados*'
          cardWidth='36%'
          cardMinWidth='72px'
          cardColor='#0FAA0F'
        />
        <Card
          cardText={dashboardData.signedCases > 0 ? dashboardData.signedCases.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.signedCasesCurrentYear > 0 ? dashboardData.signedCasesCurrentYear.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.signedCasesCurrentMonth > 0 ? dashboardData.signedCasesCurrentMonth.toLocaleString() : '-'}
          cardWidth='32%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
      </div>
      <div className='dashboard-cases-count-section'>
        <Card
          cardText='Sem direito'
          cardWidth='36%'
          cardMinWidth='72px'
          cardColor='#F70707'
        />
        <Card
          cardText={dashboardData.negativeResults > 0 ? dashboardData.negativeResults.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.negativeResultsCurrentYear > 0 ? dashboardData.negativeResultsCurrentYear.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.negativeResultsCurrentMonth > 0 ? dashboardData.negativeResultsCurrentMonth.toLocaleString() : '-'}
          cardWidth='32%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
      </div>
      <div className='dashboard-cases-count-section'>
        <Card
          cardText='Em avaliação'
          cardWidth='36%'
          cardMinWidth='72px'
          cardColor='#EFC337'
        />
        <Card
          cardText={dashboardData.onGoingCases > 0 ? dashboardData.onGoingCases.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.onGoingCasesCurrentYear > 0 ? dashboardData.onGoingCasesCurrentYear.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.onGoingCasesCurrentMonth > 0 ? dashboardData.onGoingCasesCurrentMonth.toLocaleString() : '-'}
          cardWidth='32%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
      </div>
      <div className='dashboard-cases-count-section'>
        <Card
          cardText='Casos frios'
          cardWidth='36%'
          cardMinWidth='72px'
          cardColor='#171f48'
        />
        <Card
          cardText={dashboardData.coldCases > 0 ? dashboardData.coldCases.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.coldCasesCurrentYear > 0 ? dashboardData.coldCasesCurrentYear.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.coldCasesCurrentMonth > 0 ? dashboardData.coldCasesCurrentMonth.toLocaleString() : '-'}
          cardWidth='32%'
          cardMinWidth='60px'
          cardColor='#FFF'
          cardBorder='1px solid #171f48'
        />
      </div>
      <div className='dashboard-cases-count-section'>
        <Card
          cardText=''
          cardWidth='36%'
          cardMinWidth='72px'
          cardColor='#FFF'
          cardBorder='none'
        />
        <Card
          cardText={dashboardData.total > 0 ? dashboardData.total.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#171f48'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.totalCurrentYear > 0 ? dashboardData.totalCurrentYear.toLocaleString() : '-'}
          cardWidth='24%'
          cardMinWidth='60px'
          cardColor='#171f48'
          cardBorder='1px solid #171f48'
        />
        <Card
          cardText={dashboardData.totalCurrentMonth > 0 ? dashboardData.totalCurrentMonth.toLocaleString() : '-'}
          cardWidth='32%'
          cardMinWidth='60px'
          cardColor='#171f48'
          cardBorder='1px solid #171f48'
        />
      </div>
      <div className='dashboard-cases-count-section'>
        <span
          style={{ fontSize: 10 }}
        >
          *Casos fechados = kits recebidos
        </span>
      </div>
    </div>
  );
}

export default CasesCount;
