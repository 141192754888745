import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import {
  format,
  parse,
} from 'date-fns';

import logoReports from '../../../images/base64Images';

function Report6PDF(reportData) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', 150],
        body: [
          [
            {
              text: reportData.reportTitle,
              fontSize: 15,
              bold: true,
              alignment: 'center',
              margin: [140, 15, 0, 15],
              colSpan: 9,
              border: [true, true, false, true],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              width: 90,
              image: logoReports,
              border: [false, true, true, true],
              margin: [50, 10, 10, 10],
            },
          ],
        ],
      },
      layout: 'headerLineOnly',
    },
  ];

  const dataPart1 = reportData.leadsList.map((lead) => [
    {
      text: lead.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: lead.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: lead.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: lead.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: lead.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const dataPart2 = reportData.awaitingDocumentsList.map((awaitingDocuments) => [
    {
      text: awaitingDocuments.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: awaitingDocuments.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingDocuments.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingDocuments.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingDocuments.docsRequestedAt ? format(parse(awaitingDocuments.docsRequestedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: awaitingDocuments.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const dataPart3 = reportData.awaitingCalculationList.map((awaitingCalculation) => [
    {
      text: awaitingCalculation.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: awaitingCalculation.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingCalculation.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingCalculation.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingCalculation.docsRequestedAt ? format(parse(awaitingCalculation.docsRequestedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: awaitingCalculation.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const dataPart4 = reportData.awaitingKitList.map((awaitingKit) => [
    {
      text: awaitingKit.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: awaitingKit.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingKit.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingKit.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingKit.evaluatedAt ? format(parse(awaitingKit.evaluatedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(awaitingKit.evaluationValue) > 0
        ? ((Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString()
          + (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(awaitingKit.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : '')))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: awaitingKit.kitRequestedAt ? format(parse(awaitingKit.kitRequestedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: awaitingKit.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const dataPart5 = reportData.awaitingDistributionList.map((awaitingDistribution) => [
    {
      text: awaitingDistribution.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: awaitingDistribution.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingDistribution.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: awaitingDistribution.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(awaitingDistribution.evaluationValue) > 0
        ? ((Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString()
          + (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(awaitingDistribution.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : '')))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: awaitingDistribution.kitReceivedAt ? format(parse(awaitingDistribution.kitReceivedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: awaitingDistribution.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const dataPart6 = reportData.negativeResultsList.map((negativeResult) => [
    {
      text: negativeResult.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: negativeResult.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: negativeResult.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: negativeResult.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: negativeResult.evaluatedAt ? format(parse(negativeResult.evaluatedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: negativeResult.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const dataPart7 = reportData.coldCasesList.map((coldCase) => [
    {
      text: coldCase.client,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'left',
      border: [true, true, false, true],
    },
    {
      text: coldCase.origin,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: coldCase.partners.join('\r\n'),
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: coldCase.product,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: coldCase.evaluatedAt ? format(parse(coldCase.evaluatedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-',
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, false, true],
    },
    {
      text: `R$ ${Number(coldCase.evaluationValue) > 0
        ? ((Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString()
          + (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') === -1
            ? ',00'
            : (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().length - (Number(Number(coldCase.evaluationValue).toFixed(2).toString()).toLocaleString().indexOf(',') + 1) === 1)
              ? '0'
              : '')))
        : '-'}`,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'right',
      border: [false, true, false, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: '',
      margin: [0, 0, 0, 0],
      border: [false, true, true, true],
    },
    {
      text: coldCase.status,
      fontSize: 7,
      margin: [0, 5, 0, 2],
      alignment: 'center',
      border: [false, true, true, true],
    },
  ]);

  const header = [
    {
      table: {
        widths: ['*', 135, 105, 25, 80, 25, 90, 25, 45, 25],
        body: [
          [
            {
              text: `Período: ${(reportData.startDate && reportData.endDate) ? `${format(parse(reportData.startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')} a ${format(parse(reportData.endDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}` : 'Geral'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: `Origem: ${reportData.origin ? reportData.origin : 'Geral'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Leads:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: reportData.leadsList.length > 0 ? reportData.leadsList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Aguardando cálculo:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: reportData.awaitingCalculationList.length > 0 ? reportData.awaitingCalculationList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Aguardando distribuição:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: reportData.awaitingDistributionList.length > 0 ? reportData.awaitingDistributionList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: 'Casos frios:',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
            {
              text: reportData.coldCasesList.length > 0 ? reportData.coldCasesList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, true, false, false],
              fontSize: 8,
            },
          ],
          [
            {
              text: `Produto: ${reportData.product ? reportData.product : 'Geral'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: `Parceiro: ${reportData.partner ? reportData.partner : 'Geral'}`,
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: 'Aguardando documentação:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.awaitingDocumentsList.length > 0 ? reportData.awaitingDocumentsList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: 'Aguardando kit:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.awaitingKitList.length > 0 ? reportData.awaitingKitList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: 'Sem direito:',
              style: 'tableHeader',
              lignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: reportData.negativeResultsList.length > 0 ? reportData.negativeResultsList.length.toLocaleString() : '-',
              style: 'tableHeader',
              alignment: 'left',
              border: [false, false, false, true],
              fontSize: 8,
            },
            {
              text: '',
              style: 'tableHeader',
              border: [false, false, false, true],
            },
            {
              text: '',
              style: 'tableHeader',
              border: [false, false, false, true],
            },
          ],
        ],
      },
    },
  ];

  const details1 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 0, 0, 0, 0, 0, 90],
        body: [

          [
            {
              text: 'LEADS',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {},
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart1,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details2 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 65, 0, 0, 0, 0, 90],
        body: [

          [
            {
              text: 'AGUARDANDO DOCUMENTAÇÃO',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'SOLICITAÇÃO DOCUMENTAÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart2,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details3 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 65, 0, 0, 0, 0, 90],
        body: [

          [
            {
              text: 'AGUARDANDO CÁLCULO',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'SOLICITAÇÃO DOCUMENTAÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart3,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details4 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 65, 75, 65, 0, 0, 90],
        body: [

          [
            {
              text: 'AGUARDANDO KIT',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA AVALIAÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'VALOR DO CÁLCULO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'SOLICITAÇÃO KIT',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart4,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details5 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 75, 65, 0, 0, 0, 90],
        body: [

          [
            {
              text: 'AGUARDANDO DISTRIBUIÇÃO',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'VALOR DO CÁLCULO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'RECEBIMENTO KIT',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart5,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details6 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 65, 0, 0, 0, 0, 90],
        body: [

          [
            {
              text: 'SEM DIREITO - AGUARDANDO COMUNICAR CLIENTE',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA AVALIAÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart6,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  const details7 = [
    {
      table: {
        headerRows: 2,
        widths: ['*', 90, 45, 90, 65, 75, 0, 0, 0, 90],
        body: [

          [
            {
              text: 'CASOS FRIOS',
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              margin: [0, 8, 0, 8],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {
              text: 'CLIENTE',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'left',
              style: 'tableHeader',
              bold: true,
              border: [true, true, false, true],
            },
            {
              text: 'ORIGEM',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PARCEIRO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'PRODUTO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'DATA AVALIAÇÃO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {
              text: 'VALOR DO CÁLCULO',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, false, true],
            },
            {},
            {},
            {},
            {
              text: 'STATUS',
              margin: [0, 8, 0, 8],
              fontSize: 8,
              borderBotton: 2,
              alignment: 'center',
              style: 'tableHeader',
              bold: true,
              border: [false, true, true, true],
            },
          ],
          ...dataPart7,
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ];

  function footer(currentPage, pageCount) {
    return {
      columns: [
        {
          text: `Gerado em: ${format(new Date(), 'dd/MM/yyyy HH:mm:ss')}`,
          fontSize: 7,
          margin: [20, 10, 0, 0], // left, top, right, bottom
        },
        {
          text: `${currentPage} / ${pageCount}`,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0], // left, top, right, bottom
        },
      ],
    };
  }

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [15, 40, 15, 40],
    content: [
      reportTitle,
      header,
      reportData.leadsList.length > 0 ? details1 : '',
      reportData.awaitingDocumentsList.length > 0 ? details2 : '',
      reportData.awaitingCalculationList.length > 0 ? details3 : '',
      reportData.awaitingKitList.length > 0 ? details4 : '',
      reportData.awaitingDistributionList.length > 0 ? details5 : '',
      reportData.negativeResultsList.length > 0 ? details6 : '',
      reportData.coldCasesList.length > 0 ? details7 : '',
    ],
    footer,
  };

  return docDefinitions;
}

export default Report6PDF;
