import { useContext } from 'react';

import {
  format,
  parse,
} from 'date-fns';

import { AuthContext } from '../../../contexts/auth';

import Card from '../Card';

import { API } from '../../../services/api';

import './style.css';

function ExpectedBenefitDateNearDeadline({
  dashboardData, setSelectedEvaluation, setAlertMessage, setOpenAlert,
}) {
  const { activeUser, token } = useContext(AuthContext);

  async function handleSelectEvaluation(evaluation) {
    try {
      const reqData = {
        id: evaluation.id,
        token,
      };

      const data = await API.fetch('GET', 'evaluations', reqData);

      setSelectedEvaluation(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/ExpectedBenefitDateNearDeadline/index.js - handleSelectEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  return (
    <div className='dashboard-cases-near-deadline'>
      <div className='dashboard-cases-near-deadline-section-1'>
        <Card
          cardText='ALERTA DIB PREVISTA'
          cardColor='#171f48'
        />
      </div>
      <div className='dashboard-cases-near-deadline-section-2'>
        <Card
          cardText='CLIENTE'
          cardWidth='19vw'
          cardColor='#171f48'
        />
        <Card
          cardText='PRODUTO'
          cardWidth='19vw'
          cardColor='#171f48'
        />
        <Card
          cardText='DIB PREVISTA'
          cardWidth='7.9vw'
          cardColor='#171f48'
          marginRight='1vw'
        />
      </div>
      <div className='dashboard-cases-near-deadline-section-3'>
        {dashboardData.casesNearExpectedBenefitDateDeadline.map((caseNearExpectedBenefitDateDeadline) => (
          <div
            className='dashboard-cases-near-deadline-section-3-row'
            onClick={() => handleSelectEvaluation(caseNearExpectedBenefitDateDeadline)}
          >
            <Card
              cardText={caseNearExpectedBenefitDateDeadline.client}
              cardWidth='19vw'
              cardColor='#FFF'
              cardBorder='1px solid #171f48'
              justifyContent='flex-start'
            />
            <Card
              cardText={caseNearExpectedBenefitDateDeadline.product}
              cardWidth='19vw'
              cardColor='#FFF'
              cardBorder='1px solid #171f48'
              justifyContent='flex-start'
            />
            <Card
              cardText={format(parse(caseNearExpectedBenefitDateDeadline.expectedBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
              cardWidth='8vw'
              cardColor='#FFF'
              cardBorder='1px solid #171f48'
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExpectedBenefitDateNearDeadline;
