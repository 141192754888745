import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  format,
  parse,
} from 'date-fns';

import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { doubleSpaceRegex } from '../../../../services/regex';

import { API } from '../../../../services/api';

import { AuthContext } from '../../../../contexts/auth';

import './style.css';

function ClientFormRow3({
  selectedClient, clientData, setClientData, clientLogs, setClientLogs, setAlertMessage, setOpenAlert, auxiliaryLists,
}) {
  const { activeUser, token } = useContext(AuthContext);

  const [arrivedAt, setArrivedAt] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [lisPendens, setLisPendens] = useState('');
  const [newComment, setNewComment] = useState();
  const [origin, setOrigin] = useState();
  const [partnerCode1, setPartnerCode1] = useState();
  const [partnerCode2, setPartnerCode2] = useState();
  const [partnerCode3, setPartnerCode3] = useState();
  const [removeCommentConfirmation, setRemoveCommentConfirmation] = useState('');
  const [retirementBenefitDate, setRetirementBenefitDate] = useState();
  const [retirementBenefitNumber, setRetirementBenefitNumber] = useState();

  function formatRetirementBenefitNumberInput(retirementBenefitNumber) {
    const formattedRetirementBenefitNumber = retirementBenefitNumber
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{1})\d+?$/, '$1');
    setRetirementBenefitNumber(formattedRetirementBenefitNumber);
  }

  useEffect(() => {
    if (selectedClient !== undefined) {
      setArrivedAt(selectedClient.arrivedAt ? format(parse(selectedClient.arrivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '');
      setCreatedAt(selectedClient.createdAt ? format(parse(selectedClient.createdAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '');
      setLisPendens(selectedClient.lisPendens ? selectedClient.lisPendens : '');
      setOrigin(selectedClient.origin ? selectedClient.origin : '');
      setPartnerCode1(selectedClient.partnerCode1 ? selectedClient.partnerCode1 : '');
      setPartnerCode2(selectedClient.partnerCode2 ? selectedClient.partnerCode2 : '');
      setPartnerCode3(selectedClient.partnerCode3 ? selectedClient.partnerCode3 : '');
      setRetirementBenefitDate(selectedClient.retirementBenefitDate ? format(parse(selectedClient.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '');
      if (selectedClient.retirementBenefitNumber) {
        formatRetirementBenefitNumberInput(selectedClient.retirementBenefitNumber);
      } else {
        setRetirementBenefitNumber('');
      }
    }
  }, [selectedClient]);

  useEffect(() => {
    let currentData = {};
    if (clientData !== undefined && clientData !== '') {
      currentData = clientData;
    }

    if (arrivedAt) {
      if (format(parse(arrivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') !== selectedClient.arrivedAt) {
        currentData.arrivedAt = format(parse(arrivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');
      }
    } else {
      currentData.arrivedAt = null;
    }

    currentData.lisPendens = lisPendens;

    if (origin) {
      if (origin !== selectedClient.origin && origin !== '') {
        currentData.origin = origin;
      }
    }

    if (partnerCode1 && partnerCode1 !== '') {
      currentData.partnerCode1 = partnerCode1;
    } else {
      currentData.partnerCode1 = null;
    }

    if (partnerCode2 && partnerCode2 !== '') {
      currentData.partnerCode2 = partnerCode2;
    } else {
      currentData.partnerCode2 = null;
    }

    if (partnerCode3 && partnerCode3 !== '') {
      currentData.partnerCode3 = partnerCode3;
    } else {
      currentData.partnerCode3 = null;
    }

    if (retirementBenefitDate) {
      if (format(parse(retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') !== selectedClient.retirementBenefitDate) {
        currentData.retirementBenefitDate = format(parse(retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');
      }
    } else {
      currentData.retirementBenefitDate = null;
    }

    if (retirementBenefitNumber) {
      if (retirementBenefitNumber !== selectedClient.retirementBenefitNumber) {
        currentData.retirementBenefitNumber = retirementBenefitNumber.replace(/\D/g, '');
      }
    } else {
      currentData.retirementBenefitNumber = null;
    }

    setClientData(currentData);
  }, [arrivedAt, lisPendens, origin, partnerCode1, partnerCode2, partnerCode3, retirementBenefitDate, retirementBenefitNumber, clientData,
    setClientData, selectedClient.arrivedAt, selectedClient.origin, selectedClient.partnerCode1, selectedClient.partnerCode2,
    selectedClient.partnerCode3, selectedClient.retirementBenefitDate, selectedClient.retirementBenefitNumber]);

  function handleChangePartner1(value) {
    if (value !== '') {
      if (value !== partnerCode2 && value !== partnerCode3) {
        setPartnerCode1(value);
      } else {
        setPartnerCode1('');
        setAlertMessage({ title: 'Erro!', message: 'Parceiro duplicado.' });
        setOpenAlert(true);
      }
    } else {
      setPartnerCode1('');
    }
  }

  function handleChangePartner2(value) {
    if (value !== '') {
      if (value !== partnerCode1 && value !== partnerCode3) {
        setPartnerCode2(value);
      } else {
        setPartnerCode2('');
        setAlertMessage({ title: 'Erro!', message: 'Parceiro duplicado.' });
        setOpenAlert(true);
      }
    } else {
      setPartnerCode2('');
    }
  }

  function handleChangePartner3(value) {
    if (value !== '') {
      if (value !== partnerCode1 && value !== partnerCode2) {
        setPartnerCode3(value);
      } else {
        setPartnerCode3('');
        setAlertMessage({ title: 'Erro!', message: 'Parceiro duplicado.' });
        setOpenAlert(true);
      }
    } else {
      setPartnerCode3('');
    }
  }

  async function handleAddComment() {
    try {
      if (newComment.trim().length > 0 && selectedClient.id !== undefined) {
        const commentData = {
          target: 'Cliente',
          targetId: selectedClient.id,
          details: newComment.trim(),
        };

        let reqData = {
          body: commentData,
          token,
        };

        let data = await API.fetch('POST', 'logs', reqData);

        reqData = {
          id: selectedClient.id,
          token,
        };

        data = await API.fetch('GET', 'clientLogs', reqData);

        setClientLogs(data);

        setNewComment('');
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Client/ClientFormRow3/index.js - handleAddComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRemoveComment(logId) {
    try {
      const reqData = {
        id: logId,
        token,
      };

      await API.fetch('DELETE', 'logs', reqData);

      const currentData = clientLogs.filter((comment) => comment.id !== logId);

      setClientLogs(currentData);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Modals/Client/ClientFormRow3/index.js - handleRemoveComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    } finally {
      setRemoveCommentConfirmation('');
    }
  }

  return (
    <div className='row3'>
      <div className='client-row3'>
        <div className='client-row3-col1'>
          <div className='client-benefit-and-origin'>
            <div className='input-benefit-and-origin-group'>
              <div className='benefit-and-origin-row'>
                <div className='client-retirement-benefit'>
                  <label className='form-label'>
                    Nº Benefício
                  </label>
                  <input
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    maxLength='13'
                    style={{ textAlign: 'center' }}
                    value={retirementBenefitNumber}
                    onChange={(e) => formatRetirementBenefitNumberInput(e.target.value)}
                  />
                </div>
                <div className='client-retirement-benefit-date'>
                  <label className='form-label'>
                    DIB
                  </label>
                  <input
                    className='input'
                    variant='outlined'
                    size='small'
                    type='date'
                    style={{ textAlign: 'center' }}
                    value={retirementBenefitDate}
                    onChange={(e) => setRetirementBenefitDate(e.target.value)}
                  />
                </div>
                <div className='client-origin'>
                  <label className='form-label'>
                    Origem
                  </label>
                  <select
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                  >
                    <option />
                    {auxiliaryLists.originsList.map((origin) => (
                      <option>{origin.origin}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='client-partners'>
            <div className='input-partners-group'>
              <label className='form-label'>
                Parceiros
              </label>
              <div className='partners-row'>
                <select
                  style={{ marginRight: '5px' }}
                  className='input'
                  placeholder='Parceiro 01'
                  variant='outlined'
                  size='small'
                  type='text'
                  value={partnerCode1}
                  onChange={(e) => handleChangePartner1(e.target.value)}
                >
                  <option />
                  {auxiliaryLists.partnersList.map((partner) => (
                    <option>{partner.partnerCode}</option>
                  ))}
                </select>
                <select
                  style={{ marginRight: '5px' }}
                  className='input'
                  variant='outlined'
                  size='small'
                  type='text'
                  value={partnerCode2}
                  onChange={(e) => handleChangePartner2(e.target.value)}
                >
                  <option />
                  {auxiliaryLists.partnersList.map((partner) => (
                    <option>{partner.partnerCode}</option>
                  ))}
                </select>
                <select
                  className='input'
                  variant='outlined'
                  size='small'
                  type='text'
                  value={partnerCode3}
                  onChange={(e) => handleChangePartner3(e.target.value)}
                >
                  <option />
                  {auxiliaryLists.partnersList.map((partner) => (
                    <option>{partner.partnerCode}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='client-admission-group'>
            <div className='client-lis-pendens'>
              <label className='form-label'>
                Litispendência
                :

              </label>
              <select
                className='input'
                variant='outlined'
                size='small'
                type='text'
                style={{ width: 100 }}
                value={lisPendens}
                onChange={(e) => setLisPendens(e.target.value)}
              >
                <option />
                <option>Sim</option>
                <option>Não</option>
              </select>
            </div>
            <div className='client-admission'>
              <label className='form-label'>
                Data de Entrada
              </label>
              <input
                className='input'
                variant='outlined'
                size='small'
                type='date'
                style={{ textAlign: 'center' }}
                value={arrivedAt}
                onChange={(e) => setArrivedAt(e.target.value)}
              />
            </div>
            {selectedClient
              && (
                <div className='client-registration'>
                  <label className='form-label'>
                    Data de Cadastro
                  </label>
                  <input
                    className='input'
                    variant='outlined'
                    size='small'
                    type='text'
                    style={{ textAlign: 'center' }}
                    value={createdAt}
                    onChange={(e) => setCreatedAt(e.target.value)}
                    disabled
                  />
                </div>
              )}
          </div>
        </div>
        <div className='client-row3-col2'>
          {selectedClient
            && (
              <div className='client-logs'>
                <label className='form-label'>
                  Comentários e logs de sistema
                </label>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px #818a91 solid',
                  borderRadius: '3px',
                  height: '35px',
                  alignItems: 'center',
                }}
                >
                  <input
                    className='log-input'
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value.replace(doubleSpaceRegex, ' '))}
                  />
                  {selectedClient.id !== undefined && (
                    <AddIcon
                      onClick={handleAddComment}
                      style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                      }}
                    />
                  )}
                </div>
                <div className='client-log-field'>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  >
                    {clientLogs.map((log) => (
                      <div
                        className='log-row'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          borderBottom: '1px gray solid',
                        }}
                      >
                        {log.type === 'Comentário'
                          ? <strong><span>{`[${format(Date.parse(log.dateTime), 'dd/MM/yyyy HH:mm:ss')} - ${log.user}]: ${log.details}`}</span></strong>
                          : <span>{`[${format(Date.parse(log.dateTime), 'dd/MM/yyyy HH:mm:ss')} - ${log.user}]: ${log.details}`}</span>}
                        {removeCommentConfirmation === log.id
                          && (
                            <>
                              <span style={{
                                color: 'red',
                                width: '60px',
                              }}
                              >
                                <b>Confirma a exclusão?</b>

                              </span>
                              <CheckCircleOutlineIcon
                                onClick={() => handleRemoveComment(log.id)}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                  color: 'green',
                                  backgroundColor: 'rgb(240, 240, 240)',
                                }}
                              />
                              <HighlightOffIcon
                                onClick={() => setRemoveCommentConfirmation('')}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                  color: 'red',
                                  backgroundColor: 'rgb(240, 240, 240)',
                                }}
                              />
                            </>
                          )}
                        {(log.type === 'Comentário' && removeCommentConfirmation !== log.id) && (
                          <DeleteForeverIcon
                            onClick={() => setRemoveCommentConfirmation(log.id)}
                            style={{
                              cursor: 'pointer',
                              paddingRight: '5px',
                              color: 'red',
                              backgroundColor: 'rgb(240, 240, 240)',
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default ClientFormRow3;
