import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  format,
  parse,
} from 'date-fns';

import {
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { API } from '../../../services/api';

import { AuthContext } from '../../../contexts/auth';

import Alert from '../../Modals/Alert';
import Confirmation from '../../Modals/Confirmation';

import '../../../style.css';

function FinancialTransactionsList({ transactionsCurrentList, setSelectedTransaction, setUpdateList }) {
  const { activeUser, token } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [page, setPage] = useState(0);

  const transactionId = useRef();

  const rowsPerPage = 10;

  const columns = [
    {
      id: 'transactionDate', label: 'DATA', minWidth: 60, width: '4%',
    },
    {
      id: 'transactionValue', label: 'VALOR', minWidth: 130, width: '8%', align: 'right',
    },
    {
      id: 'transactionType', label: 'TIPO', minWidth: 100, width: '8%', align: 'center',
    },
    {
      id: 'transactionCategory', label: 'CATEGORIA', minWidth: 100, width: '8%', align: 'center',
    },
    {
      id: 'origin', label: 'ORIGEM', minWidth: 150, width: '10%', align: 'center',
    },
    {
      id: 'partnerCode', label: 'PARCEIRO', minWidth: 60, width: '4%', align: 'center',
    },
    {
      id: 'details', label: 'DETALHES', minWidth: 200, width: '48%', align: 'left',
    },
    {
      id: 'completedAt', label: 'DATA DE CONCLUSÃO', width: 60, align: 'center',
    },
    {
      id: 'actionButton', label: '', width: 30, align: 'center',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleConfirmDeleteTransaction = (transaction) => {
    setConfirmationMessage('Tem certeza de que deseja remover a transação financeira?');
    transactionId.current = transaction.id;
    setOpenConfirmation(true);
  };

  const removeTransaction = useCallback(async () => {
    try {
      const reqData = {
        id: transactionId.current,
        token,
      };

      const data = await API.fetch('POST', 'financialTransactions', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/components/Lists/FinancialTransactions/index.js - removeTransaction',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, setUpdateList]);

  useEffect(() => {
    if (confirmation) {
      removeTransaction();
      setConfirmation(false);
    }
  }, [confirmation, removeTransaction]);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <>
      <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <Pagination
          className='pagination'
          count={Math.ceil(transactionsCurrentList.length / rowsPerPage)}
          variant='outlined'
          shape='rounded'
          page={page + 1}
          onChange={handleChangePage}
          hidePrevButton
          hideNextButton
        />
        <TableContainer sx={{ height: '55%', maxHeight: '100%' }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, borderBottom: '3px rgb(87, 87, 87) solid' }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionsCurrentList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((transaction) => (
                  <TableRow key={transaction.id} className='table-body'>
                    {columns.map((column) => {
                      const value = transaction[column.id];
                      return (
                        <>
                          {column.id === 'actionButton'
                            ? (
                              <TableCell key={column.id} align={column.align}>
                                {transaction.transactionType === 'Pagamento'
                                  && (
                                    <span className='table-buttons' onClick={() => handleConfirmDeleteTransaction(transaction)}>
                                      <LightTooltip title='Excluir'>
                                        <DeleteForeverIcon style={{ color: 'red' }} />
                                      </LightTooltip>
                                    </span>
                                  )}
                              </TableCell>
                            )
                            : (column.id === 'transactionDate' || column.id === 'completedAt'
                              ? (
                                <TableCell key={column.id} align={column.align} onClick={() => setSelectedTransaction(transaction)}>
                                  {value ? format(parse(value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-'}
                                </TableCell>
                              )
                              : (column.id === 'transactionValue'
                                ? (
                                  <TableCell key={column.id} align={column.align} onClick={() => setSelectedTransaction(transaction)}>
                                    R$
                                    {' '}
                                    {Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)}
                                  </TableCell>
                                )
                                : (
                                  <TableCell key={column.id} align={column.align} onClick={() => setSelectedTransaction(transaction)}>
                                    {value}
                                  </TableCell>
                                )
                              ))}
                        </>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {(openConfirmation && confirmationMessage)
        && <Confirmation openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation} confirmationMessage={confirmationMessage} setConfirmationMessage={setConfirmationMessage} setConfirmation={setConfirmation} />}
      {openAlert
        && <Alert openAlert={openAlert} setOpenAlert={setOpenAlert} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
    </>
  );
}

export default FinancialTransactionsList;
