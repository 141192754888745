import {
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import { AuthContext } from '../../../contexts/auth';

import { API } from '../../../services/api';

import Alert from '../Alert';
import BlueButton from '../../Buttons/BlueButton';
import GrayButton from '../../Buttons/GrayButton';

import './style.css';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function User({
  openModal, setOpenModal, selectedUser, setSelectedUser, setUpdateList, auxiliaryLists,
}) {
  const {
    activeUser,
    emailDomain,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [openAlert, setOpenAlert] = useState(false);

  const title = selectedUser ? 'Editar Usuário' : 'Cadastrar Usuário';

  useEffect(() => {
    if (selectedUser) {
      setName(selectedUser.name);
      setEmail(selectedUser.email.substring(0, selectedUser.email.length - emailDomain.length));
      setCategory(selectedUser.category);
    }
  }, [selectedUser]);

  function handleCloseUserModal() {
    setOpenModal(false);
    setName('');
    setEmail('');
    setCategory('');
    setSelectedUser('');
  }

  async function handleSaveUser() {
    try {
      if (!name) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o nome do usuário.',
        });
        setOpenAlert(true);
        return;
      }

      if (!email) {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar o e-mail do usuário.',
        });
        setOpenAlert(true);
        return;
      }

      if (!category && category !== '') {
        setAlertMessage({
          title: 'Alerta!',
          message: 'É necessário informar a categoria do usuário.',
        });
        setOpenAlert(true);
        return;
      }

      const reqData = {
        body: {
          name: name.trim().toUpperCase(),
          email: email + emailDomain,
          category,
        },
        token,
      };

      let operation = 'POST';

      if (selectedUser) {
        reqData.id = selectedUser.id;
        operation = 'PUT';
      }

      const data = await API.fetch(operation, 'users', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: data,
      });

      setOpenAlert(true);

      handleCloseUserModal();

      setUpdateList(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'src/components/Modals/User/index.js - handleSaveUser',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  return (
    <div>
      <div>
        <Dialog
          open={openModal}
          TransitionComponent={Transition}
          keepMounted
        >
          <div className='title-modal'>
            <div className='dialog-title'>
              {title}
              <div
                className='close-button'
                onClick={handleCloseUserModal}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          <DialogContent sx={{ width: '450px' }}>
            <div className='user-input'>
              <input
                style={{ marginBottom: '5px' }}
                autoFocus
                className='input'
                placeholder='Nome'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className='email-input'>
                <input
                  className='input'
                  placeholder='E-mail'
                  variant='outlined'
                  size='small'
                  type='email'
                  disabled={activeUser.category !== 'Sócio'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className='login-email'>
                  {emailDomain}
                </span>
              </div>
              <select
                className='input'
                placeholder='Categoria'
                variant='outlined'
                size='small'
                type='text'
                disabled={activeUser.category !== 'Sócio'}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option />
                {auxiliaryLists.categoriesList.map((category) => (
                  <option>{category.category}</option>
                ))}
              </select>
            </div>
          </DialogContent>
          {activeUser.category === 'Sócio' && (
            <div
              className='dialog-actions'
              style={{
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <DialogActions sx={{ padding: '20px 25px' }}>
                <div onClick={handleCloseUserModal}>
                  <GrayButton
                    buttonText='Cancelar'
                    buttonWidth='90px'
                  />
                </div>
                <div onClick={handleSaveUser}>
                  <BlueButton
                    className='recover-button'
                    buttonText={selectedUser ? 'Salvar' : 'Criar'}
                    buttonWidth={selectedUser ? '70px' : '60px'}
                  />
                </div>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
    </div>
  );
}

export default User;
