import { forwardRef } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material/';

import {
  format,
  parse,
} from 'date-fns';

import BlueButton from '../../Buttons/BlueButton';

import { formatPhone } from '../../../services/format';

const Transition = forwardRef((
  props,
  ref,
) => <Slide direction='up' ref={ref} {...props} />);

function ClientQuickInfo({
  openClientQuickInfo, setOpenClientQuickInfo, clientQuickInfoData, setClientQuickInfoData,
}) {
  function handleCloseClientQuickInfo() {
    setOpenClientQuickInfo(false);
    setClientQuickInfoData([]);
  }

  return (
    <div>
      <Dialog
        open={openClientQuickInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseClientQuickInfo}
      >
        <div className='dialog-title'>{clientQuickInfoData.name}</div>
        <DialogContent sx={{ width: '350px' }}>
          {clientQuickInfoData.birthDate
            && (
              <div>
                <strong>Data de nascimento: </strong>
                {format(parse(clientQuickInfoData.birthDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
              </div>
            )}
          {clientQuickInfoData.phone1
            && (
              <>
                <div><strong>Telefone:</strong></div>
                <ul>
                  {clientQuickInfoData.phone1
                    && <li>{formatPhone(clientQuickInfoData.phone1)}</li>}
                  {clientQuickInfoData.phone2
                    && <li>{formatPhone(clientQuickInfoData.phone2)}</li>}
                  {clientQuickInfoData.phone3
                    && <li>{formatPhone(clientQuickInfoData.phone3)}</li>}
                  {clientQuickInfoData.phone4
                    && <li>{formatPhone(clientQuickInfoData.phone4)}</li>}
                  {clientQuickInfoData.phone5
                    && <li>{formatPhone(clientQuickInfoData.phone5)}</li>}
                </ul>
              </>
            )}
          {clientQuickInfoData.email
            && (
              <>
                <div><strong>E-mail:</strong></div>
                <div>{clientQuickInfoData.email}</div>
              </>
            )}
          {(clientQuickInfoData.city || clientQuickInfoData.state)
            && (
              <div>
                <strong>Localidade: </strong>
                {clientQuickInfoData.city && clientQuickInfoData.state
                  ? (`${clientQuickInfoData.city}/${clientQuickInfoData.state}`)
                  : clientQuickInfoData.city
                    ? clientQuickInfoData.city
                    : clientQuickInfoData.state}
              </div>
            )}
          {clientQuickInfoData.retirementBenefitNumber
            && (
              <div>
                <strong>Nº do benefício: </strong>
                {clientQuickInfoData.retirementBenefitNumber}
              </div>
            )}
        </DialogContent>
        <div className='dialog-actions'>
          <DialogActions onClick={handleCloseClientQuickInfo}>
            <BlueButton buttonText='OK' />
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default ClientQuickInfo;
