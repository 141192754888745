//Fluxo produto Concessão Planejada

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  format,
  isValid,
  parse,
} from 'date-fns';

import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { AuthContext } from '../../contexts/auth';

import { API } from '../../services/api';
import {
  formatDecimal, formatDecimalBR, formatPhone, formatTaxId,
} from '../../services/format';

import Alert from '../../components/Modals/Alert';
import BlueButton from '../../components/Buttons/BlueButton';
import Client from '../../components/Modals/Client';
import ClientQuickInfo from '../../components/Modals/ClientQuickInfo';
import Confirmation from '../../components/Modals/Confirmation';
import GrayButton from '../../components/Buttons/GrayButton';
import Header from '../../components/Header';
import RedButton from '../../components/Buttons/RedButton';

import '../../style.css';

function EvaluationWorkflow3() {
  const navigate = useNavigate();

  const { id: evaluationId } = useParams();

  const {
    activeUser,
    token,
  } = useContext(AuthContext);

  const [alertMessage, setAlertMessage] = useState(false);
  const [clientLogs, setClientLogs] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [estimatedGrossCommission, setEstimatedGrossCommission] = useState(0);
  const [estimatedNetCommission, setEstimatedNetCommission] = useState(0);
  const [evaluation, setEvaluation] = useState({});
  const [evaluationDocs, setEvaluationDocs] = useState([]);
  const [evaluationDocsStatus, setEvaluationDocsStatus] = useState(0);
  const [evaluationLogs, setEvaluationLogs] = useState([]);
  const [evaluationReports, setEvaluationReports] = useState([]);
  const [evaluationReportsStatus, setEvaluationReportsStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [openClientQuickInfo, setOpenClientQuickInfo] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [origins, setOrigins] = useState([]);
  const [partners, setPartners] = useState([]);
  const [products, setProducts] = useState([]);
  const [proposalValue, setProposalValue] = useState(0);
  const [removeCommentConfirmation, setRemoveCommentConfirmation] = useState('');
  const [pendingSave, setPendingSave] = useState();
  const [selectedClient, setSelectedClient] = useState({});
  const [updateData, setUpdateData] = useState('');
  const [, setUpdateList] = useState(false);

  const evaluationStatus = [
    'Lead',
    'Aguardando documentação',
    'Aguardando avaliação',
    'Orçamento',
    'Aguardando kit',
    'Execução',
    'Concluído',
    'Baixado',
  ];

  const retrieveEvaluation = useCallback(async (id) => {
    try {
      const reqData = {
        id,
        token,
      };

      const data = await API.fetch('GET', 'evaluations', reqData);

      setEvaluation(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - retrieveEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveEvaluationDocs = useCallback(async (id) => {
    try {
      if (id) {
        const reqData = {
          id,
          token,
        };

        const data = await API.fetch('GET', 'evaluationDocs', reqData);

        setEvaluationDocs(data);

        const receivedDocs = data.filter((doc) => !!doc.docReceivedAt).length;

        setEvaluationDocsStatus(receivedDocs / data.length);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - retrieveEvaluationDocs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveEvaluationReports = useCallback(async (id) => {
    try {
      if (id) {
        const reqData = {
          id,
          token,
        };

        const data = await API.fetch('GET', 'evaluationReports', reqData);

        setEvaluationReports(data);

        const deliveredReports = data.filter((report) => !!report.date).length;

        setEvaluationReportsStatus(deliveredReports / data.length);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - retrieveEvaluationReports',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveEvaluationLogs = useCallback(async (id) => {
    try {
      if (id) {
        const reqData = {
          id,
          token,
        };

        const data = await API.fetch('GET', 'evaluationLogs', reqData);

        setEvaluationLogs(data);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - retrieveEvaluationLogs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  const retrieveProducts = useCallback(async () => {
    try {
      const reqData = { token };

      const data = await API.fetch('GET', 'products', reqData);

      setProducts(data);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - retrieveProducts',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }, [token, activeUser]);

  function calculateEstimatedCommission(evaluation, calculatedProposalValue) {
    const {
      commissionOnOverdueAmounts, commissionFee, overdueAmounts, partnersCommissionDetails,
    } = evaluation;

    if ((!commissionOnOverdueAmounts || Number(commissionFee || 0) === 0 || Number(overdueAmounts || 0) === 0) && (Number(formatDecimal(calculatedProposalValue)) === 0)) {
      setEstimatedGrossCommission(formatDecimalBR(0));
      setEstimatedNetCommission(formatDecimalBR(0));
      return;
    }

    const calculatedGrossCommission = (((Number(commissionFee || 0) / 100) * Number(overdueAmounts || 0)) + Number(formatDecimal(calculatedProposalValue))).toFixed(2);

    setEstimatedGrossCommission(formatDecimalBR(calculatedGrossCommission));

    let calculatedNetCommission = 0;

    if (partnersCommissionDetails.length === 0) {
      calculatedNetCommission = Number(calculatedGrossCommission);
    } else {
      const netCommissionBasis = Number(calculatedGrossCommission);
      let partnerCommission = 0;

      partnersCommissionDetails.forEach((partner) => {
        if (partner.typeEnd === 'Percentual' && partner.calculationBasisEnd === 'Honorários finais') {
          if (partner.valueEnd) {
            if (Number(partner.valueEnd.replace(/\D+/g, ''))) {
              partnerCommission += ((parseInt(partner.valueEnd.replace(/\D+/g, ''), 10) / 100) / 100) * netCommissionBasis;
            }
          }
        }
      });

      calculatedNetCommission = (Number(calculatedGrossCommission) - partnerCommission).toFixed(2);
    }

    const formattedNetCommission = formatDecimalBR(calculatedNetCommission);

    setEstimatedNetCommission(formattedNetCommission);
  }

  function calculateProposalValue(evaluation) {
    const {
      installments, installmentValue,
    } = evaluation;

    if (Number(installments || 0) === 0 || Number(installmentValue || 0) === 0) {
      setProposalValue(formatDecimalBR(0));
      calculateEstimatedCommission(evaluation, formatDecimalBR(0));
      return;
    }

    const calculatedValue = Number(installments) * Number(installmentValue);

    setProposalValue(formatDecimalBR(calculatedValue));

    calculateEstimatedCommission(evaluation, formatDecimalBR(calculatedValue));
  }

  useEffect(() => {
    if (evaluationId) {
      setLoading(true);

      const fetchData = async () => {
        const promise1 = retrieveEvaluation(evaluationId);
        const promise2 = retrieveEvaluationDocs(evaluationId);
        const promise3 = retrieveEvaluationReports(evaluationId);
        const promise4 = retrieveEvaluationLogs(evaluationId);
        const promise5 = retrieveProducts();

        await Promise.all([promise1, promise2, promise3, promise4, promise5]);

        setLoading(false);
      };

      fetchData();
    }
  }, [retrieveEvaluation, retrieveEvaluationDocs, retrieveEvaluationReports, retrieveEvaluationLogs, retrieveProducts, evaluationId]);

  useEffect(() => {
    if (evaluationId && updateData) {
      setLoading(true);

      const fetchData = async () => {
        switch (updateData) {
          case 'evaluation':
            const promise1 = retrieveEvaluation(evaluationId);
            const promise2 = retrieveEvaluationDocs(evaluationId);
            const promise3 = retrieveEvaluationReports(evaluationId);
            const promise4 = retrieveEvaluationLogs(evaluationId);
            await Promise.all([promise1, promise2, promise3, promise4]);
            break;

          case 'docs':
            await retrieveEvaluationDocs(evaluationId);
            break;

          case 'reports':
            await retrieveEvaluationReports(evaluationId);
            break;

          case 'logs':
            await retrieveEvaluationLogs(evaluationId);
            break;

          default:
            break;
        }

        setLoading(false);
        setUpdateData(false);
      };

      fetchData();
    }
  }, [updateData, retrieveEvaluation, retrieveEvaluationDocs, retrieveEvaluationReports, retrieveProducts, retrieveEvaluationLogs, evaluationId]);

  function updateEvaluationData(state, data) {
    let newState = { ...state };

    switch (data.target) {
      case 'start':
      case 'undo':
        newState = { ...evaluation };
        newState.evaluation = evaluation.evaluation || '';
        newState.notes = evaluation.notes || '';
        calculateProposalValue(newState);
        setPendingSave(false);
        break;

      case 'product':
        if (newState.product !== data.value) {
          const currentProduct = products.find((product) => product.product === newState.product);
          const newProduct = products.find((product) => product.product === data.value);
          if (currentProduct.productGroupId !== newProduct.productGroupId || currentProduct.workflowId !== newProduct.workflowId) {
            setAlertMessage({
              title: 'Alerta!',
              message: `Alteração do produto só é permitida dentro do mesmo grupo (${currentProduct.productGroup}).`,
            });
            setOpenAlert(true);
          } else {
            newState.product = data.value;
            if (data.value) {
              newState.productId = newProduct.id;
            } else {
              newState.productId = null;
            }
            setPendingSave(true);
          }
        }
        break;

      case 'coldCase':
        if (newState.coldCase !== data.value) {
          newState.coldCase = data.value;
          setPendingSave(true);
        }
        break;

      case 'status':
        if (newState.status !== data.value) {
          newState.status = data.value;
          setPendingSave(true);
        }
        break;

      case 'docsRequestedAt':
        if (new Date(newState.docsRequestedAt) !== new Date(data.value)) {
          newState.docsRequestedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'evaluatedAt':
        if (new Date(newState.evaluatedAt) !== new Date(data.value)) {
          newState.evaluatedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'evaluation':
        if (newState.evaluation !== data.value) {
          newState.evaluation = data.value;
          setPendingSave(true);
        }
        break;

      case 'expectedRetirementBenefitDate':
        if (new Date(newState.expectedRetirementBenefitDate) !== new Date(data.value)) {
          newState.expectedRetirementBenefitDate = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'installments':
        if (newState.installments !== data.value) {
          newState.installments = data.value || 0;
          calculateProposalValue(newState);
          setPendingSave(true);
        }
        break;

      case 'installmentValue':
        if (newState.installmentValue !== data.value) {
          newState.installmentValue = formatDecimal(data.value || 0);
          calculateProposalValue(newState);
          setPendingSave(true);
        }
        break;

      case 'commissionOnOverdueAmounts':
        if (newState.commissionOnOverdueAmounts !== data.value) {
          newState.commissionOnOverdueAmounts = data.value;
          if (data.value && newState.commissionType !== 'Percentual') {
            newState.commissionType = 'Percentual';
          }
          if (!data.value) {
            newState.overdueAmounts = '';
            newState.commissionFee = '';
          }
          calculateEstimatedCommission(newState, proposalValue);
          setPendingSave(true);
        }
        break;

      case 'overdueAmounts':
        if (newState.overdueAmounts !== data.value) {
          newState.overdueAmounts = formatDecimal(data.value || 0);
          if (newState.commissionType !== 'Percentual') {
            newState.commissionType = 'Percentual';
          }
          if (!newState.commissionOnOverdueAmounts) {
            newState.commissionOnOverdueAmounts = true;
          }
          calculateEstimatedCommission(newState, proposalValue);
          setPendingSave(true);
        }
        break;

      case 'commissionFee':
        if (newState.commissionFee !== data.value) {
          newState.commissionFee = formatDecimal(data.value || 0);
          if (newState.commissionType !== 'Percentual') {
            newState.commissionType = 'Percentual';
          }
          if (!newState.commissionOnOverdueAmounts) {
            newState.commissionOnOverdueAmounts = true;
          }
          calculateEstimatedCommission(newState, proposalValue);
          setPendingSave(true);
        }
        break;

      case 'proposalDeliveredAt':
        if (new Date(newState.proposalDeliveredAt) !== new Date(data.value)) {
          newState.proposalDeliveredAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'kitRequestedAt':
        if (new Date(newState.kitRequestedAt) !== new Date(data.value)) {
          newState.kitRequestedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'kitReceivedAt':
        if (new Date(newState.kitReceivedAt) !== new Date(data.value)) {
          newState.kitReceivedAt = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'retirementPlanning':
        if (new Date(newState.retirementPlanning) !== new Date(data.value)) {
          newState.retirementPlanning = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'paymentGuidesRecurrence':
        if (newState.paymentGuidesRecurrence !== data.value) {
          newState.paymentGuidesRecurrence = data.value || '';
          setPendingSave(true);
        }
        break;

      case 'administrativeApplication':
        if (new Date(newState.administrativeApplication) !== new Date(data.value)) {
          newState.administrativeApplication = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      case 'notes':
        if (newState.notes !== data.value) {
          newState.notes = data.value || '';
          setPendingSave(true);
        }
        break;

      case 'retirementBenefitDate':
        if (new Date(newState.retirementBenefitDate) !== new Date(data.value)) {
          newState.retirementBenefitDate = data.value
            ? (isValid(data.value)
              ? format(parse(data.value.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
              : data.value)
            : null;
          setPendingSave(true);
        }
        break;

      default:
        break;
    }

    return newState;
  }

  const [evaluationData, dispatch] = useReducer(updateEvaluationData);

  function handleConfirmDeleteEvaluation() {
    setConfirmationMessage('Tem certeza de que deseja remover essa avaliação?');
    setOpenConfirmation(true);
  }

  async function updateEvaluationDocs(documentData) {
    try {
      let reqData = {}

      if (documentData.date) {
        let newDate = new Date(documentData.date);

        if (isValid(newDate)) {
          const newDateYear = newDate.getFullYear();

          if (newDateYear >= 2000 && newDateYear <= 2100) {
            newDate = format(parse(documentData.date.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');

            reqData = {
              id: documentData.id,
              body: { receivedAt: newDate },
              token,
            };
          }
        }
      } else {
        reqData = {
          id: documentData.id,
          body: { receivedAt: null },
          token,
        };
      }

      if (Object.keys(reqData).length > 0) {
        await API.fetch('PUT', 'evaluationDocs', reqData);
        setUpdateData('docs');
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - updateEvaluationDocs',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function updateEvaluationReports(reportData) {
    try {
      let newDate = new Date(reportData.date);

      if (isValid(newDate)) {
        const newDateYear = newDate.getFullYear();

        if (newDateYear >= 2000 && newDateYear <= 2100) {
          newDate = format(parse(reportData.date.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd');

          const reqData = {
            id: reportData.id,
            body: { date: newDate },
            token,
          };

          await API.fetch('PUT', 'evaluationReports', reqData);

          setUpdateData('reports');
        }
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - updateEvaluationReports',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function updateEvaluation() {
    try {
      const reqData = {
        id: evaluationId,
        body: {
          clientId: evaluationData.clientId,
          productId: evaluationData.productId,
          commissionType: evaluationData.commissionType,
          commissionFee: evaluationData.commissionFee ? (parseInt(evaluationData.commissionFee.replace(/\D+/g, ''), 10) / 100).toFixed(2) : null,
          docsRequestedAt: evaluationData.docsRequestedAt ? format(parse(evaluationData.docsRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          evaluatedAt: evaluationData.evaluatedAt ? format(parse(evaluationData.evaluatedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          evaluation: evaluationData.evaluation ? evaluationData.evaluation.trim() : null,
          expectedRetirementBenefitDate: evaluationData.expectedRetirementBenefitDate ? format(parse(evaluationData.expectedRetirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          installments: evaluationData ? parseInt(evaluationData.installments, 10) : null,
          installmentValue: evaluationData.installmentValue ? (parseInt(evaluationData.installmentValue.replace(/\D+/g, ''), 10) / 100).toFixed(2) : null,
          commissionOnOverdueAmounts: evaluationData.commissionOnOverdueAmounts,
          overdueAmounts: evaluationData.overdueAmounts ? (parseInt(evaluationData.overdueAmounts.replace(/\D+/g, ''), 10) / 100).toFixed(2) : null,
          proposalDeliveredAt: evaluationData.proposalDeliveredAt ? format(parse(evaluationData.proposalDeliveredAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          kitRequestedAt: evaluationData.kitRequestedAt ? format(parse(evaluationData.kitRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          kitReceivedAt: evaluationData.kitReceivedAt ? format(parse(evaluationData.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          retirementPlanning: evaluationData.retirementPlanning ? format(parse(evaluationData.retirementPlanning.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          paymentGuidesRecurrence: evaluationData.paymentGuidesRecurrence,
          administrativeApplication: evaluationData.administrativeApplication ? format(parse(evaluationData.administrativeApplication.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          notes: evaluationData.notes ? evaluationData.notes.trim() : null,
          retirementBenefitDate: evaluationData.retirementBenefitDate ? format(parse(evaluationData.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : null,
          status: evaluationData.status,
          coldCase: evaluationData.coldCase,
        },
        token,
      };

      const update = await API.fetch('PUT', 'evaluations', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: update,
      });

      setOpenAlert(true);

      setPendingSave(false);

      setUpdateData('evaluation');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - updateEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function removeEvaluation() {
    try {
      const reqData = {
        id: evaluationId,
        token,
      };

      const update = await API.fetch('DELETE', 'evaluations', reqData);

      setAlertMessage({
        title: 'Sucesso!',
        message: update,
      });

      setOpenAlert(true);

      navigate('/evaluations');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - removeEvaluation',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleAddEvaluationReport() {
    try {
      const reqData = {
        id: evaluation.id,
        token,
      };

      await API.fetch('POST', 'evaluationReports', reqData);

      setUpdateData('reports');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - handleAddEvaluationReport',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRemoveEvaluationReport(id) {
    try {
      const reqData = {
        id,
        token,
      };

      await API.fetch('DELETE', 'evaluationReports', reqData);

      setUpdateData('reports');
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - handleRemoveEvaluationReport',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleAddComment() {
    try {
      if (newComment.trim().length > 0 && evaluationId) {
        const commentData = {
          target: 'Avaliação',
          targetId: evaluationId,
          details: newComment.trim(),
        };

        let reqData = {
          body: commentData,
          token,
        };

        const data = await API.fetch('POST', 'logs', reqData);

        reqData = {
          id: evaluationId,
          token,
        };

        retrieveEvaluationLogs(evaluationId);

        setNewComment('');
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - handleAddComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleRemoveComment(logId) {
    try {
      const reqData = {
        id: logId,
        token,
      };

      await API.fetch('DELETE', 'logs', reqData);

      const currentData = evaluationLogs.filter((comment) => comment.id !== logId);

      setEvaluationLogs(currentData);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - handleRemoveComment',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleOpenClientModal(clientData) {
    try {
      const phones = [];
      if (clientData.phone1) {
        phones.push(formatPhone(clientData.phone1));
      }
      if (clientData.phone2) {
        phones.push(formatPhone(clientData.phone2));
      }
      if (clientData.phone3) {
        phones.push(formatPhone(clientData.phone3));
      }
      if (clientData.phone4) {
        phones.push(formatPhone(clientData.phone4));
      }
      if (clientData.phone5) {
        phones.push(formatPhone(clientData.phone5));
      }
      clientData.phones = phones;

      const partners = [];
      if (clientData.partnerCode1) {
        partners.push(clientData.partnerCode1);
      }
      if (clientData.partnerCode2) {
        partners.push(clientData.partnerCode2);
      }
      if (clientData.partnerCode3) {
        partners.push(clientData.partnerCode3);
      }
      clientData.partners = partners;

      let location = '';
      if (clientData.city && clientData.state) {
        location = `${clientData.city}/${clientData.state}`;
      } else if (clientData.city && !clientData.state) {
        location = clientData.city;
      } else if (!clientData.city && clientData.state) {
        location = clientData.state;
      }
      clientData.location = location;

      setSelectedClient(clientData);

      const reqData = { token };

      let data = await API.fetch('GET', 'origins', reqData);
      setOrigins(data);

      data = await API.fetch('GET', 'partners', reqData);
      setPartners(data);

      reqData.id = evaluationData.clientId;
      data = await API.fetch('GET', 'clientLogs', reqData);
      setClientLogs(data);

      setOpenClientModal(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - handleOpenClientModal',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  async function handleClientDetails(action) {
    try {
      const reqData = {
        id: evaluationData.clientId,
        token,
      };

      const data = await API.fetch('GET', 'clients', reqData);

      setSelectedClient(data);

      if (action === 'view') {
        setSelectedClient(data);
        setOpenClientQuickInfo(true);
      } else {
        handleOpenClientModal(data);
      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        message: error.name === 'APIError' ? error.message : `Erro de sistema: ${error.message}`,
      });

      setOpenAlert(true);

      if (error.name !== 'APIError') {
        const errorData = {
          dateTime: new Date(),
          user: activeUser,
          response: error.response,
          origin: 'scr/pages/EvaluationWorkflow3/index.js - handleClientDetails',
        };
        console.log('Enviar errorData para registro no back-end');
      }
    }
  }

  function formatLogRecord(log) {
    return `[${format(Date.parse(log.dateTime), 'dd/MM/yyyy HH:mm:ss')} - ${log.user}]: ${log.details}`;
  }

  useEffect(() => {
    if (evaluation?.id) {
      dispatch({ value: null, target: 'start' });
      calculateProposalValue(evaluation);
    }

    setLoading(false);
  }, [evaluation]);

  function step1Color() {
    return (!evaluationData?.docsRequestedAt && !evaluationDocsStatus)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.docsRequestedAt && evaluationDocsStatus === 1)
        ? '#3c56aa'
        : 'red');
  }

  function step2Color() {
    return (!evaluationData?.evaluatedAt && !evaluationData?.evaluation && !evaluationData?.expectedRetirementBenefitDate)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.evaluatedAt && evaluationData?.evaluation && evaluationData?.expectedRetirementBenefitDate)
        ? '#3c56aa'
        : 'red');
  }

  function step3Color() {
    return (!evaluationData?.installments && !evaluationData?.installmentValue && !evaluationData?.overdueAmounts && !evaluationData?.commissionFee && !evaluationData?.proposalDeliveredAt)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.installments && evaluationData?.installmentValue && evaluationData?.proposalDeliveredAt && (
        (!evaluationData?.commissionOnOverdueAmounts && !evaluationData?.overdueAmounts && !evaluationData?.commissionFee)
        || (evaluationData?.commissionOnOverdueAmounts && evaluationData?.commissionFee)
      ))
        ? '#3c56aa'
        : 'red');
  }

  function step4Color() {
    return (!evaluationData?.kitRequestedAt && !evaluationData?.kitReceivedAt)
      ? 'rgba(0, 0, 0, 0.38)'
      : ((evaluationData?.kitRequestedAt && evaluationData?.kitReceivedAt)
        ? '#3c56aa'
        : 'red');
  }

  function step5Color() {
    return (!evaluationData?.retirementPlanning && !evaluationData?.paymentGuidesRecurrence && !evaluationData?.administrativeApplication && !evaluationData?.notes)
      ? 'rgba(0, 0, 0, 0.38)'
      : (evaluationData?.retirementPlanning && evaluationData?.paymentGuidesRecurrence && evaluationData?.administrativeApplication
        ? '#3c56aa'
        : 'red');
  }

  function step6Color() {
    return (!evaluationReportsStatus)
      ? 'rgba(0, 0, 0, 0.38)'
      : (evaluationReportsStatus === 1
        ? '#3c56aa'
        : 'red');
  }

  function step7Color() {
    return (!evaluationData?.retirementBenefitDate)
      ? 'rgba(0, 0, 0, 0.38)'
      : '#3c56aa';
  }

  useEffect(() => {
    if (confirmation) {
      removeEvaluation();
      setConfirmation(false);
    }
  }, [confirmation, removeEvaluation]);

  return (
    <div className='page-container'>
      <Header secondaryTitle={`AVALIAÇÃO - ${evaluationData?.client}`} />
      <div className='page-body-container'>
        <div
          className='page-top-menu-area'
          style={{ justifyContent: 'flex-end' }}
        >
          <div style={{
            display: 'flex',
            columnGap: 10,
          }}
          >
            {pendingSave && (
              <>
                <div onClick={() => dispatch({ value: null, target: 'undo' })}>
                  <GrayButton
                    buttonText='Desfazer Alterações'
                    buttonWidth={160}
                  />
                </div>
                <div onClick={updateEvaluation}>
                  <BlueButton
                    buttonText='Salvar Alterações'
                    buttonWidth={140}
                  />
                </div>
              </>
            )}
            {!pendingSave && (
              <div onClick={() => navigate('/evaluations')}>
                <BlueButton
                  buttonText='Voltar'
                  buttonWidth={70}
                />
              </div>
            )}
          </div>
        </div>
        <div className='page-form-area'>
          {!loading && (
            <div className='form-container'>
              <div className='form-header'>
                <div className='form-header-row'>
                  <div style={{
                    width: '60%',
                    minWidth: '200px',
                    maxWidth: '372px',
                  }}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    >
                      <label
                        className='form-label link'
                        onClick={() => handleClientDetails('view')}
                      >
                        Cliente
                      </label>
                      <span
                        className='edit-icon'
                        onClick={() => handleClientDetails('edit')}
                        style={{ height: '16px' }}
                      >
                        <EditIcon
                          fontSize='small'
                          color='primary'
                          style={{
                            height: '16px',
                            margin: '-6px 0 6px 0',
                          }}
                        />
                      </span>
                    </div>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.client}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '110px',
                    minWidth: '110px',
                  }}
                  >
                    <label className='form-label'>
                      CPF
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      style={{ textAlign: 'center' }}
                      value={formatTaxId(evaluationData?.taxId, 'cpf')}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label className='form-label'>
                      Parceiros
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.partner1}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label
                      className='form-label'
                      style={{ height: '18px' }}
                    />
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.partner2}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '105px',
                    minWidth: '105px',
                  }}
                  >
                    <label
                      className='form-label'
                      style={{ height: '18px' }}
                    />
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.partner3}
                      disabled
                    />
                  </div>
                  <div style={{
                    width: '240px',
                    minWidth: '240px',
                    marginRight: 'max(auto, 8px)',
                  }}
                  >
                    <label className='form-label'>
                      Origem
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      maxLength='14'
                      value={evaluationData?.origin}
                      disabled
                    />
                  </div>
                </div>
                <div className='form-header-row'>
                  <div style={{
                    width: '60%',
                    minWidth: '200px',
                    maxWidth: '372px',
                  }}
                  >
                    <label className='form-label'>
                      Produto
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={evaluationData?.product}
                      onChange={(e) => dispatch({ value: e.target.value, target: 'product' })}
                    >
                      <option />
                      {products.map((item) => (
                        <option>{item.product}</option>
                      ))}
                    </select>
                  </div>
                  {(activeUser.category === 'Sócio' || activeUser.category === 'Financeiro') && (
                    <div style={{
                      width: '110px',
                      minWidth: '110px',
                    }}
                    >
                      <label className='form-label'>
                        Estimado Bruto
                      </label>
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        style={{
                          padding: '0 10%',
                          textAlign: 'right',
                        }}
                        value={estimatedGrossCommission}
                        disabled
                      />
                      <span style={{
                        marginLeft: '-95%',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        R$
                      </span>
                    </div>
                  )}
                  {(activeUser.category === 'Sócio' || activeUser.category === 'Financeiro') && (
                    <div style={{
                      width: '105px',
                      minWidth: '105px',
                    }}
                    >
                      <label className='form-label'>
                        Estimado Líquido
                      </label>
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='text'
                        style={{
                          padding: '0 10%',
                          textAlign: 'right',
                        }}
                        value={estimatedNetCommission}
                        disabled
                      />
                      <span style={{
                        marginLeft: '-95%',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        R$
                      </span>
                    </div>
                  )}
                  <div style={{
                    width: '220px',
                    minWidth: '220px',
                    marginRight: 'max(auto, 8px)',
                  }}
                  >
                    <label className='form-label'>
                      Caso Frio
                    </label>
                    <input
                      className='input'
                      variant='outlined'
                      size='small'
                      type='checkbox'
                      style={{
                        height: 15,
                        width: 15,
                        marginRight: 0,
                        margin: '8px 0 0 0',
                      }}
                      checked={evaluationData?.coldCase}
                      onChange={(e) => dispatch({ value: e.target.checked, target: 'coldCase' })}
                    />
                  </div>
                  <div style={{
                    width: '240px',
                    minWidth: '240px',
                  }}
                  >
                    <label className='form-label'>
                      Etapa
                    </label>
                    <select
                      className='input'
                      variant='outlined'
                      size='small'
                      type='text'
                      value={evaluationData?.status}
                      onChange={(e) => dispatch({ value: e.target.value, target: 'status' })}
                    >
                      <option />
                      {evaluationStatus.map((status) => (
                        <option>{status}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='horizontal-separator' />

              <div className='form-data-container'>
                <div className='form-data-container-data'>
                  <div className='form-data-container-card-workflow-2'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step1Color() }}
                      >
                        1
                      </span>
                      DOCUMENTAÇÃO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label docs-label-column'>
                        Solicitação
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.docsRequestedAt ? format(parse(evaluationData?.docsRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'docsRequestedAt' })}
                      />
                      {!evaluationData?.docsRequestedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'docsRequestedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',

                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'docsRequestedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-horizontal-separator' />
                    {evaluationDocs.map((doc) => (
                      <div
                        key={doc?.id}
                        className='form-data-container-card-row'
                      >
                        <label className='form-label docs-label-column'>
                          {doc?.doc}
                        </label>
                        <input
                          className='date-input'
                          variant='outlined'
                          size='small'
                          type='date'
                          min='2000-01-01'
                          max='2100-12-31'
                          value={doc?.docReceivedAt}
                          onChange={(e) => updateEvaluationDocs({ id: doc?.id, date: e.target.value })}
                        />
                        {!doc?.docReceivedAt ? (
                          <RadioButtonUncheckedIcon
                            style={{
                              height: 30,
                              width: 30,
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            onClick={() => updateEvaluationDocs({ id: doc?.id, date: format(new Date(), 'yyyy-MM-dd') })}
                          />
                        ) : (
                          <CheckCircleOutlineRoundedIcon
                            style={{
                              height: 30,
                              width: 30,
                              color: '#171f48',
                              cursor: 'pointer',
                            }}
                            onClick={() => updateEvaluationDocs({ id: doc?.id, date: null })}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  <div className='form-data-container-card-workflow-2'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step2Color() }}
                      >
                        2
                      </span>
                      AVALIAÇÃO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Avaliação
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.evaluatedAt ? format(parse(evaluationData?.evaluatedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'evaluatedAt' })}
                      />
                      {!evaluationData?.evaluatedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'evaluatedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'evaluatedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <textarea
                        className='text-area-input'
                        style={{
                          margin: '12px 0',
                          height: 160,
                        }}
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.evaluation}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'evaluation' })}
                      />
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        DIB prevista
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.expectedRetirementBenefitDate ? format(parse(evaluationData?.expectedRetirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'expectedRetirementBenefitDate' })}
                      />
                      {!evaluationData?.expectedRetirementBenefitDate ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'expectedRetirementBenefitDate' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'expectedRetirementBenefitDate' })}
                        />
                      )}
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-2'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step3Color() }}
                      >
                        3
                      </span>
                      ORÇAMENTO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Parcelas
                      </label>
                      <input
                        className='installments-input'
                        variant='outlined'
                        size='small'
                        type='number'
                        min={0}
                        max={360}
                        value={evaluationData?.installments}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'installments' })}
                      />
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Valor
                      </label>
                      <span style={{
                        margin: 0,
                        transform: 'translateX(42px)',
                        fontSize: '13px',
                        zIndex: 2,
                      }}
                      >
                        R$
                      </span>
                      <input
                        className='monetary-value-input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='14'
                        value={evaluationData?.installmentValue ? formatDecimalBR(evaluationData?.installmentValue) : ''}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'installmentValue' })}
                      />
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Total
                      </label>
                      <span style={{
                        margin: 0,
                        transform: 'translateX(42px)',
                        fontSize: '13px',
                        zIndex: 2,
                      }}
                      >
                        R$
                      </span>
                      <input
                        className='monetary-value-input'
                        variant='outlined'
                        size='small'
                        type='text'
                        value={proposalValue}
                        disabled
                      />
                    </div>
                    <div
                      className='form-data-container-card-row'
                      style={{ marginTop: 12 }}
                    >
                      <input
                        className='input'
                        variant='outlined'
                        size='small'
                        type='checkbox'
                        style={{
                          height: 15,
                          width: 15,
                          marginRight: 0,
                        }}
                        checked={evaluationData?.commissionOnOverdueAmounts}
                        onChange={(e) => dispatch({ value: e.target.checked, target: 'commissionOnOverdueAmounts' })}
                      />
                      <label className='form-label input-label'>
                        Honorários sobre atrasados
                      </label>
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Atrasados
                      </label>
                      <span style={{
                        margin: 0,
                        transform: 'translateX(28px)',
                        fontSize: '13px',
                        zIndex: 2,
                      }}
                      >
                        R$
                      </span>
                      <input
                        className='monetary-value-input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='14'
                        value={evaluationData?.overdueAmounts ? formatDecimalBR(evaluationData?.overdueAmounts) : ''}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'overdueAmounts' })}
                      />
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Honorários
                      </label>
                      <input
                        className='installments-input'
                        variant='outlined'
                        size='small'
                        type='text'
                        maxLength='6'
                        style={{
                          textAlign: 'center',
                          marginRight: '-32px',
                        }}
                        value={evaluationData?.commissionFee ? formatDecimalBR(evaluationData?.commissionFee) : ''}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'commissionFee' })}
                      />
                      <span style={{
                        transform: 'translateX(-20px)',
                        alignSelf: 'center',
                        fontSize: '13px',
                      }}
                      >
                        %
                      </span>
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Entregue
                      </label>
                      <input
                        className='date-input'
                        style={{ marginRight: '-28px' }}
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.proposalDeliveredAt ? format(parse(evaluationData?.proposalDeliveredAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'proposalDeliveredAt' })}
                      />
                      {!evaluationData?.proposalDeliveredAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'proposalDeliveredAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'proposalDeliveredAt' })}
                        />
                      )}
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-2'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step4Color() }}
                      >
                        4
                      </span>
                      KIT
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label kit-label-column'>
                        Solicitação
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.kitRequestedAt ? format(parse(evaluationData?.kitRequestedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'kitRequestedAt' })}
                      />
                      {!evaluationData?.kitRequestedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'kitRequestedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'kitRequestedAt' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label kit-label-column'>
                        Recebimento
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.kitReceivedAt ? format(parse(evaluationData?.kitReceivedAt.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'kitReceivedAt' })}
                      />
                      {!evaluationData?.kitReceivedAt ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'kitReceivedAt' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'kitReceivedAt' })}
                        />
                      )}
                    </div>
                  </div>

                  <div className='form-data-container-card-workflow-2'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step5Color() }}
                      >
                        5
                      </span>
                      EXECUÇÃO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        Planejamento
                      </label>
                      <input
                        className='date-input distribution-date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.retirementPlanning ? format(parse(evaluationData?.retirementPlanning.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'retirementPlanning' })}
                      />
                      {!evaluationData?.retirementPlanning ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'retirementPlanning' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'retirementPlanning' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        Guias
                      </label>
                      <select
                        className='input'
                        style={{ width: 180 }}
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.paymentGuidesRecurrence}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'paymentGuidesRecurrence' })}
                      >
                        <option />
                        {['Mensal', 'Trimestral', 'Semestral'].map((recurrence) => (
                          <option>{recurrence}</option>
                        ))}
                      </select>
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label distribution-label-column'>
                        DER
                      </label>
                      <input
                        className='date-input distribution-date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.administrativeApplication ? format(parse(evaluationData?.administrativeApplication.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'administrativeApplication' })}
                      />
                      {!evaluationData?.administrativeApplication ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'administrativeApplication' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'administrativeApplication' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Notas
                      </label>
                    </div>
                    <div className='form-data-container-card-row'>
                      <textarea
                        className='text-area-input'
                        style={{ height: 122 }}
                        variant='outlined'
                        size='small'
                        type='text'
                        value={evaluationData?.notes}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'notes' })}
                      />
                    </div>
                  </div>

                  <div
                    className='form-data-container-card-workflow-2'
                    style={{ width: 316 }}
                  >
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step6Color() }}
                      >
                        6
                      </span>
                      EXECUÇÃO - RELATÓRIOS
                    </div>
                    <div
                      className='form-data-container-card-row'
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <label className='form-label distribution-label-column'>
                        Incluir relatório
                      </label>
                      <AddIcon
                        onClick={handleAddEvaluationReport}
                        style={{
                          cursor: 'pointer',
                          paddingRight: '5px',
                        }}
                      />
                    </div>
                    {evaluationReports.map((report, index) => (
                      <div
                        key={report?.id}
                        className='form-data-container-card-row'
                      >
                        <label className='form-label distribution-label-column'>
                          {`Relatório ${index + 1}`}
                        </label>
                        <input
                          className='date-input distribution-date-input'
                          variant='outlined'
                          size='small'
                          type='date'
                          min='2000-01-01'
                          max='2100-12-31'
                          value={report?.date}
                          onChange={(e) => updateEvaluationReports({ id: report?.id, date: e.target.value })}
                        />
                        {!report?.date ? (
                          <RadioButtonUncheckedIcon
                            style={{
                              height: 30,
                              width: 30,
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => updateEvaluationReports({ id: report?.id, date: format(new Date(), 'yyyy-MM-dd') })}
                          />
                        ) : (
                          <CheckCircleOutlineRoundedIcon
                            style={{
                              height: 30,
                              width: 30,
                              color: '#171f48',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => updateEvaluationReports({ id: report?.id, date: null })}
                          />
                        )}
                        <DeleteForeverIcon
                          onClick={() => (report?.date ? handleRemoveEvaluationReport(report?.id) : undefined)}
                          style={{
                            cursor: (report?.date ? 'pointer' : 'default'),
                            color: (report?.date ? 'red' : 'grey'),
                          }}
                        />
                      </div>
                    ))}
                  </div>

                  <div className='form-data-container-card-workflow-2'>
                    <div className='form-data-container-card-title'>
                      <span
                        className='form-data-container-card-number'
                        style={{ backgroundColor: step7Color() }}
                      >
                        7
                      </span>
                      CONCESSÃO
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        DIB
                      </label>
                      <input
                        className='date-input'
                        variant='outlined'
                        size='small'
                        type='date'
                        min='2000-01-01'
                        max='2100-12-31'
                        value={(evaluationData?.retirementBenefitDate ? format(parse(evaluationData?.retirementBenefitDate.substring(0, 10), 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')}
                        onChange={(e) => dispatch({ value: e.target.value, target: 'retirementBenefitDate' })}
                      />
                      {!evaluationData?.retirementBenefitDate ? (
                        <RadioButtonUncheckedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: format(new Date(), 'yyyy-MM-dd'), target: 'retirementBenefitDate' })}
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            height: 30,
                            width: 30,
                            color: '#171f48',
                            cursor: 'pointer',
                          }}
                          onClick={() => dispatch({ value: null, target: 'retirementBenefitDate' })}
                        />
                      )}
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        Alterações na DIB serão replicadas
                      </label>
                    </div>
                    <div className='form-data-container-card-row'>
                      <label className='form-label'>
                        no cadastro do cliente.
                      </label>
                    </div>
                  </div>
                </div>

                <div className='vertical-separator' />

                <div className='form-data-container-logs'>
                  <label className='form-label'>
                    Comentários e logs de sistema
                  </label>
                  <div className='evaluation-new-comment'>
                    <input
                      className='log-input'
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                    />
                    <AddIcon
                      onClick={handleAddComment}
                      style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                      }}
                    />
                  </div>
                  <div className='evaluations-log-field'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      {evaluationLogs.map((log) => (
                        <div
                          className='log-row'
                          style={{ borderBottom: '1px gray solid' }}
                        >
                          {formatLogRecord}
                          {log.type === 'Comentário'
                            ? <strong><span>{formatLogRecord(log)}</span></strong>
                            : <span>{formatLogRecord(log)}</span>}
                          {removeCommentConfirmation === log.id && (
                            <span style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              gap: 10,
                              alignItems: 'center',
                            }}
                            >
                              <span style={{
                                color: 'red',
                                width: '60px',
                              }}
                              >
                                <b>Confirma a exclusão?</b>
                              </span>
                              <CheckCircleOutlineIcon
                                onClick={() => handleRemoveComment(log.id)}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                  color: 'green',
                                  backgroundColor: 'rgb(240, 240, 240)',
                                }}
                              />
                              <HighlightOffIcon
                                onClick={() => setRemoveCommentConfirmation('')}
                                style={{
                                  cursor: 'pointer',
                                  paddingRight: '5px',
                                  color: 'red',
                                  backgroundColor: 'rgb(240, 240, 240)',
                                }}
                              />
                            </span>
                          )}
                          {(log.type === 'Comentário' && removeCommentConfirmation !== log.id) && (
                            <DeleteForeverIcon
                              onClick={() => setRemoveCommentConfirmation(log.id)}
                              style={{
                                cursor: 'pointer',
                                paddingRight: '5px',
                                color: 'red',
                                backgroundColor: 'rgb(240, 240, 240)',
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 8,
                    }}
                    onClick={handleConfirmDeleteEvaluation}
                  >
                    <RedButton
                      buttonText='Excluir Avaliação'
                      buttonWidth={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openClientModal && (
        <Client
          openModal={openClientModal}
          setOpenModal={setOpenClientModal}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          clientLogs={clientLogs}
          setClientLogs={setClientLogs}
          setUpdateList={setUpdateList}
          auxiliaryLists={{
            originsList: origins,
            partnersList: partners,
          }}
        />
      )}
      {openClientQuickInfo && (
        <ClientQuickInfo
          openClientQuickInfo={openClientQuickInfo}
          setOpenClientQuickInfo={setOpenClientQuickInfo}
          clientQuickInfoData={selectedClient}
          setClientQuickInfoData={setSelectedClient}
        />
      )}
      {openAlert && (
        <Alert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}
      {(openConfirmation && confirmationMessage) && (
        <Confirmation
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
          setConfirmation={setConfirmation}
        />
      )}
    </div>
  );
}

export default EvaluationWorkflow3;
